import React from 'react';
import * as PropTypes from 'prop-types';
import detailedViewConfig from '../PriceHistory/Detailed/config';

export const PriceTrendChartDescription = ({
  realEstateType,
  locationName,
  percDiff,
  currentQuarter,
  currentYear,
  firstQuarter,
  firstYear,
  currentQuarterPrice,
}) => {
  if (!realEstateType || !currentQuarterPrice) {
    return null;
  }
  const config = detailedViewConfig.getFor(realEstateType);
  if (!config) {
    return null;
  }
  const percentageFormat = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const priceFormat = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const price = priceFormat.format(currentQuarterPrice);
  const realEstateText = config.subTitle();
  const gender = config.pronoun();
  const nowYear = `Q${currentQuarter} ${currentYear}`;
  const startedYear = `Q${firstQuarter} ${firstYear}`;

  return (
    <>
      {realEstateText} lag in {nowYear} in&nbsp;{locationName} und Umgebung bei
      <b> {price} €/m²</b>. {gender} ist seit {startedYear} um insgesamt <b>{percentageFormat.format(percDiff)}%</b>{' '}
      {percDiff > 0 ? 'gestiegen' : 'gesunken'}.
    </>
  );
};

PriceTrendChartDescription.propTypes = {
  realEstateType: PropTypes.string,
  percDiff: PropTypes.number,
  locationName: PropTypes.string,
  currentQuarter: PropTypes.number,
  currentYear: PropTypes.number,
  firstQuarter: PropTypes.number,
  firstYear: PropTypes.number,
  currentQuarterPrice: PropTypes.number,
};

export default PriceTrendChartDescription;
