import React from 'react';
import PropTypes from "prop-types";
import styles from './HomeOwnerWorldDescription.less';

const HomeOwnerWorldDescription = ({ header, text }) => (
  <div className={`grid-item one-half grid-align-center desk-padding-left-s lap-padding-left-s palm-padding-bottom`}>
    <div className={styles.myPropertyFunctionalityHeader}>{header}</div>
    <p>{text}</p>
  </div>
);

HomeOwnerWorldDescription.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
};

export default HomeOwnerWorldDescription;
