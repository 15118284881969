export const commercializationTypes = [
  {
    name: 'Kaufen',
    value: 'BUY',
  },
  {
    name: 'Mieten',
    value: 'RENT',
  },
];

export const propertyTypes = [
  {
    name: 'Wohnung',
    value: 'APARTMENT',
  },
  {
    name: 'Haus',
    value: 'HOUSE',
  },
];

export const userIntents = [
  {
    name: 'Verkaufen',
    value: 'SELL',
  },
  {
    name: 'Kaufen',
    value: 'BUY',
  },
  {
    name: 'Vermieten',
    value: 'LET',
  },
  {
    name: 'Mieten',
    value: 'RENT',
  },
];


export const userIntentsReduced = [
  {
    name: 'Kaufpreise',
    value: 'SELL',
  },
  {
    name: 'Mietpreise',
    value: 'LET',
  },
];

const userIntentsSymbolsToGerman = Object.assign(...userIntents.map(({ name, value }) => ({ [value]: name })));
const propertyTypesSymbolsToGerman = Object.assign(...propertyTypes.map(({ name, value }) => ({ [value]: name })));
export const translateUserIntent = (intent) => userIntentsSymbolsToGerman[intent];
export const translatePropertyType = (propertyType) => propertyTypesSymbolsToGerman[propertyType];

export const userIntentName = (intent) => {
  let userIntentsName = Object.assign(...userIntentsReduced.map(({ name, value }) => ({ [value]: name })))
  userIntentsName = { ...userIntentsName, "RENT": "Mietpreise", "BUY": "Kaufpreise" }
  return userIntentsName[intent];
}
