import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import styles from './PriceTable.less';
import { appendSearchParamsFromQuery } from '../../utils/urlUtils';
import tracking from '../../tracking/tracking';
import { EXPLORE_PRICETABLE_LINKS } from '../../tracking/trackingNaming';
import { getLocationQuery } from "../../selectors/selectors";

const purchasePriceFormat = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const rentPriceFormat = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const formatPurchasePrice = (price) => {
  if (!price) {
    return 'n.v.';
  }
  return purchasePriceFormat.format(price);
};

const formatRentPrice = (price) => {
  if (!price) {
    return 'n.v.';
  }
  return rentPriceFormat.format(price);
};

const showMore = (
  <>
    <i className="is24-icon-chevron-down" /> <span>Mehr anzeigen</span>
  </>
);
const showLess = (
  <>
    <i className="is24-icon-chevron-up" /> <span>Weniger anzeigen</span>
  </>
);

export const PriceTable = ({ entries, columnHeadline, headline, pageTitle, stepCount, locationQuery }) => {
  const [maxVisible, setMaxVisible] = useState(stepCount);
  const [displayShowMore, setDisplayShowMore] = useState(entries && entries.length > stepCount);

  useEffect(() => {
    setMaxVisible(stepCount);
    if (entries && entries.length > stepCount) {
      setDisplayShowMore(true);
    } else {
      setDisplayShowMore(false);
    }
  }, [columnHeadline, entries, stepCount]);

  if (!entries || !entries.length || !columnHeadline) {
    return null;
  }

  const onClickShowMoreOrLess = (event) => {
    if (maxVisible < Math.min(entries.length, stepCount * 3)) {
      setMaxVisible(maxVisible + stepCount);
    } else {
      setMaxVisible(stepCount);
      document.body.parentElement.scrollTop -= (Math.min(entries.length, stepCount * 3) - stepCount) * 59.54;
    }
    event.currentTarget.blur();
  };

  const doTracking = () => {
    tracking.reportData({
      pageTitle,
      label: EXPLORE_PRICETABLE_LINKS,
    });
  };

  return (
    <div className={styles.priceTableOuter}>
      <h2 data-qa="headline">{headline}</h2>
      <div className={`${styles.priceTableContainer} ${displayShowMore ? styles.withShowMore : ''}`}>
        <div className={`${styles.priceTableMain} grid grid-flex`}>
          <div data-qa="columnHeadline" className="grid-item one-third font-bold">
            {columnHeadline}
          </div>
          <div data-qa="boldDesc" className="grid-item two-sixths font-bold">
            Ø Kaufpreis (€ / m²)
          </div>
          <div data-qa="boldDesc" className="grid-item two-sixths font-bold">
            Ø Mietpreis (€ / m²)
          </div>
          <div data-qa="lineHeader" className={`grid-item one-third ${styles.headerCell}`}>
            Immobilientyp
          </div>
          <div data-qa="columnHeader" className={`grid-item one-sixth ${styles.headerCell}`}>
            Wohnung
          </div>
          <div data-qa="columnHeader" className={`grid-item one-sixth ${styles.headerCell}`}>
            Haus
          </div>
          <div data-qa="columnHeader" className={`grid-item one-sixth ${styles.headerCell}`}>
            Wohnung
          </div>
          <div data-qa="columnHeader" className={`grid-item one-sixth ${styles.headerCell}`}>
            Haus
          </div>
          {entries.map((entry, index) => {
            if (index >= stepCount * 3) {
              return null;
            }
            const showOrHide = index >= maxVisible ? 'hide' : '';
            return (
              <React.Fragment key={index}>
                <div data-qa="address-cell" className={`grid-item one-third border-bottom ${showOrHide}`}>
                  <a
                    onClick={doTracking}
                    title={entry.locationName}
                    className="font-ellipsis"
                    href={appendSearchParamsFromQuery(entry.link, locationQuery)}
                  >
                    {entry.locationName}
                  </a>
                </div>
                <div data-qa="APARTMENT_BUY" className={`grid-item one-sixth border-bottom ${showOrHide}`}>
                  {formatPurchasePrice(entry.values?.APARTMENT_BUY?.prices?.price)}
                </div>
                <div data-qa="HOUSE_BUY" className={`grid-item one-sixth border-bottom ${showOrHide}`}>
                  {formatPurchasePrice(entry.values?.HOUSE_BUY?.prices?.price)}
                </div>
                <div data-qa="APARTMENT_RENT" className={`grid-item one-sixth border-bottom ${showOrHide}`}>
                  {formatRentPrice(entry.values?.APARTMENT_RENT?.prices?.price)}
                </div>
                <div data-qa="HOUSE_RENT" className={`grid-item one-sixth border-bottom ${showOrHide}`}>
                  {formatRentPrice(entry.values?.HOUSE_RENT?.prices?.price)}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {displayShowMore && (
        <div className={styles.showMoreLess}>
          <button data-qa="btnShowMore" onClick={onClickShowMoreOrLess} className="link-text" type="button">
            {maxVisible < Math.min(entries.length, stepCount * 3) ? showMore : showLess}
          </button>
        </div>
      )}
    </div>
  );
};

const PriceTableEntry = PropTypes.shape({
  link: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  values: PropTypes.shape({
    HOUSE_BUY: PropTypes.object,
    APARTMENT_BUY: PropTypes.object,
    HOUSE_RENT: PropTypes.object,
    APARTMENT_RENT: PropTypes.object,
  }),
});

PriceTable.propTypes = {
  columnHeadline: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  stepCount: PropTypes.number,
  entries: PropTypes.arrayOf(PriceTableEntry).isRequired,
  locationQuery: PropTypes.object.isRequired,
};

PriceTable.defaultProps = {
  stepCount: 7,
};

const mapStateToProps = (state) => ({
  locationQuery: getLocationQuery(state),
});

export default connect(mapStateToProps)(PriceTable);
