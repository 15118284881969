import { PRICE_CHECK_INPUT_UPDATE, PRICE_CHECK_REAL_ESTATE_TYPE } from './actions.js';
import { SELECT_REAL_ESTATE_TYPE } from '../../actions.js';

const initialState = {
  showTeaserBox: true,
  showRangeBox: false,
  animateScale: false,
  realEstateType: '',
  priceCheckInputs: {},
};

function resetToDefaultViewOnRealEstateTypeChange(state) {
  return Object.assign({}, state, initialState);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REAL_ESTATE_TYPE:
      return resetToDefaultViewOnRealEstateTypeChange(state);
    case PRICE_CHECK_INPUT_UPDATE: {
      const { key, value } = action;
      return Object.assign({}, state, {
        priceCheckInputs: { ...state.priceCheckInputs, ...{ [key]: value } },
      });
    }
    case PRICE_CHECK_REAL_ESTATE_TYPE: {
      const { realEstateType } = action;
      if (realEstateType) {
        return Object.assign({}, state, { realEstateType });
      }
      return Object.assign({}, state, { realEstateType });
    }
    default:
      return state;
  }
};
