import { combineReducers } from 'redux';

import {
  CANCEL_ADDRESS_SEARCH,
  FETCH_SINGLE_SUGGESTIONS,
  FETCH_SINGLE_SUGGESTIONS_FAILURE,
  FETCH_SINGLE_SUGGESTIONS_SUCCESS,
  FETCH_SUGGESTIONS,
  FETCH_SUGGESTIONS_FAILURE,
  FETCH_SUGGESTIONS_SUCCESS,
  SAVE_LAST_SEARCH_INPUT,
  SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE,
  SCREEN_RESIZE,
  SUGGESTIONS_ARROW_DOWN_EVENT,
  SUGGESTIONS_ARROW_UP_EVENT,
} from './actions';

import { getSuggestionResultLength } from '../../utils/suggestionsUtils';
import {
  ERROR_ADDRESS,
  ERROR_GEO_HIERARCHY,
  ERROR_PRICE_HISTORY,
  ERROR_PRICE_TABLE_DATA, UPDATE_ADDRESS,
  UPDATE_GEO_HIERARCHY,
  UPDATE_PRICE_HISTORY,
  UPDATE_PRICE_TABLE_DATA
} from '../../actions';
import {
  buildReducer,
  defaultReducer,
  realEstateReducer,
  scrollReducer,
  updateKauferPlusEntitlementReducer,
  userIntentReducer
} from '../../reducer';
import navigationReducer from '../../components/Navigation/reducer';
import addressSearchReducer from '../../components/AddressSearch/reducer';
import localStorage from '../../utils/localStorage';
import { PRICE_ATLAS_LAST_FILTERS, PRICE_ATLAS_LAST_SEARCH } from '../../utils/globals';

const nothingSelected = -1;
const lastSearchInput = localStorage.getItem(PRICE_ATLAS_LAST_SEARCH);
const { inputValue, atlasUrlSelected } = lastSearchInput === undefined ? { inputValue: '', atlasUrlSelected: '' } : lastSearchInput

const initialState = {
  selectedSuggestionIndex: nothingSelected,
  isFetching: false,
  suggestionResults: {},
  singleSuggestionResult: {},
  error: '',
  suggestionQuery: '',
  searchInput: inputValue,
  urlSelectedSuggestion: atlasUrlSelected,
  suggestionSelected: lastSearchInput !== undefined,
};

const screenResizeReducer = (state = { screenWidth: Math.min(window.innerWidth, 1040) }, action) => {
  switch (action.type) {
    case SCREEN_RESIZE:
      return Object.assign({}, state, { screenWidth: action.screenWidth });
    default:
      return state;
  }
};

const indexpageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_RESIZE:
      return Object.assign({}, state, { screenWidth: action.screenWidth });
    case SUGGESTIONS_ARROW_UP_EVENT:
      return Object.assign({}, state, {
        selectedSuggestionIndex:
          state.selectedSuggestionIndex > 0 ? state.selectedSuggestionIndex - 1 : state.selectedSuggestionIndex,
      });
    case SUGGESTIONS_ARROW_DOWN_EVENT: {
      const selectedSuggestionIndex =
        state.selectedSuggestionIndex < getSuggestionResultLength(state.suggestionResults) - 1
          ? state.selectedSuggestionIndex + 1
          : state.selectedSuggestionIndex;
      return Object.assign({}, state, { selectedSuggestionIndex });
    }
    case FETCH_SUGGESTIONS_SUCCESS:
      return Object.assign({}, state, { isFetching: false, suggestionResults: action.suggestionsResult });
    case FETCH_SUGGESTIONS_FAILURE:
      return Object.assign({}, state, { isFetching: false, suggestionResults: {}, error: action.error });
    case FETCH_SUGGESTIONS:
      return Object.assign({}, state, { isFetching: true, suggestionQuery: action.suggestionQuery });
    case FETCH_SINGLE_SUGGESTIONS_SUCCESS:
      return Object.assign({}, state, { isFetching: false, singleSuggestionResult: action.suggestionsResult });
    case FETCH_SINGLE_SUGGESTIONS_FAILURE:
      return Object.assign({}, state, { isFetching: false, singleSuggestionResult: {}, error: action.error });
    case FETCH_SINGLE_SUGGESTIONS:
      return Object.assign({}, state, { isFetching: true });
    case CANCEL_ADDRESS_SEARCH:
      return Object.assign({}, state, {
        selectedSuggestionIndex: nothingSelected,
        suggestionQuery: '',
        suggestionResults: {},
      });
    case SAVE_LAST_SEARCH_INPUT: {
      localStorage.setItem(PRICE_ATLAS_LAST_SEARCH, { inputValue: action.inputValue, atlasUrlSelected: action.atlasUrlSelected });
      return state;
    }
    case SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE: {
      localStorage.setItem(PRICE_ATLAS_LAST_FILTERS, { userIntent: action.userIntent, realEstateType: action.realEstateType });
      return state;
    }
    default:
      return state;
  }
};

const defaultStringReducer = (state) => state || '';

const rootReducer = combineReducers({
  indexpage: indexpageReducer,
  scroll: scrollReducer,
  screenResize: typeof window !== 'object' ? defaultReducer : screenResizeReducer,
  realEstateType: realEstateReducer,
  userIntent: userIntentReducer,
  hierarchyLevel: defaultStringReducer,
  config: defaultReducer,
  geoType: defaultStringReducer,
  auth: updateKauferPlusEntitlementReducer,
  navigation: navigationReducer,
  addressSearch: addressSearchReducer,
  address: buildReducer(UPDATE_ADDRESS, ERROR_ADDRESS),
  geoHierarchy: buildReducer(UPDATE_GEO_HIERARCHY, ERROR_GEO_HIERARCHY),
  priceHistory: buildReducer(UPDATE_PRICE_HISTORY, ERROR_PRICE_HISTORY),
  priceTableData: buildReducer(UPDATE_PRICE_TABLE_DATA, ERROR_PRICE_TABLE_DATA),
});

export default rootReducer;
