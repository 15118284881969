import React from 'react';
import PropTypes from 'prop-types';
import styles from './AddressSearchResults.less';
import AddressSearchResultsItem from './Item/AddressSearchResultsItem';
import addressSearchConfig from '../config';
import { getSuggestionResultLength, homePageReportLabels, searchReportLabels } from '../../../utils/suggestionsUtils';

export default class AddressSearchResults extends React.Component {
  renderSuggestionResultItems = (configItem, offset) => {
    const selectedIndex = this.props.selectedIndex % getSuggestionResultLength(this.props.suggestionResults);
    const suggestionResultItems = this.props.suggestionResults[configItem];
    const config = addressSearchConfig[configItem];
    const reportLabel = this.props.isHomePage? homePageReportLabels[configItem] : searchReportLabels[configItem];
    return (
      <div key={configItem} id={configItem} className="padding-bottom-l">
        <p
          key={`${configItem}-title`}
          className="font-bold font-s padding-horizontal-xl padding-top-xl padding-bottom-m"
        >
          {config.title}
        </p>
        {suggestionResultItems.map((item, index) => (
          <AddressSearchResultsItem
            key={index + offset}
            id={index + offset}
            selectedResult={index + offset === selectedIndex}
            suggestionQuery={this.props.suggestionQuery}
            url={item.url}
            addressLineOne={config.addressLineOne(item)}
            addressLineTwo={config.addressLineTwo(item)}
            handleClick={(url) => this.props.handleClick(url, config.addressLineOne(item), reportLabel)}
            generateLink={this.props.generateLink}
          />
        ))}
      </div>
    );
  };

  render() {
    let offset = 0;
    const { suggestionResults, className, resetSelection, onTouchMove } = this.props;

    if (getSuggestionResultLength(suggestionResults) <= 0) {
      return <div />;
    }
    return (
      <div
        key="address-search-results"
        id="suggestion-result"
        onMouseEnter={resetSelection}
        onTouchMove={() => onTouchMove()}
        className={`${styles.resultBlock} ${className}`}
      >
        {Object.keys(addressSearchConfig).map((configItem) => {
          if (suggestionResults[configItem].length > 0) {
            const suggestionResultHtml = this.renderSuggestionResultItems(configItem, offset);
            offset += (suggestionResults[configItem] || []).length;
            return suggestionResultHtml;
          }
          return '';
        })}
      </div>
    );
  }
}

AddressSearchResults.defaultProps = {
  isHomePage: false,
  suggestionQuery: '',
  className: '',
  onTouchMove: () => {},
};

AddressSearchResults.propTypes = {
  suggestionQuery: PropTypes.string,
  className: PropTypes.string,
  suggestionResults: PropTypes.object.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  onTouchMove: PropTypes.func,
  generateLink: PropTypes.func.isRequired,
  isHomePage: PropTypes.bool.isRequired,
};
