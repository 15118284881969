import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({ className, fontSize }) => <span style={{ fontSize }} className={`align-top ${className}`} />;

MenuIcon.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.string,
};

MenuIcon.defaultProps = {
  className: '',
  fontSize: '23px',
};

export default MenuIcon;
