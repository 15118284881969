import { generateQueryStr } from "../../../utils/urlUtils";
import { getCSRFToken } from "../../../utils/getCSRFToken";

function reportHomeOwnerToggle(activated) {
  const queryParams = {
    homeOwnerState: activated
  };
  const url = `/homeOwner?${generateQueryStr(queryParams)}`;
  const token = getCSRFToken()
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'XSRF-TOKEN': `${token}`
    },
    credentials: 'include',
  }).then(() => {
  });
}

export default {
  reportHomeOwnerToggle
};
