import React from 'react';
import PropTypes from 'prop-types';

import { formatDE } from '../../../utils/NumbersHelper.js';
import styles from './PricePerMeter.less';

export default class PricePerMeter extends React.Component {
  render() {
    const { price, color, commercializationType, appearance, futureTrend } = this.props;

    const priceFormat = new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    let formattedPrice = price;

    if (commercializationType === 'BUY') {
      formattedPrice = priceFormat.format(formattedPrice);
    } else {
      formattedPrice = formatDE(formattedPrice);
    }

    switch (appearance) {
      case 'cosma':
        return (
          <div className={futureTrend ? styles.priceCosmaFpt : styles.priceCosma}>
            &Oslash; {formattedPrice} &euro;/m²
          </div>
        );
      case 'large':
        return (
          <div style={{ color }}>
            <span className={styles.large}>&Oslash;&nbsp;</span>
            <span className={styles.pricePerMeterLarge}>
              {formattedPrice}&nbsp;<span className={styles.separator}>€</span>
            </span>
            <span className={styles.large}>
              /m<sup>2</sup>
            </span>
          </div>
        );
      case 'bold':
        return (
          <div style={{ color }}>
            &Oslash;&nbsp;
            <span className={styles.pricePerMeterBold}>{formattedPrice}&nbsp;€</span>/m<sup>2</sup>
          </div>
        );
      case 'cosma-bold':
        return (
          <div style={{ color }}>
            <div className={styles.priceCosma}>{formattedPrice} &euro;/m²</div>
          </div>
        );
      case 'basic':
      default:
        return (
          <div style={{ color }}>
            &Oslash;&nbsp;<span className="font-xl font-light">{formattedPrice}&nbsp;€</span>/m<sup>2</sup>
          </div>
        );
    }
  }
}

PricePerMeter.defaultProps = {
  commercializationType: 'RENT',
  appearance: 'basic',
  futureTrend: false,
};

PricePerMeter.propTypes = {
  appearance: PropTypes.oneOf(['basic', 'bold', 'large', 'cosma', 'cosma-bold']),
  price: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  commercializationType: PropTypes.string,
  futureTrend: PropTypes.bool,
};
