export const PALM_MAX = 668;
export const LAP_MIN = 669;
export const LAP_MAX = 1013;
export const DESK_MIN = 1014;
export const NO_DATA_TEXT = 'Momentan stehen keine Informationen zur Verfügung.';
export const NO_PRICE_RANGE = 'Kein Preis vorhanden';
export const SIDEBAR_FOLDED_STATE_TTL = 24 * 60 * 60 * 1000;
export const TOGGLE_HOME_OWNER_STATE_TTL = 24 * 60 * 60 * 1000;

export const GEO_HIERARCHY_LEVELS = {
  country: 'country',
  region: 'region',
  city: 'city',
  district: 'district',
  street: 'street',
  address: 'address',
};

export * from './featureSwitchFlags';

/* Place to set local storage keys */
export const IS_SIDEBAR_DESKTOP_EXPANDED = 'IS_SIDEBAR_DESKTOP_EXPANDED';
export const IS_HOME_OWNER = 'IS_HOME_OWNER';
export const PRICE_ATLAS_LAST_SEARCH = 'priceAtlasLastSearch';
export const PRICE_ATLAS_LAST_FILTERS = 'priceAtlasLastFilters';
export const HAS_SEEN_HOMEOWNER_TOGGLE_OVERLAY = 'HAS_SEEN_HOMEOWNER_TOGGLE_OVERLAY';
