/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */

export const INTERNAL_DOMAINS = ['immobilienscout24.de', 'localhost', '127.0.0.1'];

function flattenDeep(arr1) {
  return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
}

export function setSearchParams(name, value, oldSearch = window.location.search) {
  if (oldSearch === null) {
    throw new Error('oldSearch is null');
  }
  const urlSearchParams = new URLSearchParams(oldSearch);
  urlSearchParams.set(name, value);
  return `?${urlSearchParams}`;
}

export function appendSearchParamsFromQuery(urlLink, locationQuery = {}) {
  const relativeUrlLink = urlLink.replace('https://atlas.immobilienscout24.de', '');
  const requestQueryPairs = generateQueryStr(locationQuery);
  const parameter = requestQueryPairs.length > 0 ? `?${requestQueryPairs}` : '';

  return `${relativeUrlLink}${parameter}`;
}

export function generateQueryStr(queryParams = {}) {
  const urlSearchParams = new URLSearchParams();
  Object.keys(queryParams).forEach((key) => {
    if (Array.isArray(queryParams[key])) {
      const values = flattenDeep(queryParams[key]);
      values.forEach((value) => {
        urlSearchParams.append(key, value);
      });
    } else {
      urlSearchParams.append(key, queryParams[key]);
    }
  });
  return urlSearchParams.toString();
}

export function getSearchParam(paramName, queryParams = window.location.search) {
  return new URLSearchParams(queryParams).get(paramName);
}

export const getHashParam = (hash, param) => {
  const hashParams = hash.substr(hash.indexOf('?'));
  return new URLSearchParams(hashParams).get(param);
};

export function getDocumentReferer() {
  // When comming form imperva, the original referer for the request is first stored in
  // sessionStorage. When tealium runs, it deletes the value from sessionStorage and saves it
  // to document.referer. We need to cover both cases (before and after document.referer is set)
  // because we don't know if we are running before or after tealium.
  const documentReferrer = document.referrer;
  let sessionStorageReferrer = null;
  try {
    sessionStorageReferrer = sessionStorage.getItem('distil_referrer');
  } catch {}
  return sessionStorageReferrer || documentReferrer;
}

export function isInternalReferer(referer) {
  return referer ? INTERNAL_DOMAINS.some((x) => referer.includes(x)) : false;
}
 export function doesHashMatchTab(hash, tab) {
  return decodeURIComponent(hash) === `#/${tab.name}`
 }
