import { SAVE_LAST_SEARCH_INPUT, SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE } from "./homepage/indexpage/actions";

export const UPDATE_STATIC_MAPS = 'UPDATE_staticMaps';
export const ERROR_STATIC_MAPS = 'ERROR_staticMaps';
export const UPDATE_ADDRESS = 'UPDATE_address';
export const ERROR_ADDRESS = 'ERROR_address';
export const UPDATE_PRICE_HISTORY = 'UPDATE_priceHistory';
export const ERROR_PRICE_HISTORY = 'ERROR_priceHistory';
export const UPDATE_GEO_HIERARCHY = 'UPDATE_geoHierarchy';
export const ERROR_GEO_HIERARCHY = 'ERROR_geoHierarchy';
export const UPDATE_REAL_ESTATE_TYPE = 'UPDATE_realEstateType';
export const SELECT_REAL_ESTATE_TYPE = 'SELECT_REAL_ESTATE_TYPE';
export const UPDATE_DEMOGRAPHIC_INFO = 'UPDATE_demographicInfo';
export const ERROR_DEMOGRAPHIC_INFO = 'ERROR_demographicInfo';
export const SCROLL_Y = 'SCROLL_Y';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const HASH_UPDATED = 'HASH_UPDATED';
export const UPDATE_SUB_HIERARCHY_INFO = 'UPDATE_subHierarchyInfo';
export const ERROR_SUB_HIERARCHY_INFO = 'ERROR_subHierarchyInfo';
export const UPDATE_SIBLINGS_HIERARCHY_INFO = 'Update_siblingsHierarchyInfo';
export const ERROR_SIBLINGS_HIERARCHY_INFO = 'ERROR_siblingsHierarchyInfo';
export const UPDATE_PRICE_TABLE_DATA = 'UPDATE_priceTableData';
export const ERROR_PRICE_TABLE_DATA = 'ERROR_priceTableData';
export const UPDATE_USER_INTENT = 'UPDATE_USER_INTENT';
export const SELECT_USER_INTENT = 'SELECT_USER_INTENT';
export const SET_IS_INTERNAL_REFERER = 'SET_IS_INTERNAL_REFERER';
export const UPDATE_KAUFER_PLUS_ENTITLEMENT = 'UPDATE_KAUFER_PLUS_ENTITLEMENT';

const actionCreators = {
  selectRealEstateType: (value) => ({
    type: SELECT_REAL_ESTATE_TYPE,
    value,
  }),

  selectUserIntent: (value) => ({
    type: SELECT_USER_INTENT,
    value,
  }),

  updateUserIntent: (value) => ({
    type: UPDATE_USER_INTENT,
    value,
  }),

  updateIsInternalReferer: (value) => ({
    type: SET_IS_INTERNAL_REFERER,
    value,
  }),

  updateKauferPlusEntitlement: (value) => ({
    type: UPDATE_KAUFER_PLUS_ENTITLEMENT,
    value,
  }),

  scrollY: (y) => ({ type: SCROLL_Y, value: y }),
  setWindowWidth: (width) => ({ type: WINDOW_WIDTH, value: width }),
  setLocationHash: (hash) => ({ type: HASH_UPDATED, value: hash }),

  saveLastSearchInput: (inputValue, atlasUrlSelected) => ({
    type: SAVE_LAST_SEARCH_INPUT,
    inputValue,
    atlasUrlSelected
  }),

  saveLastUserIntentAndPropertyType: (userIntent, realEstateType) => ({
    type: SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE,
    userIntent,
    realEstateType
  }),
};

export default actionCreators;
