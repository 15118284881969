import { memoize } from 'lodash';

const replaceDoubleNameInner = memoize((name) => name.replace(/(.+)\s\(\1\)/, '$1'));

export const replaceDoubleName = (name) => {
  if (!name) {
    return name;
  }
  return replaceDoubleNameInner(name);
};
