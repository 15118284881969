import React from 'react';
import styles from './PriceTrendChartInstructionMobile.less';

const PriceTrendChartInstructionMobile = () => (
  <div className="grid">
    <div className={`float-left margin-right-l ${styles.instructionImageContainer}`}>
      <img
        alt=""
        className={styles.instructionImage}
        src="https://www.static-immobilienscout24.de/statpic/sales-journey/it/atlas/20973fc001804e2672981c8169958a96_guide_hand_gesture_finger_touch_icon.png"
      />
    </div>
    <span className="font-lightgray">Fahren Sie entlang des Graphen, um ein anderes Jahr auszuwählen.</span>
  </div>
);

export default PriceTrendChartInstructionMobile;
