import React from 'react';
import PropTypes from 'prop-types';

export default function Dropdown(props) {
  const { options, selected, onChangeHandler, id } = props;
  return (
    <div className="select-container">
      <div className="select-input-wrapper">
        <select className="select padding-right-xl" id={id} value={selected} onChange={onChangeHandler}>
          {options.map((item) => (
            <option value={`${item.value}`} key={`${item.value}`}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

const Option = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

Dropdown.defaultProps = {
  onChangeHandler: null,
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(Option).isRequired,
  onChangeHandler: PropTypes.func,
  selected: PropTypes.string.isRequired,
};
