import React from 'react';
import Media from 'react-media';
import { useStore } from 'react-redux';
import { PALM_MAX, LAP_MIN, LAP_MAX, DESK_MIN } from '../../../utils/globals.js';
import State from "../../../store/state";

const width = {
  palmMax: PALM_MAX,
  lapMin: LAP_MIN,
  lapMax: LAP_MAX,
  deskMin: DESK_MIN,
};

type MediaParams = {
  render?: (() => React.ReactNode);
  minWidth?: number;
  maxWidth?: number;
  children?: JSX.Element | JSX.Element[];
};

// Desktop, tablet and mobile setup
export const Desktop = ({ render, minWidth, children }: MediaParams) => {
  const state: State = useStore().getState() as State;
  const device = state.config.device;

  return (
    <Media
      query={{ minWidth: minWidth || width.deskMin }}
      render={render}
      defaultMatches={device === 'desktop'}
    >
      {children}
    </Media>
  );
}

export const Tablet = ({ render, maxWidth, children }: MediaParams) => {
  const state: State = useStore().getState() as State;
  const device = state.config.device;

  return (
    <Media
      query={{ minWidth: width.lapMin, maxWidth: maxWidth || width.lapMax }}
      render={render}
      defaultMatches={device === 'tablet'}
    >
      {children}
    </Media>
  );
}
export const Mobile = ({ render, children }: MediaParams) => {
  const state: State = useStore().getState() as State;
  const device = state.config.device;

  return (
    <Media
      query={{ maxWidth: width.palmMax }}
      render={render}
      defaultMatches={device === 'mobile'}
    >
      {children}
    </Media>
  );
}

// Combined setups
export const TabletAndMobile = ({ render, children }: MediaParams) => {
  const state: State = useStore().getState() as State;
  const device = state.config.device;

  return (
    <Media
      query={{ maxWidth: width.lapMax }}
      render={render}
      defaultMatches={device === 'mobile' || device === 'tablet'}
    >
      {children}
    </Media>
  );
};

export const DesktopAndTablet = ({ render, children }: MediaParams) => {
  const state: State = useStore().getState() as State;
  const device = state.config.device;

  return (
    <Media
      query={{ minWidth: width.lapMin }}
      render={render}
      defaultMatches={device === 'desktop' || device === 'tablet'}
    >
      {children}
    </Media>
  );
};
