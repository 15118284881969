import { max, min, filter } from 'lodash';
import { PRIMARY_BLUE, PRIMARY_GREEN } from '../../../utils/colorPalette.js';

export function toNumber(year, quarter) {
  return year + (quarter ? 0.25 * (quarter - 1) : 0);
}

export function getDiff(currentPrice, previousPrice) {
  return (currentPrice - previousPrice) / previousPrice;
}

export function formatQuarter(quarter, year) {
  return `Q${quarter} ${year}`;
}

export function getStatsBoxData(selectedQuarter, nativeData, parentData, currentLocation, parentLocation, isMobile) {
  let { year, quarter } = toYearAndQuarter(selectedQuarter);
  const [nativeCurrentQData] = filter(nativeData, { year, quarter });
  const [parentCurrentQData] = filter(parentData, { year, quarter });
  const nativeCurrentYearData = filter(nativeData, { year });
  const parentCurrentYearData = filter(parentData, { year });
  const nativePreviousYearData = nativeData.filter((d) => d.year === year - 1);
  const parentPreviousYearData = parentData.filter((d) => d.year === year - 1);
  const [nativePreviousYearQData] = filter(nativeData, { year: year - 1, quarter });
  const [parentPreviousYearQData] = filter(parentData, { year: year - 1, quarter });

  if(!isMobile) {
    if (quarter === 1) {
      year -= 1;
      quarter = 4;
    } else {
      quarter -= 1;
    }
  } else {
    year -= 1
  }

  const [nativePreviousQData] = filter(nativeData, { year, quarter });
  const [parentPreviousQData] = filter(parentData, { year, quarter });

  const statsBoxDataQ = [];
  const statsBoxDataYear = [];

  populateArrayWithQData(statsBoxDataQ, currentLocation, nativeCurrentQData, nativePreviousQData, nativePreviousYearQData, PRIMARY_BLUE());
  populateArrayWithQData(statsBoxDataQ, parentLocation, parentCurrentQData, parentPreviousQData, parentPreviousYearQData, PRIMARY_GREEN());
  populateArrayWithYearData(
    statsBoxDataYear,
    currentLocation,
    nativeCurrentYearData,
    nativePreviousYearData,
    PRIMARY_BLUE(),
  );
  populateArrayWithYearData(
    statsBoxDataYear,
    parentLocation,
    parentCurrentYearData,
    parentPreviousYearData,
    PRIMARY_GREEN(),
  );

  return {
    statsBoxDataQ,
    statsBoxDataYear,
  };
}

export const toYearAndQuarter = (selectedQuarter) => {
  const quarterAsDecimal = selectedQuarter % 1;
  const quarter = quarterAsDecimal / 0.25 + 1;

  const year = Math.floor((selectedQuarter * 100) / 100);

  return { year, quarter };
};

export const getLatestQuarterStats = (quarters, locationName) => {
  const sortedQuarterData = sortByQuarterAndYear(quarters);

  const latestQuarterData = sortedQuarterData[0];
  const previousQuarterData = filter(sortedQuarterData, previousQuarterAndYear(latestQuarterData))[0];

  const result = [];
  populateArrayWithQData(result, locationName, latestQuarterData, previousQuarterData);
  return result[0];
};

const previousQuarterAndYear = ({ quarter, year }) =>
  quarter === 1 ? { quarter: 4, year: year - 1 } : { quarter: quarter - 1, year };

const sortByQuarterAndYear = (allQuartersData) =>
  allQuartersData.slice().sort((a, b) => toNumber(b.year, b.quarter) - toNumber(a.year, a.quarter));

const populateArrayWithQData = (array, locationName, currentData, previousQData, previousYearQData, color) => {
  if (currentData) {
    let deltaToPreviousQ;
    let previousQ;

    if (previousQData) {
      previousQ = `Q${previousQData.quarter} ${previousQData.year}`;
      deltaToPreviousQ = getDiff(currentData.price, previousQData.price);
    }
    const currentQ = `Q${currentData.quarter} ${currentData.year}`;

    let deltaToPreviousYearQ, previousYearQ;
    if (previousYearQData) {
      previousYearQ = `Q${previousYearQData.quarter} ${previousYearQData.year}`;
      deltaToPreviousYearQ = getDiff(currentData.price, previousYearQData.price);
    }
    array.push({
      locationName,
      price: currentData.price,
      previousQ,
      currentQ,
      deltaToPreviousQ,
      previousYearQ,
      deltaToPreviousYearQ,
      color,
    });
  }
};

const populateArrayWithYearData = (array, locationName, currentYearData, previousYearData, color) => {
  if (currentYearData && currentYearData.length) {
    let deltaToPreviousYear;
    let previousYear;
    const currentYearPrice = currentYearData.reduce((acc, p) => acc + p.price, 0) / currentYearData.length;

    if (previousYearData && previousYearData[0]) {
      previousYear = `${previousYearData[0].year}`;
      const previousYearPrice = previousYearData.reduce((acc, p) => acc + p.price, 0) / previousYearData.length;
      deltaToPreviousYear = getDiff(currentYearPrice, previousYearPrice);
    }

    array.push({
      locationName,
      price: currentYearPrice,
      previousYear,
      deltaToPreviousYear,
      color,
    });
  }
};

export const getLatestPointInTime = ({ nativeData = [], parentData = [] }) =>
  max([...nativeData, ...parentData].map(({ year, quarter }) => toNumber(year, quarter)));

export const getCurrentPointInTime = ({ nativeData = [], parentData = [] }) =>
  max(
    [...nativeData, ...parentData]
      .filter((data) => !data.futureTrend)
      .map(({ year, quarter }) => toNumber(year, quarter)),
  );

export const getCurrentPointInTimeMobile = ({ nativeData = [], parentData = [] }) =>
  max([...nativeData, ...parentData].filter((data) => !data.futureTrend).map(({ year }) => year));

export const getFirstPointInTime = ({ nativeData = [], parentData = [] }) =>
  min([...nativeData, ...parentData].map(({ year, quarter }) => toNumber(year, quarter)));
