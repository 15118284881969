export const svgDimensions = { width: 160, height: 230 };

export const svgMargins = {
  top: 50,
  right: 30,
  bottom: 50,
  left: 30,
};

export const ticksConfigWidgetDesktop = {
  dx: {
    tickSize: 15,
    tickLengthOffset: 15,
  },
  dy: {
    tickSize: 30,
    tickLengthOffset: 30,
    ticksNumber: 4,
  },
};

export const svgMarginsMobile = {
  top: 42,
  right: 16,
  bottom: 25,
  left: 16,
};

export const ticksConfigWidgetMobile = {
  dx: {
    tickSize: 0,
    tickLengthOffset: 0,
  },
  dy: {
    tickSize: 0,
    tickLengthOffset: 0,
    ticksNumber: 4,
  },
};
