import { combineReducers } from 'redux';
import addressSearchReducer from './components/AddressSearch/reducer';
import googleAddressSearchReducer from './components/GoogleAddressSearch/reducer';
import navigationReducer from './components/Navigation/reducer.js';
import priceHistoryReducer from './components/PriceHistory/reducer.js';
import priceCheckReducer from './components/PriceCheck/reducer.js';

import {
  UPDATE_STATIC_MAPS,
  ERROR_STATIC_MAPS,
  UPDATE_ADDRESS,
  ERROR_ADDRESS,
  UPDATE_PRICE_HISTORY,
  ERROR_PRICE_HISTORY,
  UPDATE_GEO_HIERARCHY,
  ERROR_GEO_HIERARCHY,
  SELECT_REAL_ESTATE_TYPE,
  UPDATE_REAL_ESTATE_TYPE,
  UPDATE_DEMOGRAPHIC_INFO,
  ERROR_DEMOGRAPHIC_INFO,
  SCROLL_Y,
  WINDOW_WIDTH,
  HASH_UPDATED,
  UPDATE_SUB_HIERARCHY_INFO,
  ERROR_SUB_HIERARCHY_INFO,
  SELECT_USER_INTENT,
  UPDATE_USER_INTENT,
  SET_IS_INTERNAL_REFERER,
  UPDATE_KAUFER_PLUS_ENTITLEMENT,
  UPDATE_PRICE_TABLE_DATA,
  ERROR_PRICE_TABLE_DATA,
  UPDATE_SIBLINGS_HIERARCHY_INFO,
  ERROR_SIBLINGS_HIERARCHY_INFO,
} from './actions.js';

import realEstateTypes, { getCommercializationType } from './realEstateTypes.js';
import userIntents from './userIntents.js';
import { isInternalReferer } from './utils/urlUtils';
import { SAVE_LAST_SEARCH_INPUT, SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE } from './homepage/indexpage/actions';
import localStorage from './utils/localStorage';
import { PRICE_ATLAS_LAST_FILTERS, PRICE_ATLAS_LAST_SEARCH } from './utils/globals';


const initialState = { status: 'LOADING' };
const initialLocationState = { query: {} };
export const realEstateInitialState = realEstateTypes.APARTMENT_BUY;
export const userIntentInitialState = userIntents.SELL;

export const defaultReducer = (state) => state || {};

export const buildReducer = (updateAction, errorAction) => (state = initialState, action) => {
  switch (action.type) {
    case updateAction:
      return Object.assign({}, state, { status: 'DONE', data: action.value });
    case errorAction:
      return Object.assign({}, state, { status: 'ERROR' });
    default:
      return state;
  }
};

export const realEstateReducer = (state = realEstateInitialState, action) => {
  switch (action.type) {
    case SELECT_REAL_ESTATE_TYPE:
      return action.value;
    case UPDATE_REAL_ESTATE_TYPE:
      return action.value ? action.value : state;
    default:
      return state;
  }
};

export const userIntentReducer = (state = userIntentInitialState, action) => {
  switch (action.type) {
    case SELECT_USER_INTENT:
      return action.value;
    case UPDATE_USER_INTENT:
      return action.value ? getCommercializationType(action.value) : state;
    default:
      return state;
  }
};

export const locationReducer = (state = initialLocationState, action) => {
  switch (action.type) {
    case SELECT_USER_INTENT:
      state.query.userIntent = action.value;

      return state;
    case UPDATE_USER_INTENT:
      if (!action.value) {
        return state
      }
      state.query.userIntent = getCommercializationType(action.value);

      return state;
    case SELECT_REAL_ESTATE_TYPE:
      state.query.marketingFocus = action.value;

      return state;
    case UPDATE_REAL_ESTATE_TYPE:
      if (!action.value) {
        return state
      }
      state.query.marketingFocus = action.value;

      return state;
    default:
      return state;
  }
};

export const scrollReducer = (state = { scroll_y: 0 }, action) => {
  switch (action.type) {
    case SCROLL_Y:
      return Object.assign({}, state, { scroll_y: action.value });
    default:
      return state;
  }
};

// TODO mhartnagel: decide on fallback 1024
const resizeReducer = (state = { width: typeof window === 'object' ? window.innerWidth : 1024 }, action) => {
  switch (action.type) {
    case WINDOW_WIDTH:
      return Object.assign({}, state, { width: action.value });
    default:
      return state;
  }
};

export const pathnameReducer = (state = '', action) => {
  switch (action.type) {
    case HASH_UPDATED:
      return action.value ? action.value : state;
    default:
      return state;
  }
};

export const isInternalRefererReducer = (state = false, action) => {
  switch (action.type) {
    case SET_IS_INTERNAL_REFERER:
      return isInternalReferer(action.value);
    default:
      return state;
  }
};

export const updateKauferPlusEntitlementReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_KAUFER_PLUS_ENTITLEMENT:
      return Object.assign({}, state, { kauferPlusEntitlement: action.value });
    default:
      return state || {};
  }
};

export const searchInputReducer = (state = {}, action) => {
  switch(action.type) {
    case SAVE_LAST_SEARCH_INPUT: {
      localStorage.setItem(PRICE_ATLAS_LAST_SEARCH, { inputValue: action.inputValue, atlasUrlSelected: action.atlasUrlSelected });
      return state;
    }
    case SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE: {
      localStorage.setItem(PRICE_ATLAS_LAST_FILTERS, { userIntent: action.userIntent, realEstateType: action.realEstateType });
      return state;
    }
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  addressSearch: addressSearchReducer,
  googleAddressSearch: googleAddressSearchReducer,
  hierarchyLevel: defaultReducer,
  geoType: defaultReducer,
  realEstateType: realEstateReducer,
  realEstateTypeParam: defaultReducer,
  address: buildReducer(UPDATE_ADDRESS, ERROR_ADDRESS),
  geoHierarchy: buildReducer(UPDATE_GEO_HIERARCHY, ERROR_GEO_HIERARCHY),
  staticMaps: buildReducer(UPDATE_STATIC_MAPS, ERROR_STATIC_MAPS),
  priceHistory: buildReducer(UPDATE_PRICE_HISTORY, ERROR_PRICE_HISTORY),
  navigation: navigationReducer,
  demographicInfo: buildReducer(UPDATE_DEMOGRAPHIC_INFO, ERROR_DEMOGRAPHIC_INFO),
  scroll: scrollReducer,
  config: defaultReducer,
  pageLink: defaultReducer,
  window: resizeReducer,
  priceCheckSettings: priceCheckReducer,
  priceHistorySettings: priceHistoryReducer,
  pathname: pathnameReducer,
  subHierarchyInfo: buildReducer(UPDATE_SUB_HIERARCHY_INFO, ERROR_SUB_HIERARCHY_INFO),
  buildingShapes: defaultReducer,
  userIntent: userIntentReducer,
  userIntentParam: defaultReducer,
  isInternalReferer: isInternalRefererReducer,
  auth: updateKauferPlusEntitlementReducer,
  duplicateDistricts: defaultReducer,
  priceTableData: buildReducer(UPDATE_PRICE_TABLE_DATA, ERROR_PRICE_TABLE_DATA),
  siblingsHierarchyInfo: buildReducer(UPDATE_SIBLINGS_HIERARCHY_INFO, ERROR_SIBLINGS_HIERARCHY_INFO),
  location: locationReducer,
  searchInput: searchInputReducer,
});

export default rootReducer;
