import React from 'react';
import PropTypes from 'prop-types';
import styles from './PriceDeltaLegend.less';

const PriceDeltaLegend = ({ locationName, color }) => (
  <div className={`font-ellipsis one-whole font-nowrap inline-block ${styles.legend}`}>
    <span style={{ background: color }} className={`inline-block ${styles.circle} margin-right-xs`} />
    <span className="font-xs font-lightgray">{locationName}</span>
  </div>
);

PriceDeltaLegend.propTypes = {
  locationName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default PriceDeltaLegend;
