// localStorage: exports a wrapper around window.localStorage that:
//   - handles when localStorage is unavailable.
//   - provides a way of having entries expire after a period of time, given when setting the entry.

const nullLocalStorage = {
  getItem: () => null,
  setItem: () => undefined,
};

const getLocalStorage = () => {
  try {
    if (typeof process === 'object' && process.env.BABEL_ENV === 'test') {
      return nullLocalStorage;
    }
    // eslint-disable-next-line no-restricted-properties
    return window.localStorage;
  } catch (error) {
    return nullLocalStorage;
  }
};

export const createLocalStorage = (storage) => ({
  getItem: (...args) => {
    if (args.length < 1) {
      throw new TypeError("Failed to execute 'getItem': no key provided");
    }
    const [key] = args;
    try {
      const item = storage.getItem(key);
      if (!item) {
        return undefined;
      }
      const { value, expiresAt } = JSON.parse(item);

      if (expiresAt && expiresAt < new Date().getTime()) {
        return undefined;
      }

      return value;
    } catch (error) {
      return undefined;
    }
  },
  setItem: (...args) => {
    if (args.length < 2) {
      throw new TypeError("Failed to execute 'setItem': no key and/or value provided");
    }
    const [key, value, ttl] = args;
    try {
      return storage.setItem(
        key,
        JSON.stringify({
          value,
          expiresAt: ttl ? new Date().getTime() + ttl : null,
        }),
      );
    } catch (error) {
      return undefined;
    }
  },
});

const localStorageInstance = createLocalStorage(getLocalStorage());

export default localStorageInstance;
