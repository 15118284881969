import React, { useEffect } from 'react';
import { select as d3Select } from 'd3-selection';
import PropTypes from 'prop-types';

import styles from '../styles.less';
import axes from '../axisConfig.js';

const Axis = (props) => {
  let axisElement, minorTicksElement;

  useEffect(() => {
    renderAxis();
  });
  const { orient, scale, tickSize, translate, ticksNumber, axisLabel, majorTickPadding, minorTickPadding } = props;
  const { values: tickValues, format: tickFormat } = props.ticks;

  const renderAxis = () => {
    const minMajor = tickValues.major[0] || -1;
    const minMinor = tickValues.minor[0] || 0;
    const axis = axes[orient]()
      .scale(scale)
      .tickSizeInner(tickSize)
      .tickSizeOuter(minMajor < minMinor ? tickSize : 0)
      .tickPadding([majorTickPadding])
      .tickFormat(tickFormat.major);

    const minorTicks = axes[orient]()
      .scale(scale)
      .tickSizeInner(tickSize / 2)
      .tickSizeOuter(minMinor < minMajor ? tickSize / 2 : 0)
      .tickPadding([minorTickPadding])
      .tickValues(tickValues.minor)
      .tickFormat(tickFormat.minor);

    d3Select(axisElement).call(
      tickValues.major.length > 0 ? axis.tickValues(tickValues.major) : axis.ticks(ticksNumber || 4),
    );

    d3Select(minorTicksElement).call(minorTicks).selectAll('g').classed(styles.minor, true);

    if (axisLabel) {
      renderAxisLabel();
    }
  }

  const renderAxisLabel = () => {
    // logic to render the axisLabel only once per axis
    if (document.querySelectorAll(`.axisLabel__${orient}`).length === 0) {
      d3Select('svg#priceChartSvg')
        .append('text')
        .classed(styles.axisLabel, true)
        .classed(`axisLabel__${orient}`, true)
        .text(axisLabel.label)
        .attr('transform', axisLabel.transform)
        .attr('text-anchor', 'middle');
    }
  }

  return [
    <g
      className={styles.axis}
      ref={(el) => {
        axisElement = el;
      }}
      transform={translate}
      key="major-ticks"
    />,
    <g
      className={styles.axis}
      ref={(el) => {
        minorTicksElement = el;
      }}
      transform={translate}
      key="minor-ticks"
    />,
  ];
};

Axis.propTypes = {
  orient: PropTypes.string.isRequired,
  scale: PropTypes.func.isRequired,
  translate: PropTypes.string.isRequired,
  tickSize: PropTypes.number.isRequired,
  majorTickPadding: PropTypes.number,
  minorTickPadding: PropTypes.number,
  ticks: PropTypes.object.isRequired,
  ticksNumber: PropTypes.number,
};

Axis.defaultProps = {
  ticksNumber: 4,
  majorTickPadding: 8,
  minorTickPadding: 6,
};

export default Axis;
