/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import actions from '../../actions';
import tracking from '../../../../tracking/tracking.js';
import addressSearchConfig from '../../../../components/AddressSearch/config';
import Dropdown from '../../../../components/Dropdown/Dropdown.jsx';
import { DesktopAndTablet, Mobile } from '../../../../components/Utils/MediaComponents/MediaComponents';
import { propertyTypes, userIntentsReduced } from '../../../../utils/userIntentConfig';
import { getPropertyType } from '../../../../realEstateTypes.js';
import { toCommercializationType } from '../../../../userIntents.js';
import { getRealEstateType, getUserIntent, isMobileNavigationMenuOpen } from '../../../../selectors/selectors.js';
import { generateLink, getNameFromSuggestion } from '../../../../utils/suggestionsUtils';
import styles from './SavingHomepageAddressSearch.less';
import {
  INDEX_SEARCH_CLICK_ON_SEARCH_FIELD,
  INDEX_SEARCH_ENTER_ADDRESS,
  PROPERTY_BOOK
} from '../../../../tracking/trackingNaming';
import HomeOwnerToggle from '../../../../components/Navigation/HomeOwnerToggle/HomeOwnerToggle';
import ContainerSearchMobile
  from '../../../../components/Navigation/Sidebar/ContainerSearchMobile/ContainerSearchMobile';
import actionCreators from '../../../../components/Navigation/actions';
import NewHomepageAddressSearchResults from './NewHomepageAddressSearchResults';
import { setSearchParams } from '../../../../utils/urlUtils';
import { isFeatureOn } from "../../../../utils/featureSwitchReader";

type SavingHomepageAddressSearchProps = {
  propertyType: string,
  userIntent: string,
  cancelSearch: () => never,
  updateSuggestionQuery: (x: string) => never,
  suggestionsArrowDownEvent: () => never,
  suggestionsArrowUpEvent: () => never,
  selectRealEstateType: (x: string) => never,
  selectUserIntent: (x: string) => never,
  suggestionResults: object,
  suggestionQuery: string,
  selectedSuggestionIndex: number,
  onButtonOpen: () => never,
  saveLastSearchInput: (x: string, y: string) => never,
  saveLastUserIntentAndPropertyType: (x: string, y: string) => never,
  searchInput: string,
  urlSelectedSuggestion: string,
  suggestionSelected: boolean,
  isMobileMenuOpen: boolean,
  isFeatureOn: (x: string) => boolean,
}

export const SavingHomepageAddressSearch = (props: SavingHomepageAddressSearchProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState(props.searchInput);
  const [urlSelectedSuggestion, setUrlSelectedSuggestion] = useState(props.urlSelectedSuggestion);
  const [suggestionSelected, setSuggestionSelected] = useState(props.suggestionSelected);
  const [showStickyButton, setShowStickyButton] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [enterAddressReported, setEnterAddressReported] = useState(false);
  const stickyButtonRef: RefObject<any> = useRef();
  const userIntent: string = useSelector(state => getUserIntent(state));
  const propertyType: string = useSelector(state => getPropertyType(getRealEstateType(state)));
  const isMobileMenuOpen: boolean = useSelector(state => isMobileNavigationMenuOpen(state));

  useEffect(() => {
    document.addEventListener('scroll', checkSticky);
    document.addEventListener('resize', checkSticky);
    setIsMounted(true);
    checkSticky();

    return () => {
      document.removeEventListener('scroll', checkSticky);
      document.removeEventListener('resize', checkSticky);
      setIsMounted(false);
    };
  },[]);

  useEffect(() => {
    document.body.setAttribute('data-hp-sticky-button', showStickyButton ? 'true' : 'false');
  }, [showStickyButton]);

  const flattenSuggestionResult = (suggestionResults: any) => {
    return Object.keys(addressSearchConfig).reduce((acc: any[], configItem: string) => {
      const values = suggestionResults[configItem].map((value: any) => ({ ...value, 'addressType': configItem }))
      return values ? [...acc, ...values] : acc;
    }, []);
  };

  const checkSticky = () => {
    if (!stickyButtonRef.current) {
      return;
    }
    const rect = stickyButtonRef.current.getBoundingClientRect();
    if (!rect) {
      return;
    }
    if (rect.y < -20) {
      setShowStickyButton(true);
    } else {
      setShowStickyButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      const input = document.querySelector("input");
      input?.focus();
    }, 1000);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      props.cancelSearch();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      props.suggestionsArrowUpEvent();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      props.suggestionsArrowDownEvent();
    } else if (event.key === 'Enter') {
      const {
        selectedSuggestionIndex,
        suggestionResults,
        suggestionQuery
      } = props;
      const suggestions = flattenSuggestionResult(suggestionResults);

      if (suggestions.length === 0 || selectedSuggestionIndex === -1) {
        return;
      }

      const name = getNameFromSuggestion(suggestions[selectedSuggestionIndex]);
      const url = generateLink(suggestions[selectedSuggestionIndex], suggestionQuery);

      setSelectedSuggestion(name, url);
      props.cancelSearch();
    }
  };

  const handleSearchInput = (event: any) => {
    setSuggestionSelected( false)
    setSearchInput(event.target.value);
    props.updateSuggestionQuery(event.target.value);
  };

  const handleChange = (event: any) => {
    if (!enterAddressReported) {
      setEnterAddressReported(true);
      tracking.report(INDEX_SEARCH_ENTER_ADDRESS, PROPERTY_BOOK);
    }
    setSearchInput(event.target.value);
  };

  const handleClick = () => {
    tracking.report(INDEX_SEARCH_CLICK_ON_SEARCH_FIELD, PROPERTY_BOOK);
  };

  const handlePropertyTypeChange = (event: any) => {
    const value = event.target.value;

    props.selectRealEstateType(`${value}_${toCommercializationType(userIntent)}`);
  };

  const handleUserIntentChange = (event: any) => {
    const value = event.currentTarget.value;

    props.selectUserIntent(value);
    props.selectRealEstateType(`${propertyType}_${toCommercializationType(value)}`);
  };

  const setSelectedSuggestion = (name: string, link: string) => {
    setSuggestionSelected(true);
    setInvalidInput(false);

    setSearchInput(name);
    setUrlSelectedSuggestion(link);
  };

  const validateSearch = () => {
    if(suggestionSelected && urlSelectedSuggestion) {
      saveTrackAndNavigate();
    } else {
      setInvalidSearch();
    }
  };

  const setInvalidSearch = () => {
    setInvalidInput(true);
    setSearchInput('');
    setUrlSelectedSuggestion('');
  };

  const saveTrackAndNavigate = () => {
    props.saveLastSearchInput(searchInput, urlSelectedSuggestion);
    props.saveLastUserIntentAndPropertyType(userIntent, generateRealEstateType());

    const reportLabel = `${propertyType.toLowerCase()}_${userIntent.toLowerCase()}`;
    const urlRedirect = addRealEstateTypeAndUserIntentToUrl(urlSelectedSuggestion);

    tracking.report(reportLabel, PROPERTY_BOOK).then(() => {
      window.location.assign(urlRedirect);
    });
  };

  const addRealEstateTypeAndUserIntentToUrl = (url: string) => {
    const q0 = window.location.search;
    const q1 = setSearchParams('marketingFocus', encodeURIComponent(generateRealEstateType()), q0);
    const q2 = setSearchParams('userIntent', encodeURIComponent(userIntent), q1);
    const newUrl = url.replace(/\?.*/, '').replace(/#.*/, '');
    return `${newUrl}${q2}`;
  };

  const generateRealEstateType = () => {
    return `${propertyType}_${toCommercializationType(userIntent)}`;
  };

  const onFocusMobile = (event: any) => {
    event.target.blur();
    onButtonOpen();
  }

  const { onButtonOpen } = props;

  return (
    <div id="address-search-box">
      <div className={`grid ${styles.searchContainer}`}>
        <p className={`grid-item one-whole font-bold font-line-s margin-bottom-xs palm-margin-bottom-m ${styles.bigHeadline}`}>
          Entdecken Sie Ihren lokalen Immobilienmarkt
        </p>
        <p className={`grid-item one-whole font-lightgray margin-bottom-m palm-margin-bottom-xl ${styles.subHeadline}`}>
          Basierend auf über 6 Milliarden Immobiliendaten
        </p>

        <div className="search-section grid-item desk-four-fifths lap-four-fifths palm-one-whole">
          <div className="grid grid-flex">
            <DesktopAndTablet
              render={() => (
                <>
                  <div className={`grid-item ${styles.searchInputWrapper} one-whole`}>
                    <div className="grid grid-flex">
                      <div className={`grid-item desk-three-fifths lap-three-fifths palm-one-whole absolute-reference`}>
                        <input
                          type="text"
                          placeholder="Stadt, Stadtviertel oder Adresse eingeben"
                          className={`one-whole padding-left-m ${invalidInput ? styles.searchInputWrapperInvalid : null}`}
                          autoComplete="off"
                          /* eslint-disable-next-line jsx-a11y/no-autofocus */
                          autoFocus
                          onBlur={() => props.cancelSearch()}
                          id="search-input"
                          onInput={(e) => handleSearchInput(e)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          value={searchInput}
                          onChange={handleChange}
                          onClick={handleClick}
                        />
                        <NewHomepageAddressSearchResults selectSuggestion={setSelectedSuggestion} selectedSuggestionIndex={props.selectedSuggestionIndex} />
                      </div>
                      <div className={`grid-item desk-one-fifth lap-one-fifth palm-hide border-right ${styles.borderColor}`}>
                        <Dropdown
                          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                          /* @ts-ignore */
                          id="userIntentTypesDropdown"
                          options={userIntentsReduced}
                          selected={userIntent}
                          onChangeHandler={handleUserIntentChange} />
                      </div>
                      <div className={`grid-item desk-one-fifth lap-one-fifth palm-hide ${styles.borderColor}`}>
                        <Dropdown
                          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                          /* @ts-ignore */
                          id="propertyTypesDropdown"
                          options={propertyTypes}
                          selected={propertyType}
                          onChangeHandler={handlePropertyTypeChange} />
                      </div>
                    </div>
                  </div>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    /* @ts-ignore */}
                  <HomeOwnerToggle additionalClasses={'palm-hide'} isHomePage={true} />
                </>
              )}>
            </DesktopAndTablet>
            <Mobile
              render={() => (
                <div className={`grid-item ${styles.searchInputWrapper} one-whole`}>
                  <div className="grid grid-flex">
                    <input
                      type="text"
                      placeholder="Stadt, Stadtviertel oder Adresse eingeben"
                      className="grid-item padding-left-m desk-three-fifths lap-three-fifths palm-one-whole"
                      autoComplete="off"
                      onFocus={(e) => onFocusMobile(e)}
                      onTouchStart={handleClick}
                    />
                  </div>
                </div>
              )}>
            </Mobile>
          </div>
        </div>
        <DesktopAndTablet
          render={() => (
            <button
              type="submit"
              ref={stickyButtonRef}
              onClick={() => validateSearch()}
              data-qa="submit-search"
              className="button-primary grid-item desk-one-fifth lap-one-fifth palm-one-whole"
            >
              Preise prüfen
            </button>
          )}
        >
        </DesktopAndTablet>
        <Mobile
          render={() => (
            <button
              type="submit"
              ref={stickyButtonRef}
              className="button-primary grid-item desk-one-fifth lap-one-fifth palm-one-whole"
              onClick={() => onButtonOpen()}
            >
              Preise prüfen
            </button>
          )}
        >
        </Mobile>

        {isMounted && (
          <Mobile render={() => (
            <>
              {isMobileMenuOpen && <ContainerSearchMobile isHomePage={true} /> }
            </>
          )}
          >
          </Mobile>
        )}
      </div>
      {isMounted && (
        <>
          <DesktopAndTablet
            render={() => (
              <div
                data-visible={showStickyButton ? 'yes' : 'no'}
                className={`${styles.stickyButtonLine} align-center`}
              >
                <button
                  data-qa="stickyButton"
                  type="button"
                  className="button-primary"
                  onClick={() => scrollToTop() }>
                  Preise für meine Nachbarschaft prüfen
                </button>
              </div>
            )}
          >
          </DesktopAndTablet>
          <Mobile
            render={() => (
              <div
                data-visible={showStickyButton ? 'yes' : 'no'}
                className={`${styles.stickyButtonLine}`}
              >
                <div className="grid grid-flex gutter-m grid-fill-rows">
                  <div className="grid-item one-third">
                    <input
                      type="text"
                      placeholder="Stadt, Stadtviertel oder Adresse eingeben"
                      className="grid-item padding-left-m padding-right-m font-ellipsis one-whole"
                      autoComplete="off"
                      onFocus={(e) => onFocusMobile(e)}
                    />
                  </div>
                  <div className="grid-item grid-item-fixed-width">
                    <button
                      type="button"
                      className="button-primary"
                      onClick={() => onButtonOpen()}
                    >
                      Preise prüfen
                    </button>
                  </div>
                </div>
              </div>
            )}
          >
          </Mobile>
        </>
      )}
    </div>
  );
};

SavingHomepageAddressSearch.defaultProps = {
  isFeatureOn
};

const mapDispatchToProps = {
  cancelSearch: () => actions.cancelAddressSearch(),
  updateSuggestionQuery: (suggestionQuery: string) => actions.updateSuggestionQuery(suggestionQuery),
  suggestionsArrowDownEvent: () => actions.suggestionsArrowDownEvent(),
  suggestionsArrowUpEvent: () => actions.suggestionsArrowUpEvent(),
  selectRealEstateType: (value: string) => actions.selectRealEstateType(value),
  selectUserIntent: (value: string) => actions.selectUserIntent(value),
  onButtonOpen: () => actionCreators.openMobileNavigationMenu(),
  saveLastSearchInput: (inputValue: string, atlasUrlSelected: string) => actions.saveLastSearchInput(inputValue, atlasUrlSelected),
  saveLastUserIntentAndPropertyType: (userIntent: string, realEstateType: string) => actions.saveLastUserIntentAndPropertyType(userIntent, realEstateType),
};

const mapStateToProps = (state: any) => ({
    ...state.indexpage,
});

export default connect(mapStateToProps, mapDispatchToProps)(SavingHomepageAddressSearch);
