import React from 'react';
import PropTypes from 'prop-types';
import styles from './StarRating.less';

export const ratingFormat = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 0,
});

const StarRating = ({ ratingValue }) => {
  const percentageOfMaxRating = `${Math.round((ratingValue * 100) / 5)}%`;
  const starsTitle = ratingValue === 1 ? 'Stern' : 'Sterne';

  return (
    <div className={styles.starRating}>
      <span
        title={`${ratingFormat.format(ratingValue)} ${starsTitle}`}
        className={`${styles.stars} ${styles.starsEmpty}`}
      />
      <span
        title={`${ratingFormat.format(ratingValue)} ${starsTitle}`}
        className={`${styles.stars} ${styles.starsFilled}`}
        style={{ width: percentageOfMaxRating }}
      />
    </div>
  );
};

StarRating.propTypes = {
  ratingValue: PropTypes.number.isRequired,
};

export default StarRating;
