function addressExtractor(hierarchyLevel, geoHierarchy, address) {
  if (!geoHierarchy || geoHierarchy === {}) {
    return '';
  }

  const [country, region, city, district, street] = geoHierarchy;

  if (hierarchyLevel === 'region') {
    return `${region.name}, ${country.name}`;
  }

  if (hierarchyLevel === 'city') {
    return `${city.name}, ${region.name}`;
  }

  if (hierarchyLevel === 'district') {
    return `${district.name}, ${city.name}`;
  }

  if (hierarchyLevel === 'street') {
    return `${street.name}, ${city.name}`;
  }

  if (hierarchyLevel === 'address' && address) {
    return `${address.street} ${address.houseNumber}, ${address.city}`;
  }

  return '';
}

export default addressExtractor;
