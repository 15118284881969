import React from 'react';
import { StatusMessage } from 'is24-corecss-server';
import styles from "./OverlayMessage.less";
import TrackableLink from '../../TrackableLink/TrackableLink';
import defaultTracking from "../../../tracking/tracking";
import { HOMEOWNER_TOGGLE_OVERLAY_LINK } from '../../../tracking/trackingNaming';

const OverlayMessage = ({ onClose, tracking = defaultTracking, }) => (
  <div className={styles.overlayMessageContainer}>
    <StatusMessage
      headline={"Für Eigentümer:innen"}
      message={(
        <div>
          <div>
            Beobachten Sie kostenlos die Markt- & Preisentwicklung sowie die Nachfrage nach Ihrer Immobilie.
          </div>
          <div className="margin-top-s palm-margin-top-m">
            <TrackableLink onClick={() => tracking.report(HOMEOWNER_TOGGLE_OVERLAY_LINK)} href="https://www.immobilienscout24.de/meinkonto/meine-immobilien/">Immobilienpreis verfolgen</TrackableLink>
          </div>
        </div>
      )}
      statusType={"info"}
      onClose={onClose}
      statusStyle="toast"
    />
  </div>
);

export default OverlayMessage;
