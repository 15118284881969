import errorLogger from "./errorLogger"

export function getCSRFToken() {
  const token = document.querySelector('meta[name="XSRF-TOKEN"]')?.getAttribute('content')
  if (token == null) {
    errorLogger.log(new Error('Can not query content of XSRF-TOKEN from specified metatag'));
  }
  return token
}

