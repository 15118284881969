import React from 'react';
import styles from './TextSection.less';

const iconDeskLap1 =
  'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/3c26e264d6077d5697150240928134c6_pic1.png';
const iconDeskLap2 =
  'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/59599350db9ec7b27aa680c19be8439d_pic2.png';
const iconDeskLap3 =
  'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/516d7cdc0469aacef326b81327f86392_pic3.png';

const iconMobile1 =
  'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/54bf7e8ec990013d8a41d28dfbf2c669_mobile1.png';
const iconMobile2 =
  'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/7363e64d23c59150f76944e675f5b818_mobile2.png';
const iconMobile3 =
  'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/b0d991a43238579d0f2879a4253eec07_mobile3.png';

const blocks = [
  {
    icon: {
      mobile: iconMobile3,
      deskLap: iconDeskLap3,
    },
    headline: <>Informationen<br />für ganz Deutschland</>,
    text:
      'Analysieren Sie Deutschlands Immobilienpreise nach Bundesland, Landkreis, Stadt, Gemeinde oder ganz einfach nach Ihrer Adresse. ',
  },
  {
    icon: {
      mobile: iconMobile2,
      deskLap: iconDeskLap2,
    },
    headline: <>Alles zu Ihrer<br />Immobilie</>,
    text:
      'Finden Sie heraus, wie sich der Wert Ihrer Immobilie auch in Zukunft entwickeln wird. Wie hoch ist die Nachfrage in Ihrer Nachbarschaft? Mit welchem Angebot können Sie rechnen? ',
  },
  {
    icon: {
      mobile: iconMobile1,
      deskLap: iconDeskLap1,
    },
    headline: <>Datenbasis vom<br />Marktführer</>,
    text:
      'Der Preisatlas von ImmoScout24 stellt Ihnen die Preisdaten von über 43 Millionen Immobilien zur Verfügung, die regelmäßig aktualisiert werden, um den neuesten Stand der Immobilienpreis-Landschaft in Deutschland abzubilden. ',
  },
];

export const TextSection = () => (
  <div className={styles.textSection}>
    <h1 className={styles.h1}>Immobilienpreise Deutschland {new Date().getFullYear()}</h1>
    <div>
      Der Immobilienmarkt ist in ständiger Bewegung. Bundesweit sind die Immobilienpreise in den letzten Jahren stark
      gestiegen. Allerdings zeigen Statistiken, dass es regionale Unterschiede in der Entwicklung der Immobilienpreise
      gibt. Entdecken Sie im Preisatlas die aktuelle Miet- und Kaufpreisentwicklung und die Marktsituation an Ihrem
      Wohnort.
    </div>
    <h2 className={styles.h2}>Darum lohnt sich der Preisatlas von ImmoScout24</h2>
    <div className="grid grid-flex gutter-xxl grid-justify-center">
      {blocks.map((block, index) => (
        <div key={index} className={`grid-item desk-one-third lap-one-half ${styles.blockAlign}`}>
          <div className="grid grid-flex grid-fill-rows gutter-m grid-align-center">
            <div className="grid-item grid-item-fixed-width desk-one-whole lap-one-whole">
              <img src={block.icon.deskLap} className="palm-hide" alt="" />
              <img src={block.icon.mobile} className="desk-hide lap-hide" alt="" />
            </div>
            <div className="grid-item one-whole palm-one-half">
              <h3 className={styles.h3}>{block.headline}</h3>
            </div>
          </div>
          <div className="padding-top palm-padding-top-l">{block.text}</div>
        </div>
      ))}
    </div>
  </div>
);

export default TextSection;
