import { SET_SELECTED_POINT_IN_TIME, CLOSE_STATS_BOX } from './actions.js';

const initialState = {
  selected: 0,
  showStatsBox: true,
};

export function priceHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_POINT_IN_TIME:
      return Object.assign({}, state, { selected: action.value, showStatsBox: true });
    case CLOSE_STATS_BOX:
      return Object.assign({}, state, { showStatsBox: false });
    default:
      return state;
  }
}

export default priceHistoryReducer;
