import React from 'react';
import styles from './HomepageMain.less';
import TextSection from './TextSection/TextSection';
import EkomiWidget from './Ekomi/EkomiWidget';
import PriceTableWidget from './PriceTable/PriceTableWidget';
import PriceTrendChartWidget from "./PriceTrendChart/PriceTrendChartWidget";
import MyPropertyPromotion from "./MyPropertyPromotion/MyPropertyPromotion";

export const HomepageMain = () => {
  return (
    <div className={styles.searchMain}>
      <EkomiWidget />
      <TextSection />
      <PriceTrendChartWidget />
      <MyPropertyPromotion />
      <PriceTableWidget />
    </div>
  );
};

export default HomepageMain;
