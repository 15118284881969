import React from 'react';
import { isFunction } from 'lodash';
import { isRenderedOnServer } from '../../utils/envUtils';

const mockWindow = {
  setTimeout: () => 123,
  top: {
    location: {
      assign: () => {},
    },
  },
};

// What is the the main use-case of <TrackableLink />?
// It is for when you want to pass an 'onClick' handler that does something asynchronous and then you want to navigate
// to a given URL (provided by the 'href' prop). The component handles the awaiting for you.

const TrackableLink = (props) => {
  const { onClick = () => Promise.resolve(), href, children, target } = props;

  const trackAndNavigate = isRenderedOnServer()
    ? () => {}
    : (event) => {
      const localWindow =
          props.window || (typeof process === 'object' && process.env.BABEL_ENV === 'test' ? mockWindow : window);

      // window can be injected, purely for testing
      const open =
          target === '_blank' ? (url) => localWindow.open(url) : (url) => localWindow.top.location.assign(url);

      event.preventDefault();
      const result = onClick(event);
      const isPromise = result && isFunction(result.then);
      if (isPromise) {
        result.then(() => {
          // we setTimeout for an arbitrary length of time to wait for any tracking requests to complete
          localWindow.setTimeout(() => {
            open(href);
          }, 50);
        });
      } else {
        open(href);
      }
    };

  return <a {...{ ...props, onClick: trackAndNavigate }} onKeyDown={null}>{children}</a>;
};

export default TrackableLink;
