export const createTitle = (address = {}) => `${address.street} ${address.houseNumber}`;

export const createSubTitle = (address = {}) => `${address.zipcode} ${address.city} - ${address.district}`;

function titleExtractor(hierarchyLevel, geoHierarchy, address, duplicateDistricts = []) {
  if (!geoHierarchy || geoHierarchy === {}) {
    return {};
  }

  const [country, region, city, district, street] = geoHierarchy;

  if (hierarchyLevel === 'region') {
    return {
      fullTitle: region.name,
      title: region.name,
      subTitle: country.name,
    };
  }

  if (hierarchyLevel === 'city') {
    return {
      fullTitle: city.name,
      title: city.name,
      subTitle: region.name,
    };
  }

  if (hierarchyLevel === 'district') {
    const fullTitle =
      duplicateDistricts && duplicateDistricts.includes(district.name)
        ? `${city.name} ${district.name}`
        : district.name;
    return {
      fullTitle,
      title: district.name,
      subTitle: city.name,
    };
  }

  if (hierarchyLevel === 'street') {
    return {
      fullTitle: street.name,
      title: street.name,
      subTitle: `${street.zipcode || ''} ${city.name} - ${district.name}`,
    };
  }

  if (hierarchyLevel === 'address' && address) {
    return {
      fullTitle: createTitle(address),
      title: createTitle(address),
      subTitle: createSubTitle(address),
    };
  }

  return {};
}

export default titleExtractor;
