import React from 'react';
import PropTypes from 'prop-types';
import styles from './HomeOwnerWorldNewTeaser.less';
import tracking from '../../tracking/tracking.js';
import { CLAIM_PROPERTY, PROPERTY_BOOK_V2 } from '../../tracking/trackingNaming.js';
import HomeOwnerWorldIcon from './Table/HomeOwnerWorldIcon';
import HomeOwnerWorldDescription from './Table/HomeOwnerWorldDescription';

const analytics = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/fc5f3222beac34d395a45688e8cd514d_icon-analytics.svg';
const createRealestate = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/6b6d3a496473b3db948e5bcadfa5a2f9_icon-create-realestate.svg';
const sellRealestate = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/62e747f61c7c9e2ab3ae8b6b254762eb_icon-start-sell.svg';

const MyPropertyTeaser = ({ onButtonClicked, title, description, isHomePage, trackingAction }) => {
  const handleButtonClick = () => {
    tracking.overwriteTrackingEventContext('overview');
    tracking.report(CLAIM_PROPERTY, trackingAction);
    tracking.revertTrackingEventContext();
    onButtonClicked();
  };

  const renderHomePage = () => {
    return (
      <div data-identifier="my-property-widget" className={`grid ${styles.myPropertyContainer}`}>
        <div className={`grid-item one-whole ${styles.titleContainer}`}>
          <h2 className={styles.headline}>{title}</h2>
          <p className={styles.descriptionHomepage} >{description}</p>
        </div>
        <div className={`grid-item one-whole `}>
          <div data-qa="nmpt-headline" className={`padding-vertical-m ${styles.myPropertyFunctionalityHeader}`}>
            So einfach funktioniert&apos;s
          </div>
          <div className={`grid one-whole`}>
            <div className={`grid-item palm-one-whole lap-one-half desk-one-third`}>
              <div className={'grid grid-flex grid-fill-rows'}>
                <HomeOwnerWorldIcon url={createRealestate} alt="create realestate" isHomePage />
                <HomeOwnerWorldDescription
                  header="1. Sie legen Ihre Immobilie an"
                  text="Bereits wenige Angaben zu Ihrer Immobilie genügen."
                />
              </div>
            </div>
            <div className={`grid-item palm-one-whole lap-one-half desk-one-third`}>
              <div className={'grid grid-flex grid-fill-rows'}>
                <HomeOwnerWorldIcon url={analytics} alt="analytics" isHomePage />
                <HomeOwnerWorldDescription
                  header="2. Behalten Sie den Überblick"
                  text='In "Meine Immobilien" können Sie alle Immobiliendaten einsehen.'
                />
              </div>
            </div>
            <div className={`grid-item palm-one-whole lap-one-half desk-one-third ${styles.lastStep}`}>
              <div className={'grid grid-flex grid-fill-rows'}>
                <HomeOwnerWorldIcon url={sellRealestate} alt="sell realestate" isHomePage />
                <HomeOwnerWorldDescription
                  header="3. In den Verkauf starten"
                  text="Wählen Sie die passende Verkaufsstrategie für Ihre Immobilie."
                />
              </div>
            </div>
          </div>

          <div className={styles.myPropertyButton}>
            <button
              id="my-property-widget-button"
              type="button"
              className="button-secondary margin-top-l"
              onClick={handleButtonClick}
            >
              Zu &quot;Meine Immobilien&quot;
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderSubPage = () => {
    return (
      <div data-identifier="my-property-widget" className={`grid ${styles.myPropertyContainer}`}>
        <div className={`grid-item one-whole ${styles.titleContainer}`}>
          <h2 className={styles.headline}>{title}</h2>
          <p>{description}</p>
        </div>
        <div className={`grid-item one-whole `}>
          <div data-qa="nmpt-headline" className={`padding-vertical-m ${styles.myPropertyFunctionalityHeader}`}>
            So einfach funktioniert&apos;s
          </div>
          <div className={'grid grid-flex grid-fill-rows'}>
            <HomeOwnerWorldIcon url={createRealestate} alt="create realestate" />
            <HomeOwnerWorldDescription
              header="1. Sie legen Ihre Immobilie an"
              text="Bereits wenige Angaben zu Ihrer Immobilie genügen."
            />
          </div>
          <div className={'grid grid-flex grid-fill-rows'}>
            <HomeOwnerWorldIcon url={analytics} alt="analytics" />
            <HomeOwnerWorldDescription
              header="2. Behalten Sie den Überblick"
              text='In "Meine Immobilien" können Sie alle Immobiliendaten einsehen.'
            />
          </div>
          <div className={'grid grid-flex grid-fill-rows'}>
            <HomeOwnerWorldIcon url={sellRealestate} alt="sell realestate" />
            <HomeOwnerWorldDescription
              header="3. In den Verkauf starten"
              text="Wählen Sie die passende Verkaufsstrategie für Ihre Immobilie."
            />
          </div>

          <div className={`${styles.myPropertyButton}`}>
            <button
              id="my-property-widget-button"
              type="button"
              className="button-secondary margin-top-l"
              onClick={handleButtonClick}
            >
              Zu &quot;Meine Immobilien&quot;
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (isHomePage) {
    return renderHomePage();
  }
  return renderSubPage();


}

MyPropertyTeaser.propTypes = {
  onButtonClicked: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  isHomePage: PropTypes.bool,
  trackingAction: PropTypes.string,
};

MyPropertyTeaser.defaultProps = {
  title: "Behalten Sie Ihre Immobilie immer im Blick",
  description: 'In "Meine Immobilien" können Sie Ihre Immobilie anlegen und so die aktuelle Marktentwicklung in Ihrer Region beobachten sowie Preisupdates verfolgen.',
  isHomePage: false,
  trackingAction: PROPERTY_BOOK_V2,
};

export default MyPropertyTeaser;
