import React from 'react';
import PropTypes from 'prop-types';
import tracking from '../../tracking/tracking.js';

export const Button = ({
  text,
  onClickHandler,
  additionalClass,
  reportingLabels,
  sizeClass,
  buttonClass,
  children,
  id,
  disabled,
}) => (
  <button
    type="button"
    className={`${buttonClass} ${sizeClass} ${additionalClass}`}
    id={id}
    disabled={disabled}
    onClick={(e) => {
      if (reportingLabels) {
        reportingLabels.forEach((label) => tracking.report(label));
      }
      onClickHandler(e);
    }}
  >
    <span>{text}</span>
    {children}
  </button>
);

export default Button;

Button.defaultProps = {
  additionalClass: '',
  reportingLabels: undefined,
  sizeClass: 'palm-one-whole',
  buttonClass: 'button-secondary',
  id: '',
  disabled: false,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  sizeClass: PropTypes.string,
  reportingLabels: PropTypes.arrayOf(PropTypes.string),
  buttonClass: PropTypes.string,
  disabled: PropTypes.bool,
};
