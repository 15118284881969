import React from 'react';
import PropTypes from 'prop-types';
import styles from './EkomiComponent.less';
import StarRating, { ratingFormat } from '../StarRating/StarRating';

export const BRONZE_URL = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/d18585744ad74364e2ec0a7e093e7ba8_bronze.png';
export const GOLD_URL = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/4e09d5d8a7975b316faffd8b0a7add59_gold.png';
export const NO_SEAL_URL = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/6fe3d518acdda689ed7342772fe062ed_keinsiegel.png';
export const SILVER_URL = 'https://www.static-immobilienscout24.de/statpic/shared-services/it/atlas/icons/227f29408405f2f262d78db3b37dd794_silber.png';

export const NO_SEAL = 'keinsiegel';
export const GOLD = 'gold';
export const SILVER = 'silber';
export const BRONZE = 'bronze';

export const VALID_SEALS = [NO_SEAL, GOLD, SILVER, BRONZE];

const iconMap = {
  [BRONZE]: BRONZE_URL,
  [GOLD]: GOLD_URL,
  [NO_SEAL]: NO_SEAL_URL,
  [SILVER]: SILVER_URL
};

const countFormat = new Intl.NumberFormat('de-DE', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const EkomiComponent = ({ count, link, rating, seal }) => (
  <div className={styles.ekomiComponent}>
    <div className={styles.main}>
      <div className="grid grid-flex grid-fill-rows gutter-m">
        <div className="grid-item grid-item-fixed-width">
          <a href={link} target="_blank" rel="noreferrer">
            <img alt="" src={iconMap[seal] || iconMap[NO_SEAL]} className={styles.icon} />
          </a>
        </div>
        <div className="grid-item one-half font-lightgray font-xs font-line-s">
          <div>
            <StarRating ratingValue={rating} />
            <span className={styles.ratingLabel}>{ratingFormat.format(rating)} / 5</span>
          </div>
          <div className="padding-top-s">
            über {countFormat.format(count)} Bewertungen auf{' '}
            <a href={link} className="font-lightgray" target="_blank" rel="noreferrer">
              eKomi
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

EkomiComponent.propTypes = {
  count: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  seal: PropTypes.string.isRequired
};

export default EkomiComponent;
