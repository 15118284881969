import * as d3Axis from 'd3-axis';

export default {
  top: d3Axis.axisTop,
  bottom: d3Axis.axisBottom,
  right: d3Axis.axisRight,
  left: d3Axis.axisLeft,
};

export const translation = (svgDimensions, margins) => {
  const { height, width } = svgDimensions;

  return {
    top: `translate(0, ${margins.top})`,
    bottom: `translate(0, ${height - margins.bottom})`,
    left: `translate(${margins.left}, 0)`,
    right: `translate(${width - margins.right}, 0)`,
  };
};
