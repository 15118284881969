import React from 'react';
import PropTypes from "prop-types";
import styles from './ContainerSearchMobile.less'
import { isFeatureOn } from '../../../../utils/featureSwitchReader';
import SavingNavigationSidebarMobileNewUserIntent from '../SavingNavigationSidebarMobileNewUserIntent';
import useWidthResize from "../../../../hooks/useWidthResize";

const ContainerSearchMobile = ({ isHomePage, isFeatureOn }) => {
  useWidthResize();
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} grid-align-center`}>
        <SavingNavigationSidebarMobileNewUserIntent isHomePage={isHomePage} />
      </div>
    </div>
  );
}

ContainerSearchMobile.defaultProps = {
  isHomePage: false,
  isFeatureOn
}

ContainerSearchMobile.propTypes = {
  isHomePage: PropTypes.bool,
};
export default ContainerSearchMobile
