import {
  INDEX_SEARCH_SELECT_ADDRESS,
  INDEX_SEARCH_SELECT_CITY,
  INDEX_SEARCH_SELECT_DISTRICT,
  INDEX_SEARCH_SELECT_REGION,
  SEARCH_SELECT_ADDRESS,
  SEARCH_SELECT_CITY,
  SEARCH_SELECT_DISTRICT,
  SEARCH_SELECT_REGION,
  SELECT_APARTMENT,
  SELECT_BUY,
  SELECT_HOUSE,
  SELECT_LET,
  SELECT_RENT,
  SELECT_SELL
} from "../tracking/trackingNaming";

export function getSuggestionResultLength(suggestionResults) {
  return Object.values(suggestionResults).reduce((accumulator, currentValue) => accumulator + currentValue.length, 0);
}

export function generateLink(suggestionResult, suggestionQuery) {
  return `${suggestionResult.url}?searchQuery=${encodeURIComponent(suggestionQuery)}`;
}

export const homePageReportLabels = {
  addresses: INDEX_SEARCH_SELECT_ADDRESS,
  districts: INDEX_SEARCH_SELECT_DISTRICT,
  cities: INDEX_SEARCH_SELECT_CITY,
  regions: INDEX_SEARCH_SELECT_REGION,
};

export const searchReportLabels = {
  addresses: SEARCH_SELECT_ADDRESS,
  districts: SEARCH_SELECT_DISTRICT,
  cities: SEARCH_SELECT_CITY,
  regions: SEARCH_SELECT_REGION,
};

export const userIntentReportLabels = {
  BUY: SELECT_BUY,
  SELL: SELECT_SELL,
  LET: SELECT_LET,
  RENT: SELECT_RENT,
};

export const propertyTypeReportLabels = {
  HOUSE: SELECT_HOUSE,
  APARTMENT: SELECT_APARTMENT,
};

export const getNameFromSuggestion = (selectedSuggestion) => {
  if (selectedSuggestion.address) {
    const houseNumberExtension = selectedSuggestion.address.houseNumberExtension !== ''
      ? ` ${selectedSuggestion.address.houseNumberExtension}` :
      '';

    return `${selectedSuggestion.address.street} ${selectedSuggestion.address.houseNumber}${houseNumberExtension}, ${selectedSuggestion.address.zipcode} ${selectedSuggestion.address.city}`
  }
  return selectedSuggestion.name
};
