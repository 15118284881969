import React from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';
import styles from '../styles.less';
import { PRIMARY_BLUE } from '../../../utils/colorPalette';

export const Line = ({ scales, data, color, strokeDashArray }) => {
  const { xScale: x, yScale: y } = scales;

  const path = line()
    .x((d) => x(d.x))
    .y((d) => y(d.y));

  return (
    <g className={styles.line}>
      <path d={path(data)} fill="none" stroke={color || PRIMARY_BLUE()} strokeDasharray={strokeDashArray} />
    </g>
  );
};

Line.propTypes = {
  scales: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  color: PropTypes.string,
  strokeDashArray: PropTypes.string,
};

Line.defaultProps = {
  strokeDashArray: '',
};

export default Line;
