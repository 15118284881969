const BUY = 'BUY';
const SELL = 'SELL';
const LET = 'LET';
const RENT = 'RENT';

export const DEFAULT_USER_INTENT = SELL;

export default {
  RENT,
  BUY,
  SELL,
  LET,
};

export function toCommercializationType(intent) {
  switch (intent) {
    case RENT:
    case LET:
      return RENT;
    case BUY:
    case SELL:
      return BUY;
    default:
      return BUY;
  }
}
