import { ToggleSwitch } from "is24-corecss-server";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from './HomeOwnerToggle.less'
import defaultTracking from "../../../tracking/tracking";
import reportingUtils from './reportingUtils';
import {
  HOMEOWNER_ACTIVATED,
  HOMEOWNER_DEACTIVATED,
  PROPERTY_BOOK_V2,
  PROPERTY_BOOK
} from "../../../tracking/trackingNaming";
import actions from "../actions";
import {
  getIsHomeOwner
} from "../../../selectors/selectors";
import localStorage from '../../../utils/localStorage';
import OverlayMessage from "./OverlayMessage";
import { HAS_SEEN_HOMEOWNER_TOGGLE_OVERLAY } from "../../../utils/globals";
import { isFeatureOn } from "../../../utils/featureSwitchReader";

const HomeOwnerToggle = ({
  label = 'Ich bin Eigentümer:in einer Immobilie',
  isHomePage = false,
  tracking = defaultTracking,
  backendTracking = reportingUtils,
  additionalClasses = '',
  toggleHomeOwner,
  isHomeOwner,
  groupName = '',
  reportingSuffix = '',
  isFeatureActivated = isFeatureOn
  }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <div
        className={`grid-item desk-one-whole lap-one-whole palm-one-half ${additionalClasses} ${isHomePage ? styles.wrapperToggleHomepage : styles.wrapperToggle}`}>
        <ToggleSwitch
          onClick={(event) => {
            const checked = event.target.checked;
            if (checked && !localStorage.getItem(HAS_SEEN_HOMEOWNER_TOGGLE_OVERLAY)) {
              setShowOverlay(true);
              localStorage.setItem(HAS_SEEN_HOMEOWNER_TOGGLE_OVERLAY, true);
            }
            tracking.report(checked ? (HOMEOWNER_ACTIVATED + reportingSuffix) : (HOMEOWNER_DEACTIVATED + reportingSuffix), isHomePage ? PROPERTY_BOOK : PROPERTY_BOOK_V2);
            backendTracking.reportHomeOwnerToggle(checked);
            toggleHomeOwner()
          }}
          checked={isHomeOwner}
          id={`toggle-homeowner${groupName}`}
          label={`${label}:`}
          className={styles.toggleHomeOwner}
          data-qa="homeowner-toggle"
        />
      </div>
      {
        showOverlay && <OverlayMessage
          data-qa="delete-success-message"
          onClose={() => setShowOverlay(false) }
        />
      }
    </>
  );
}

HomeOwnerToggle.propTypes = {
  label: PropTypes.string,
  isHomePage: PropTypes.bool,
  tracking: PropTypes.object,
  backendTracking: PropTypes.object,
  additionalClasses: PropTypes.string,
  toggleHomeOwner: PropTypes.func.isRequired,
  isHomeOwner: PropTypes.bool,
  groupName: PropTypes.string,
  reportingSuffix: PropTypes.string,
  isFeatureActivated: PropTypes.func,
};

const mapDispatchToProps = {
  toggleHomeOwner: () => actions.toggleHomeOwner(),
};

const mapStateToProps = (state) => ({
  isHomeOwner: getIsHomeOwner(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeOwnerToggle);

