import { clearUndefinedOrNullFields } from "../../utils/objectUtils";

export const MY_PROPERTY_RELOCATION_URL = '/orte/deutschland/berlin/berlin/#/meine-immobilie-relocation';
export const ATLAS_HOMEPAGE_PROMO_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051853&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_homepage_promo';
export const ATLAS_HOMEPAGE_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051852&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_homepage_graph';
export const PRICE_OVERVIEW_PROMO_OLD_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051854&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_price_overview_promo1';
export const PRICE_OVERVIEW_PROMO_NEW_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051856&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_price_overview_promo2';
export const PRICE_OVERVIEW_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051855&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_price_overview_graph';
export const PRICE_DEVELOPMENT_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051858&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_price_development_graph';
export const ATLAS_MAP_TO_HOMEOWNER_LANDINGPAGE_URL = 'https://www.immobilienscout24.de/meinkonto/meine-immobilien/?cmp_id=10-051857&cmp_name=myscout_homeowner_landingpage&cmp_position=residential_atlas&cmp_creative=atlas_map_open_for_offers';

const getRleURL = ({ zipCode, propertyType }) => {
  const urlSearchParams = new URLSearchParams(clearUndefinedOrNullFields({
    property: propertyType,
    ZIP: zipCode,
    cmp_id: '10-04775',
    cmp_name: 'residential_realtorleadengine',
    cmp_position: 'atlas_myproperty',
    cmp_creative: 'button_rle'
  }));
  return `https://www.immobilienscout24.de/maklervergleich/?${urlSearchParams}`;
};

const getHofURL = ({ city, street, houseNumber, zipCode, propertyType }) => {
  const urlSearchParams = new URLSearchParams({
    seg: 'eigentuemer/verkaufe',
    cmp_id: '10-04774',
    cmp_name: 'ppa_insertion',
    cmp_position: 'atlas_myproperty',
    cmp_creative: 'button_ppa'
  });
  if (zipCode) urlSearchParams.set('postcode', zipCode);
  if (city) urlSearchParams.set('city', city);
  if (street) urlSearchParams.set('street', street);
  if (houseNumber) urlSearchParams.set('number', houseNumber);
  return `https://www.immobilienscout24.de/anbieten/private-anbieter/inserieren/api/segments/resolve/propertyOwner/sell/${propertyType.toLowerCase()}?${urlSearchParams}`;
};

export { getRleURL, getHofURL };
