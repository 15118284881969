import { scaleLinear } from 'd3-scale';

const ranges = (svgDimensions, margins) => {
  const { height } = svgDimensions;

  return {
    left: [height - margins.bottom, margins.top],
    right: [height - margins.bottom, margins.top],
    top: [margins.left, svgDimensions.width - margins.right],
    bottom: [margins.left, svgDimensions.width - margins.right],
  };
};

export function createScale({ domain, values, ticksNumber, orient, svgDimensions, margins }) {
  const range = ranges(svgDimensions, margins)[orient];

  const scale = scaleLinear().domain(domain).range(range);

  if (values && values.length > 0) {
    return scale;
  }
  return scale.nice(ticksNumber || 4);
}

export default {
  createScale,
  ranges,
};
