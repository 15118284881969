import React from 'react';
import PropTypes from 'prop-types';
import styles from './NewWidgetTitle.less';

export const ALIGN = {
  left: 'align-left',
  center: 'align-center',
};

const WidgetTitle = ({ id, subText, maxSubTextWidth, text, useH3, useDiv, children, align }) => {
  const customWidth = maxSubTextWidth ? { maxWidth: maxSubTextWidth } : {};
  const HeadlineTag = useH3 ? 'h3' : useDiv ? 'div' : 'h2';
  return (
    <div className="grid">
      <div className={`grid-item padding-bottom-m ${align}`}>
        <HeadlineTag id={`${id}-headline`} className={styles.titleText}>
          {text || children}
        </HeadlineTag>
      </div>
      {subText && (
        <div id={`${id}-subheadline`} className={`grid-item ${align}`}>
          <span className={styles.subTitleText} style={customWidth}>
            {subText}
          </span>
        </div>
      )}
    </div>
  );
};

WidgetTitle.defaultProps = {
  text: '',
  subText: '',
  maxSubTextWidth: '',
  useH3: false,
  useDiv: false,
  children: null,
};

WidgetTitle.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  subText: PropTypes.string,
  maxSubTextWidth: PropTypes.string,
  useH3: PropTypes.bool,
  useDiv: PropTypes.bool,
};

export default WidgetTitle;
