import React, { Component } from 'react';
import { select as d3Select } from 'd3-selection';
import PropTypes from 'prop-types';
import styles from '../../styles.less';
import axes from '../../axisConfig.js';

export default class ChartGridLines extends Component {
  componentDidMount() {
    this.renderGridLines();
  }

  componentDidUpdate() {
    this.renderGridLines();
  }

  addLineIfNotExist(gridLines, selectedGridLine) {
    if (selectedGridLine) {
      const array = [selectedGridLine, ...gridLines];
      return [...new Set(array.map((a) => a))];
    }
    return gridLines;
  }

  renderGridLines() {
    const { orient, scale, length, ticks, ticksNumber, selected, currentPointInTime } = this.props;

    const gridLines = axes[orient]().scale(scale).tickSize(-length).tickFormat('');

    const tickValues = this.addLineIfNotExist(ticks, selected);

    d3Select(this.element).call(
      ticks && ticks.length > 0 ? gridLines.tickValues(tickValues) : gridLines.ticks(ticksNumber || 4),
    );

    if (selected) {
      d3Select(this.element)
        .selectAll('g')
        .filter((d) => d === selected)
        .classed(styles.selected, true);
      d3Select(this.element)
        .selectAll('g')
        .filter((d) => d !== selected)
        .classed(styles.selected, false);
      // logic for showing the current point as teal line in grid, only when not selected
      d3Select(this.element)
        .selectAll('g')
        .filter((d) => d === currentPointInTime)
        .classed(styles.currentPointInTime, true);
      d3Select(this.element)
        .selectAll('g')
        .filter((d) => d === selected)
        .classed(styles.currentPointInTime, false);
    } else {
      d3Select(this.element).selectAll('g').classed(styles.selected, false);
      d3Select(this.element)
        .selectAll('g')
        .filter((d) => d === currentPointInTime)
        .classed(styles.currentPointInTime, true);
    }
  }

  render() {
    return (
      <g
        className={styles.grid}
        ref={(el) => {
          this.element = el;
        }}
        transform={this.props.translate}
      />
    );
  }
}

ChartGridLines.propTypes = {
  orient: PropTypes.string.isRequired,
  scale: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  ticks: PropTypes.array.isRequired,
  translate: PropTypes.string.isRequired,
};

ChartGridLines.defaultProps = {};
