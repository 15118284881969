import types from '../../../realEstateTypes.js';

const TYPE_CONFIG = {
  [types.HOUSE_BUY]: {
    title: () => 'Kaufpreisentwicklung für Häuser',
    subTitle: () => 'Der durchschnittliche Immobilienpreis für Häuser zum Kauf',
    pronoun: () => 'Er',
  },
  [types.HOUSE_RENT]: {
    title: () => 'Mietpreisentwicklung für Häuser',
    subTitle: () => 'Die durchschnittliche Nettokaltmiete für Häuser',
    pronoun: () => 'Sie',
  },
  [types.APARTMENT_BUY]: {
    title: () => 'Kaufpreisentwicklung für Wohnungen',
    subTitle: () => 'Der durchschnittliche Immobilienpreis für Wohnungen zum Kauf',
    pronoun: () => 'Er',
  },
  [types.APARTMENT_RENT]: {
    title: () => 'Mietpreisentwicklung für Wohnungen',
    subTitle: () => 'Die durchschnittliche Nettokaltmiete für Wohnungen',
    pronoun: () => 'Sie',
  },
};

function getFor(realEstateType) {
  return TYPE_CONFIG[realEstateType] || TYPE_CONFIG[types.HOUSE_BUY];
}

export const svgDimensions = { width: 500, height: 700 };
export const svgDimensionsFpt = { width: 400, height: 700 };
export const svgMargins = {
  top: 35,
  right: 50,
  bottom: 50,
  left: 75,
};

export default {
  getFor,
};
