export default class TabConfig {
  constructor({ name, icon, id, trackingLabel, displayName, dataIdentifier, isActive, tooltip }) {
    this.id = id;
    this.name = name;
    this.displayName = displayName || name;
    this.icon = icon;
    this.trackingLabel = trackingLabel;
    this.dataIdentifier = dataIdentifier || '';
    this.isActive = isActive || ((pathname) => pathname === `/${this.name}`);
    this.tooltip = tooltip || '';
  }
}
