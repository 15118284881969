import React from 'react';
import { useSelector } from "react-redux";
import styles from './HomeOwnerWorldPromoChartOverlay.less';
import tracking from '../../tracking/tracking';
import { FUTURE_PRICE_TREND_LINK_MY_PROPERTY } from '../../tracking/trackingNaming';
import { getHierarchyLevel } from "../../selectors/selectors";
import {
  ATLAS_HOMEPAGE_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL,
  PRICE_DEVELOPMENT_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL,
  PRICE_OVERVIEW_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL,
} from '../MyPropertyRelocation/urls';

const HomeOwnerWorldPromoChartOverlay = ({ trackingAction }) => {
  const hierarchyLevel = useSelector(getHierarchyLevel);

  const redirectToHomeOwnerLandingPage = (event, hierarchyLevel) => {
    tracking.report(FUTURE_PRICE_TREND_LINK_MY_PROPERTY, trackingAction);
    event.preventDefault();

    if (window.location.hash === '#/preisentwicklung') {
      window.location = PRICE_DEVELOPMENT_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL;
    }
    else if (window.location.pathname === '/'){
      window.location = ATLAS_HOMEPAGE_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL;
    }
    else {
      window.location = PRICE_OVERVIEW_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL;
    }
  };

  return (
    <button
      id="my-property-promo-chart-overlay-button"
      type="button"
      onClick={(event) => redirectToHomeOwnerLandingPage(event, hierarchyLevel)}
      className={styles.chartOverlayBox}
    >
      <div data-qa="HomeOwnerWorldPromoChartOverlay-icon2" className={styles.chartOverlayIcon}>
        <span className="is24-icon-statistics" />
      </div>
      <div className={styles.chartOverlayText}>Für kostenfreie Preisprognose anmelden</div>
    </button>
  );
};

export default HomeOwnerWorldPromoChartOverlay;
