import ReactLazyLoad from 'react-lazyload';
import React from 'react';

const LazyLoad = (props) => {
  const { children } = props;
  // react-lazyload slows down unit tests considerably. For this reason we skip rendering it in the test environment.
  if (typeof process === 'object' && process.env.BABEL_ENV === 'test') {
    return children;
  }
  return <ReactLazyLoad {...props} />;
};

export default LazyLoad;
