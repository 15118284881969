import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.less';

const Rectangle = ({
  firstPointInTime,
  currentPointInTime,
  latestPointInTime,
  showMpPromoDialog,
  wholeHeight,
  wholeWidth,
  futureTrend,
  isMobile,
}) => {
  const totalSections = latestPointInTime - firstPointInTime;
  // calculating x position based on current point
  const x = (wholeWidth * (currentPointInTime - firstPointInTime)) / totalSections;
  const actualX = !showMpPromoDialog && !futureTrend ? x : isMobile ? x - 7 : x - 22;
  // calculating width percentage based on number of sections (4 quarters for D & T/ 1 year for M) of future trend data
  const width = (wholeWidth * (4 * 0.25)) / totalSections;
  const actualWidth = isMobile ? width + 19 : width;
  // calculated based on trial and error
  const y = isMobile ? 41 : 36;
  const height = showMpPromoDialog ? wholeHeight - 86 : wholeHeight - 84;
  const actualHeight = isMobile ? height + 20 : height;

  return (
    <rect
      className={showMpPromoDialog ? styles.futureTrendBackground : styles.myPropertyFutureTrendBackground}
      x={actualX}
      y={y}
      width={showMpPromoDialog ? actualWidth + 1 : actualWidth - 26}
      height={actualHeight}
    />
  );
};

Rectangle.propTypes = {
  firstPointInTime: PropTypes.number.isRequired,
  currentPointInTime: PropTypes.number.isRequired,
  latestPointInTime: PropTypes.number.isRequired,
  wholeHeight: PropTypes.number.isRequired,
  wholeWidth: PropTypes.number.isRequired,
  futureTrend: PropTypes.bool.isRequired,
  showMpPromoDialog: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Rectangle;
