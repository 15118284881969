/* eslint-disable no-console */

function log(error, description = '') {
  if (typeof window !== 'undefined' && window.IS24 && window.IS24.errorHandler) {
    window.IS24.errorHandler.exceptionLog(error, description);
  }

  typeof console !== 'undefined' && console.error && console.error(error, description);
}

export default {
  log,
};
