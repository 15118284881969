import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, memoize } from 'lodash';
import { NO_PRICE_RANGE, PALM_MAX } from '../utils/globals.js';
import { calculateDeltaInPercent, formatDE } from '../utils/NumbersHelper.js';
import { getActiveView } from '../components/Navigation/Menu/menuConfig';
import geoHierarchyLevels from '../geoHierarchyLevels.js';
import titleExtractor from '../utils/titleExtractor';
import realEstateTypes from '../realEstateTypes';
import userIntents from '../userIntents';
import { replaceDoubleName } from '../utils/districtNameUtils';

const sortSubHierarchy = memoize(
  ({ entries }) => entries.sort((first, second) => Intl.Collator().compare(first.name, second.name)),
  ({ geoHierarchy, entries }) => {
    if (entries.length === 0) {
      return 'empty';
    }
    return geoHierarchy[geoHierarchy.length - 1].link;
  },
);

export const getHierarchyLevel = (state) => state.hierarchyLevel;
export const getGeoType = (state) => state.geoType;
export const getRealEstateType = (state) => state.realEstateType;
export const getUserIntent = (state) => state.userIntent;
export const getAddress = (state) => {
  const address = state.address.data || {};
  // return address;
  return Object.assign(address, { district: replaceDoubleName(address.district) });
};
export const getAddressStatus = (state) => state.address.status;
export const getPriceHistory = (state) => state.priceHistory.data;
export const getPriceHistoryStatus = (state) => state.priceHistory.status;
export const getGeoHierarchy = (state) => {
  // return state.geoHierarchy.data;
  if (!state.geoHierarchy.data) {
    return state.geoHierarchy.data;
  }
  return state.geoHierarchy.data.map((element) => {
    if (element && element.level === 'district') {
      return Object.assign(element, { name: replaceDoubleName(element.name) });
    }
    return element;
  });
};
export const getGeoHierarchyStatus = (state) => state.geoHierarchy.status;
export const getStaticMaps = (state) => state.staticMaps.data;
export const getStaticMapsStatus = (state) => state.staticMaps.status;
export const getDemographicInfo = (state) => state.demographicInfo.data;
export const getDemographicInfoStatus = (state) => state.demographicInfo.status;
export const getAddressSearch = (state) => state.addressSearch;
export const getGoogleAddressSearch = (state) => state.googleAddressSearch;
export const getStreet = (state) => state.street;
export const getScrollY = (state) => state.scroll.scroll_y;
export const isBurgerMenuOpened = (state) => state.navigation.burgerMenuOpened;
export const isSidebarExpanded = (state) => state.navigation.sidebarExpanded;
export const getIsSidebarDesktopExpanded = (state) => state.navigation.sidebarDesktopExpanded;
export const getIsHomeOwner = (state) => state.navigation.homeOwner;
export const getWindowWidth = (state) => state.window.width;
export const getPriceCheckSettings = (state) => state.priceCheckSettings || {};
export const getSelectedPointInTime = (state) => state.priceHistorySettings.selected || 0;
export const getShowStatsBox = (state) => state.priceHistorySettings.showStatsBox;
export const getStreetWithNumber = createSelector([getAddress], (address) =>
  address ? `${address.street} ${address.houseNumber}` : '',
);
export const getZipCode = createSelector([getAddress], (address) => address.zipcode);
export const getDistrictName = createSelector([getAddress], (address) => address.district);
export const getHash = (state) => state.pathname;
export const getPageLink = (state) => {
  try {
    return `${document.location.protocol}//${document.location.host}${document.location.pathname}${document.location.search}`;
    // eslint-disable-next-line no-empty
  } catch {}
  return state.pageLink;
};
export const getRleWidgetUrl = (state) => state.config.rleWidgetUrl || '';
export const getShapeServicesUrl = (state) => state.config.shapeServiceUrl;
export const getWebMetricsScriptUrl = (state) => state.config.webMetricsScriptUrl;
export const getTopLevelDomain = (state) => state.config.topLevelDomain;
export const getSubHierarchyLinks = (state) => {
  const result = sortSubHierarchy({
    geoHierarchy: state.geoHierarchy.data || null,
    entries: state.subHierarchyInfo.data || [],
  });
  return result;
};
export const getSubHierarchyLinksStatus = (state) => state.subHierarchyInfo.status;
export const getPriceCheckRealEstateType = (state) => state.priceCheckSettings.realEstateType || state.realEstateType;
export const getConfig = (state) => state.config;
export const isMobileNavigationMenuOpen = (state) => state.navigation.isMobileNavigationMenuOpen || false;
export const getDuplicateDistricts = (state) => state.duplicateDistricts;
export const getPriceTableData = (state) => !isEmpty(state.priceTableData.data) ? state.priceTableData.data : null;
export const getSiblingsHierarchyInfo = (state) => state.siblingsHierarchyInfo.data || null;
export const getBrowserLocation = (state) => state.location || {};
export const getLocationQuery = (state) => getBrowserLocation(state).query || {};
export const getLocationOrigin = (state) => getBrowserLocation(state).origin || '';

export const getLocation = createSelector(
  [getHierarchyLevel, getGeoHierarchy, getAddress],
  (hierarchyLevel, geoHierarchy, address) => {
    const [, region, city, district, street] = geoHierarchy;

    if (hierarchyLevel === 'region') {
      return region.position;
    }

    if (hierarchyLevel === 'city') {
      return city.position;
    }

    if (hierarchyLevel === 'district') {
      return district.position;
    }

    if (hierarchyLevel === 'address') {
      return address.position;
    }

    if (hierarchyLevel === 'street') {
      return street.position;
    }

    return null;
  },
);

export const isUserIntentAndHierarchyForNewDashboard = createSelector(
  [getUserIntent, getHierarchyLevel],
  (userIntent, hierarchyLevel) =>
    (hierarchyLevel === geoHierarchyLevels.REGION ||
      hierarchyLevel === geoHierarchyLevels.CITY ||
      hierarchyLevel === geoHierarchyLevels.DISTRICT) &&
    (userIntent === userIntents.SELL || userIntent === userIntents.BUY),
);
const getPrices = createSelector(
  [getRealEstateType, getPriceHistory],
  (realEstateType, priceHistory) => priceHistory[realEstateType],
);

export const getLocationName = createSelector(
  [getHierarchyLevel, getGeoHierarchy, getStreetWithNumber],
  (hierarchyLevel, geoHierarchy, address) => {
    const [, region, city, district, street] = geoHierarchy;

    if (hierarchyLevel === 'region') {
      return region.name;
    }

    if (hierarchyLevel === 'city') {
      return city.name;
    }

    if (hierarchyLevel === 'district') {
      return district.name;
    }

    if (hierarchyLevel === 'street') {
      return street.name;
    }

    if (hierarchyLevel === 'address') {
      return address;
    }

    return '';
  },
);

export const getParentLocationName = createSelector(
  [getHierarchyLevel, getGeoHierarchy, getStreetWithNumber],
  (hierarchyLevel, geoHierarchy) => {
    const [, region, city, district, street] = geoHierarchy;

    if (hierarchyLevel === 'region') {
      return 'Deutschland';
    }

    if (hierarchyLevel === 'city') {
      return region.name;
    }

    if (hierarchyLevel === 'district') {
      return city.name;
    }

    if (hierarchyLevel === 'street') {
      return district.name;
    }

    if (hierarchyLevel === 'address') {
      return street.name;
    }

    return '';
  },
);

export const getLocationNameForAgentTouchpoint = createSelector(
  [getHierarchyLevel, getGeoHierarchy, getStreetWithNumber],
  (hierarchyLevel, geoHierarchy) => {
    const [, region, city, district] = geoHierarchy;

    if (hierarchyLevel === 'region') {
      return region.name;
    }

    if (hierarchyLevel === 'city') {
      return city.name;
    }

    return district ? district.name : '';
  },
);

export const getGeoId = createSelector([getHierarchyLevel, getGeoHierarchy], (hierarchyLevel, geoHierarchy) => {
  const [, region, city, district] = geoHierarchy;

  switch (hierarchyLevel) {
    case geoHierarchyLevels.REGION:
      return region.id;

    case geoHierarchyLevels.CITY:
      return city.id;

    case geoHierarchyLevels.DISTRICT:
      return district.id;

    case geoHierarchyLevels.STREET:
      return district.id;

    case geoHierarchyLevels.ADDRESS:
      return district.id;

    default:
      return '';
  }
});

export const getPricesByHierarchyLevel = (prices, hierarchyLevel) => {
  if (hierarchyLevel === 'region') {
    return { nativeData: prices.find((x) => x.type === 'REGION') };
  }

  if (hierarchyLevel === 'city') {
    return {
      nativeData: prices.find((x) => x.type === 'CITY'),
      parentData: prices.find((x) => x.type === 'REGION' && x.name !== 'Berlin' && x.name !== 'Hamburg'),
    };
  }

  if (hierarchyLevel === 'district') {
    return { nativeData: prices.find((x) => x.type === 'DISTRICT'), parentData: prices.find((x) => x.type === 'CITY') };
  }

  if (hierarchyLevel === 'address' || hierarchyLevel === 'street') {
    return { nativeData: prices.find((x) => x.type === 'NHOOD'), parentData: prices.find((x) => x.type === 'CITY') };
  }

  return {};
};

export const getPriceData = createSelector([getPrices, getHierarchyLevel], getPricesByHierarchyLevel);

export const getPriceRange = createSelector([getPriceData], ({ nativeData }) =>
  nativeData && nativeData.mostRecentData ? nativeData.mostRecentData.priceRange : null,
);
export const isPriceRangeAvailable = createSelector(
  [getPriceRange],
  (priceRange) => !!(priceRange && priceRange.lowerBound && priceRange.upperBound),
);
export const getPriceRangeText = createSelector([getPriceRange, isPriceRangeAvailable], (priceRange, isAvailable) =>
  isAvailable ? `${formatDE(priceRange.lowerBound, 0)} - ${formatDE(priceRange.upperBound, 0)} €/m²` : NO_PRICE_RANGE,
);
export const getMostRecentPriceRangeData = createSelector([getPriceData], ({ nativeData }) =>
  nativeData ? nativeData.mostRecentData : null,
);

export const getPricesPerQ = createSelector([getPriceData], ({ nativeData, parentData }) => ({
  nativeData: nativeData ? nativeData.pricesPerQ  : [],
  parentData: parentData ? parentData.pricesPerQ  : [],
}));

export const getPricesPerAnnualCurrentQ = createSelector([getPriceData], ({ nativeData, parentData }) => {
  const currentData = nativeData || parentData;
  let mostRecentQuarter=0;
  if (currentData && currentData.pricesPerQ) {
    const { pricesPerQ } = currentData;
    mostRecentQuarter = pricesPerQ[pricesPerQ.length - 1].quarter;
  }
  return {
    nativeDataMobile: nativeData ? nativeData.pricesPerQ.filter(price => price.quarter === mostRecentQuarter): [],
    parentDataMobile: parentData ? parentData.pricesPerQ.filter(price => price.quarter === mostRecentQuarter): [],
  }
});

export const getPricesPerYear = createSelector([getPriceData], ({ nativeData, parentData }) => ({
  nativeDataMobile: nativeData ? nativeData.prices : [],
  parentDataMobile: parentData ? parentData.prices : [],
}));

export const getPercDiffPerQ = createSelector([getPriceData], ({ nativeData, parentData }) => {
  const currentData = nativeData || parentData;
  if (currentData && currentData.pricesPerQ) {
    const { pricesPerQ } = currentData;
    const mostRecentPrice = pricesPerQ[pricesPerQ.length - 1].price;
    const oldestPrice = pricesPerQ[0].price;
    return calculateDeltaInPercent(oldestPrice, mostRecentPrice);
  }
  return currentData ? currentData.percDiff : 0;
});

export const getPercDiffPerYear = createSelector([getPriceData], ({ nativeData, parentData }) => {
  const currentData = nativeData || parentData;
  if (currentData && currentData.prices) {
    const { prices } = currentData;
    const mostRecentPrice = prices[prices.length - 1].price;
    const oldestPrice = prices[0].price;
    return calculateDeltaInPercent(oldestPrice, mostRecentPrice);
  }
  return currentData ? currentData.percDiff : 0;
});

export const getParentLocation = createSelector(
  [getHierarchyLevel, getGeoHierarchy],
  (hierarchyLevel, geoHierarchy) => {
    const [, region, city, district] = geoHierarchy;

    if (hierarchyLevel === 'city') {
      return region.name;
    }

    if (hierarchyLevel === 'district') {
      return city.name;
    }

    if (hierarchyLevel === 'street') {
      return district.name;
    }

    if (hierarchyLevel === 'address') {
      return district.name;
    }
    return '';
  },
);

export const getCityOrRegionLocation = createSelector(
  [getHierarchyLevel, getGeoHierarchy],
  (hierarchyLevel, geoHierarchy) => {
    const [, region, city] = geoHierarchy;

    if (hierarchyLevel === 'city') {
      return region.name;
    }

    if (hierarchyLevel === 'district') {
      return city.name;
    }

    if (hierarchyLevel === 'street') {
      return city.name;
    }

    if (hierarchyLevel === 'address') {
      return city.name;
    }
    return '';
  },
);

export const getSuggestionQuery = createSelector([getAddressSearch], (addressSearch) => addressSearch.suggestionQuery);

export const getSuggestionResults = createSelector(
  [getAddressSearch],
  (addressSearch) => addressSearch.suggestionResults,
);

export const getSelectedSuggestionIndex = createSelector(
  [getAddressSearch],
  (addressSearch) => addressSearch.selectedSuggestionIndex,
);

export const getGoogleSuggestionQuery = createSelector(
  [getGoogleAddressSearch],
  (googleAddressSearch) => googleAddressSearch.suggestionQuery,
);

export const getGoogleSuggestionResults = createSelector(
  [getGoogleAddressSearch],
  (googleAddressSearch) => googleAddressSearch.suggestionResults,
);

export const getGoogleSelectedSuggestionIndex = createSelector(
  [getGoogleAddressSearch],
  (googleAddressSearch) => googleAddressSearch.selectedSuggestionIndex,
);

export const getGoogleAutoCompleteScriptInitialized = createSelector(
  [getGoogleAddressSearch],
  (googleAddressSearch) => googleAddressSearch.googleAutoCompleteScriptInitialized,
);

export const getGooglePublicApiKey = createSelector([getConfig], (config) => config.googleMapsPublicApiKey);

export const getHeaderVisibility = createSelector(
  [getWindowWidth, getScrollY],
  (width, scrollY) => {
    /* check for mobile logic */
    if (width <= PALM_MAX) {
      return scrollY <= 0;
    }
    // used to hide/show top part of tablet
    return scrollY <= 68;
  },
);

export const getIsNavigationHeaderFolded = createSelector(
  [getScrollY, isBurgerMenuOpened, getWindowWidth],
  (scrollY, burgerMenuOpened, width) =>
    (scrollY > 0 && !burgerMenuOpened) || (width <= PALM_MAX && !burgerMenuOpened),
);

export const getPriceCheckInputs = createSelector(
  [getAddress, getPriceCheckSettings],
  (address, priceCheckSettings) => ({
    zipCode:
      priceCheckSettings.priceCheckInputs.zipCode === undefined
        ? address.zipcode
        : priceCheckSettings.priceCheckInputs.zipCode,

    street: priceCheckSettings.priceCheckInputs.zipCode === undefined ? address.street : undefined,

    houseNumber: priceCheckSettings.priceCheckInputs.zipCode === undefined ? address.houseNumber : undefined,

    area: priceCheckSettings.priceCheckInputs.area,
    livingArea: priceCheckSettings.priceCheckInputs.livingArea,
    rooms: priceCheckSettings.priceCheckInputs.rooms,
  }),
);
export const getViewName = createSelector([getHash], (hash) => getActiveView(hash));

export const getPageViewId = (state, { viewName = '' } = {}) => {
  const hierarchyLevel = getHierarchyLevel(state);
  const realEstateType = getRealEstateType(state);
  const finalViewName = viewName || getViewName(state);
  const [commercializationType, propertyType] = (realEstateType || '').split('_');
  return `is24.de.propertybook.${hierarchyLevel || null}.${commercializationType || null}.${propertyType || null}.${
    finalViewName || null
  }`.toLowerCase();
};

export const getGeoData = createSelector([getGeoHierarchy, getAddress], (geoHierarchy, address) => ({
  geoHierarchy,
  address,
}));

export const getPropertyType = createSelector([getRealEstateType], (realEstateType) => realEstateType.split('_')[0]);

export const getPricesPerQuarterDashboard = createSelector(
  [getPriceHistory, getRealEstateType],
  (priceHistory, realEstateType) => {
    if (!(priceHistory && priceHistory[realEstateType] && priceHistory[realEstateType].length)) {
      return null;
    }
    const [{ pricesPerQ }] = priceHistory[realEstateType];
    return pricesPerQ;
  },
);

export const getPricesPerYearDashboard = createSelector(
  [getPriceHistory, getRealEstateType],
  (priceHistory, realEstateType) => {
    if (!(priceHistory && priceHistory[realEstateType] && priceHistory[realEstateType].length)) {
      return null;
    }
    const [{ prices }] = priceHistory[realEstateType];
    return prices;
  },
);

export const getPricesForHierarchyLevel = (prices, hierarchyLevel) =>
  prices.find((x) => x.type === hierarchyLevel.toUpperCase());

export const getApartmentAndHousePrices = createSelector(
  [getPriceHistory, getRealEstateType, getHierarchyLevel],
  (priceHistory, realEstateType, hierarchyLevel) => {
    const userIntent = realEstateType.split('_')[1];
    if (!(priceHistory && priceHistory[realEstateType] && priceHistory[realEstateType].length)) {
      return null;
    }
    const apartmentPrices = getPricesForHierarchyLevel(priceHistory[`APARTMENT_${userIntent}`], hierarchyLevel);
    const housePrices = getPricesForHierarchyLevel(priceHistory[`HOUSE_${userIntent}`], hierarchyLevel);
    return {
      apartmentPrices,
      housePrices,
    };
  },
);

export const getPricesForPriceHistoryWidget = createSelector(
  [getPriceHistory, getHierarchyLevel],
  (priceHistory, hierarchyLevel) => {
    if (!priceHistory || isEmpty(priceHistory)) {
      return null;
    }
    const prices = {};
    Object.keys(realEstateTypes).forEach((realEstateType) => {
      prices[realEstateType] = getPricesForHierarchyLevel(priceHistory[realEstateType], hierarchyLevel);
    });
    return prices;
  },
);

export const getAddressTitleAndSubtitle = createSelector(
  [getAddress, getGeoHierarchy, getHierarchyLevel, getDuplicateDistricts],
  (address, geoHierarchy, hierarchyLevel, duplicateDistricts) => {
    if (address === {} || geoHierarchy === {}) {
      return null;
    }
    return titleExtractor(hierarchyLevel, geoHierarchy, address, duplicateDistricts);
  },
);

export const getLatestQuarterPriceInformationData = createSelector([getPriceHistory], (priceHistory) => {
  const priceInformationData = {};
  const keys = Object.keys(priceHistory);
  keys.forEach((key) => {
    priceInformationData[key] = priceHistory[key][0].pricesPerQ[priceHistory[key][0].pricesPerQ.length - 1];
  });
  return priceInformationData;
});
