import { find, some } from 'lodash';
import { matchPath } from 'react-router-dom';
import React from 'react';
import MenuIcon from './MenuIcon';
import TabConfig from './TabConfig.js';

export const TAB = {
  home: new TabConfig({
    id: 'home',
    name: '', // slash route '/'
    displayName: 'Preis-Übersicht',
    icon: () => <MenuIcon className="is24-icon-price-map" />,
    trackingLabel: 'navigation_dashboard_overview',
    isActive: (pathname) => pathname === '/',
  }),
  priceHistory: new TabConfig({
    id: 'priceHistory',
    name: 'preisentwicklung',
    displayName: 'Preisentwicklung',
    icon: () => <MenuIcon className="is24-icon-statistic-with-arrow-up" />,
    trackingLabel: 'navigation_price_dev_view',
  }),
  priceCheck: new TabConfig({
    id: 'priceCheck',
    name: 'preis-check',
    displayName: 'Marktwert-Rechner',
    icon: () => <MenuIcon className="is24-icon-price-check" />,
    trackingLabel: 'navigation_price_check_view',
  }),
};

export const getActiveTab = (path) => tabIdByRoute(path);

function tabIdByRoute(route) {
  switch (route) {
    case `/`:
      return TAB.home.id;
    case `/${TAB.priceHistory.name}`:
      return TAB.priceHistory.id;
    case `/${TAB.priceCheck.name}`:
      return TAB.priceCheck.id;
    default:
      return TAB.home.id;
  }
}

const findPageId = (route = '') => {
  const pageViewIdByPath = [
    { path: `/${TAB.priceHistory.name}`, pageViewId: `${TAB.priceHistory.id}` },
    { path: `/${TAB.priceCheck.name}`, pageViewId: `${TAB.priceCheck.id}` },
    { path: `/`, pageViewId: `${TAB.home.id}` },
  ];

  const found = find(pageViewIdByPath, (pageView) =>
    Boolean(matchPath({ path: pageView.path }, route)),
  );
  return found ? found.pageViewId : `${TAB.home.id}`;
};

export const getActiveView = (path) => findPageId(path);

export const isKnownTab = (route) => {
  const knownTabPaths = [
    `/${TAB.home.name}`,
    `/${TAB.priceHistory.name}`,
    `/${TAB.priceCheck.name}`,
  ];
  return some(knownTabPaths, (path) => Boolean(matchPath({ path }, route)));
};

export const TAB_CONFIG = [TAB.home, TAB.priceHistory, TAB.priceCheck];

export const navigateToUrl = (navigate, url, replaceState = false) => navigate(url, { replace: replaceState });
export const navigateTo = (navigate, tab, replaceState = false) => {
  navigateToUrl(navigate, `/${tab.name}`, replaceState);
};
