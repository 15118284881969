import React from "react";
import PropTypes from "prop-types";
import styles from "./Chips.less";

const Chip = ({ name, value, groupName, onChange, defaultChecked }) => (
  <div className={`grid-item chip ${styles.single_chip} ${styles.chip_radio}`} role="presentation">
    <input
      type="radio"
      id={`input-${value}`}
      value={value}
      name={groupName}
      onChange={onChange}
      checked={defaultChecked === value}
    />
    <span role="button" tabIndex="0">
      <label htmlFor={`input-${value}`}>{name}</label>
    </span>
  </div>
)


Chip.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  groupName: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked:PropTypes.string,
};


Chip.defaultProps = {
  onChange: () => {},
  defaultChecked: "",
};


export default Chip
