export const disablePageScroll = (): void => {
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
}

export const enablePageScroll = (): void => {
  document.body.style.overflow = 'unset';
  document.body.style.position = 'unset';
  document.body.style.width = 'auto';

}
