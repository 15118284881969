import React from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import styles from '../styles.less';
import { PRIMARY_BLUE, PRIMARY_GREEN } from '../../../utils/colorPalette';

export default class ChartLegend extends React.Component {
  render() {
    const items = this.props.items;
    const legend = uniqBy(items, 'text');
    return (
      <div className={`inline-block ${styles.legend}`}>
        {legend.map((item, index) => [
          <span key={`chart-legend-${index}`} className="inline-block">
            <span style={{ background: item.color }} className={`inline-block ${styles.box}`} />
            <span className={`margin-right-s ${styles.text}`}>{item.text}</span>
          </span>,
        ])}
        {this.props.showFuturePriceTrend && (
          <span className="inline-block">
            <span style={{ background: PRIMARY_BLUE() }} className={`inline-block ${styles.square_box}`} />
            <span style={{ background: 'black' }} className={`inline-block ${styles.duobox}`} />
            <span style={{ background: PRIMARY_GREEN() }} className={`inline-block ${styles.square_box}`} />
            <span className={`margin-right-s ${styles.text}`} key="legend-text-block">
              Preisprognose
            </span>
          </span>
        )}
      </div>
    );
  }
}

const LegendItem = PropTypes.shape({
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
});

ChartLegend.defaultProps = {
  showFuturePriceTrend: false,
};

ChartLegend.propTypes = {
  items: PropTypes.arrayOf(LegendItem).isRequired,
  showFuturePriceTrend: PropTypes.bool,
};
