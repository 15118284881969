import React, { Component } from 'react';
import throttle from 'lodash/throttle';

export default (ChildComponent) =>
  class ResponsiveChild extends Component {
    constructor(props) {
      super(props);

      this.state = {
        containerWidth: null,
        windowHeight: null,
      };

      this.fitParentContainer = this.fitParentContainer.bind(this);
      // this.fitParentContainerMaybe = () => throttle(() => this.fitParentContainer(), 100);
      this.fitParentContainerMaybe = throttle(() => this.fitParentContainer(), 100);
    }

    componentDidMount() {
      this.fitParentContainerMaybe();
      window.addEventListener('resize', this.fitParentContainerMaybe);
    }

    componentDidUpdate() {
      this.fitParentContainerMaybe();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainerMaybe);
    }

    fitParentContainer() {
      const { containerWidth, windowHeight } = this.state;
      const currentContainerWidth =
        this.childContainer && this.childContainer.getBoundingClientRect
          ? this.childContainer.getBoundingClientRect().width
          : containerWidth;
      const currentWindowHeight = window.innerHeight;
      const shouldResize = windowHeight !== currentWindowHeight || containerWidth !== currentContainerWidth;

      if (shouldResize) {
        this.setState({
          containerWidth: currentContainerWidth,
          windowHeight: currentWindowHeight,
        });
      }
    }

    renderChild() {
      const parentWidth = this.state.containerWidth;
      const windowHeight = this.state.windowHeight;
      return <ChildComponent {...this.props} parentWidth={parentWidth} windowHeight={windowHeight} />;
    }

    render() {
      const { containerWidth } = this.state;
      const shouldRenderChild = containerWidth !== null;

      return (
        <div
          ref={(el) => {
            this.childContainer = el;
          }}
          className="grid"
        >
          {shouldRenderChild && this.renderChild()}
        </div>
      );
    }
  };
