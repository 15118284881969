import debounce from 'lodash/debounce';

export const CANCEL_ADDRESS_SEARCH = 'CANCEL_ADDRESS_SEARCH';

export const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS';
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS_SUCCESS';
export const FETCH_SUGGESTIONS_FAILURE = 'FETCH_SUGGESTIONS_FAILURE';

export const FETCH_SINGLE_SUGGESTIONS = 'FETCH_SINGLE_SUGGESTIONS';
export const FETCH_SINGLE_SUGGESTIONS_SUCCESS = 'FETCH_SINGLE_SUGGESTIONS_SUCCESS';
export const FETCH_SINGLE_SUGGESTIONS_FAILURE = 'FETCH_SINGLE_SUGGESTIONS_FAILURE';

export const SUGGESTIONS_ARROW_DOWN_EVENT = 'SUGGESTIONS_ARROW_DOWN_EVENT';
export const SUGGESTIONS_ARROW_UP_EVENT = 'SUGGESTIONS_ARROW_UP_EVENT';

export const WINDOW_WIDTH_CHANGED = 'WINDOW_WIDTH_CHANGED';
export const SCROLL_Y = 'SCROLL_Y';
export const SCREEN_RESIZE = 'SCREEN_RESIZE';

export const SELECT_REAL_ESTATE_TYPE = 'SELECT_REAL_ESTATE_TYPE';
export const UPDATE_REAL_ESTATE_TYPE = 'UPDATE_realEstateType';

export const SELECT_USER_INTENT = 'SELECT_USER_INTENT';
export const UPDATE_USER_INTENT = 'UPDATE_USER_INTENT';

export const SAVE_LAST_SEARCH_INPUT = 'SAVE_LAST_SEARCH_INPUT';

export const SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE = 'SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE';

const fetchSuggestionsFromBackend= debounce((url, urlSearchParams, dispatch) => {
  return fetch(`${url}?${urlSearchParams}`)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      const status = `http status: ${response.status}`;
      return Promise.reject(status);
    })
    .then((json) => dispatch(fetchSuggestionsSuccess(json)))
    .catch((error) => dispatch(fetchSuggestionsFailure(error)));
}, 500);

function fetchSuggestions(suggestionQuery) {
  return {
    type: FETCH_SUGGESTIONS,
    suggestionQuery,
  };
}

function fetchSuggestionsSuccess(suggestionResult) {
  return {
    type: FETCH_SUGGESTIONS_SUCCESS,
    suggestionsResult: suggestionResult,
  };
}

function fetchSuggestionsFailure(error) {
  return {
    type: FETCH_SUGGESTIONS_FAILURE,
    error,
  };
}

function fetchSingleSuggestions(suggestionQuery) {
  return {
    type: FETCH_SINGLE_SUGGESTIONS,
    suggestionQuery,
  };
}

function fetchSingleSuggestionsSuccess(suggestionResult) {
  return {
    type: FETCH_SINGLE_SUGGESTIONS_SUCCESS,
    suggestionsResult: suggestionResult,
  };
}

function fetchSingleSuggestionsFailure(error) {
  return {
    type: FETCH_SINGLE_SUGGESTIONS_FAILURE,
    error,
  };
}

const actionCreators = {
  fetchSuggestions,
  fetchSuggestionsSuccess,
  fetchSuggestionsFailure,
  fetchSingleSuggestions,
  fetchSingleSuggestionsSuccess,
  fetchSingleSuggestionsFailure,

  suggestionsArrowDownEvent: () => ({
    type: SUGGESTIONS_ARROW_DOWN_EVENT,
  }),

  suggestionsArrowUpEvent: () => ({
    type: SUGGESTIONS_ARROW_UP_EVENT,
  }),

  cancelAddressSearch: () => ({
    type: CANCEL_ADDRESS_SEARCH,
  }),

  scrollY: (y) => ({ type: SCROLL_Y, value: y }),

  updateWindowWidth: (windowSize) => ({ type: WINDOW_WIDTH_CHANGED, windowSize }),

  screenResize: (width) => ({
    type: SCREEN_RESIZE,
    screenWidth: width,
  }),

  updateSuggestionQuery: (suggestionQuery) => (dispatch) => {
    dispatch(fetchSuggestions(suggestionQuery));
    const urlSearchParams = new URLSearchParams({
      searchString: suggestionQuery
    });
    const url = '/suggestions';

    return fetchSuggestionsFromBackend(url, urlSearchParams, dispatch);
  },

  selectRealEstateType: (value) => ({
    type: SELECT_REAL_ESTATE_TYPE,
    value,
  }),

  selectUserIntent: (value) => ({
    type: SELECT_USER_INTENT,
    value,
  }),

  saveLastSearchInput: (inputValue, atlasUrlSelected) => ({
    type: SAVE_LAST_SEARCH_INPUT,
    inputValue,
    atlasUrlSelected
  }),

  saveLastUserIntentAndPropertyType: (userIntent, realEstateType) => ({
    type: SAVE_LAST_USER_INTENT_AND_REAL_ESTATE_TYPE,
    userIntent,
    realEstateType
  }),
};

export default actionCreators;
