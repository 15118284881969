export const CANCEL_ADDRESS_SEARCH = 'CANCEL_ADDRESS_SEARCH';
export const FOCUS_ADDRESS_SEARCH = 'FOCUS_ADDRESS_SEARCH';

export const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS';
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS_SUCCESS';
export const FETCH_SUGGESTIONS_FAILURE = 'FETCH_SUGGESTIONS_FAILURE';

export const SUGGESTIONS_ARROW_DOWN_EVENT = 'SUGGESTIONS_ARROW_DOWN_EVENT';
export const SUGGESTIONS_ARROW_UP_EVENT = 'SUGGESTIONS_ARROW_UP_EVENT';
export const RESET_SELECTED_SUGGESTION_INDEX = 'RESET_SELECTED_SUGGESTION_INDEX';
export const GOOGLE_AUTOCOMPLETE_SCRIPT_INITIALIZED = 'GOOGLE_AUTOCOMPLETE_SCRIPT_INITIALIZED';

const RESTRICTIONS = {
  country: 'de',
};
const TYPES = ['geocode'];
const SEARCH_QUERY_MIN_LENGTH = 3;

const fetchSuggestions = (suggestionQuery) => ({
  type: FETCH_SUGGESTIONS,
  suggestionQuery,
});

const fetchSuggestionsSuccess = (suggestionResult) => ({
  type: FETCH_SUGGESTIONS_SUCCESS,
  suggestionsResult: suggestionResult,
});

const fetchSuggestionsFailure = (error) => ({
  type: FETCH_SUGGESTIONS_FAILURE,
  error,
});

const resetSelectedSuggestionIndex = () => ({
  type: RESET_SELECTED_SUGGESTION_INDEX,
});

const actionCreators = {
  fetchSuggestions,
  fetchSuggestionsSuccess,
  fetchSuggestionsFailure,
  resetSelectedSuggestionIndex,

  suggestionsArrowDownEvent: () => ({
    type: SUGGESTIONS_ARROW_DOWN_EVENT,
  }),

  suggestionsArrowUpEvent: () => ({
    type: SUGGESTIONS_ARROW_UP_EVENT,
  }),

  cancelAddressSearch: () => ({
    type: CANCEL_ADDRESS_SEARCH,
  }),

  focusAddressSearchInput: () => ({
    type: FOCUS_ADDRESS_SEARCH,
  }),

  googleScriptInitialized: () => ({
    type: GOOGLE_AUTOCOMPLETE_SCRIPT_INITIALIZED,
  }),

  updateSuggestionQuery: ({ suggestionQuery, autoComplete, sessionToken }) => (dispatch) => {
    dispatch(fetchSuggestions(suggestionQuery));

    const input = suggestionQuery.trim();

    if (input.length < SEARCH_QUERY_MIN_LENGTH) {
      dispatch(fetchSuggestionsSuccess([]));
      return;
    }

    const request = {
      input,
      sessionToken,
      types: TYPES,
      componentRestrictions: RESTRICTIONS,
    };

    autoComplete.getPlacePredictions(request, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        return dispatch(fetchSuggestionsSuccess(predictions));
      }
      if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        return dispatch(fetchSuggestionsSuccess([]));
      }
      return dispatch(fetchSuggestionsFailure(status));
    });
  },
};

export default actionCreators;
