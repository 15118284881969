const HOUSE_BUY = 'HOUSE_BUY';
const HOUSE_RENT = 'HOUSE_RENT';
const APARTMENT_BUY = 'APARTMENT_BUY';
const APARTMENT_RENT = 'APARTMENT_RENT';
export const HOUSE = 'HOUSE';
export const APARTMENT = 'APARTMENT';
export const RENT = 'RENT';
export const BUY = 'BUY';
export const LET = 'LET';
export const SELL = 'SELL';
export const COMMERCIALIZATION_TYPES = [RENT, BUY];

export const DEFAULT_MARKETING_FOCUS = APARTMENT_BUY;

// TODO export consts and refactor all the usages
export default {
  HOUSE_BUY,
  HOUSE_RENT,
  APARTMENT_BUY,
  APARTMENT_RENT,
};

export function getPropertyType(realEstateType) {
  return realEstateType.split('_')[0];
}

export function getReducedUserIntent(intent){
  switch (intent) {
    case RENT:
    case LET:
      return LET;
    case BUY:
    case SELL:
      return SELL;
    default:
      return SELL;
  }
}

export function getCommercializationType(realEstateType) {
  if (!realEstateType) {
    return null;
  }

  return realEstateType.split('_').pop();
}

export function getShortName(realEstateType) {
  return getPropertyType(realEstateType)[0] + getCommercializationType(realEstateType)[0];
}

export function normalizeRealEstateType(realEstateType) {
  return realEstateType.toUpperCase();
}

export function getGermanReadableRealEstateType(realEstateType) {
  switch (realEstateType.toUpperCase()) {
    case HOUSE_BUY:
    case HOUSE_RENT:
      return 'Haus';

    case APARTMENT_BUY:
    case APARTMENT_RENT:
      return 'Wohnung';

    default:
      return null;
  }
}

export function getGermanReadableRealEstateTypePlural(realEstateType) {
  switch (realEstateType.toUpperCase()) {
    case HOUSE_BUY:
    case HOUSE_RENT:
      return 'Häuser';

    case APARTMENT_BUY:
    case APARTMENT_RENT:
      return 'Wohnungen';

    default:
      return null;
  }
}
