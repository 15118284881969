import {
  FETCH_SUGGESTIONS_SUCCESS,
  FETCH_SUGGESTIONS_FAILURE,
  FETCH_SUGGESTIONS,
  CANCEL_ADDRESS_SEARCH,
  SUGGESTIONS_ARROW_DOWN_EVENT,
  SUGGESTIONS_ARROW_UP_EVENT,
  RESET_SELECTED_SUGGESTION_INDEX,
  GOOGLE_AUTOCOMPLETE_SCRIPT_INITIALIZED,
} from './actions.js';

const nothingSelected = -1;

const initialState = {
  selectedSuggestionIndex: nothingSelected,
  isFetching: false,
  suggestionResults: [],
  error: '',
  suggestionQuery: '',
  googleAutoCompleteScriptInitialized: false,
};

export function addressSearchReducer(state = initialState, action) {
  switch (action.type) {
    case SUGGESTIONS_ARROW_UP_EVENT:
      return Object.assign({}, state, {
        selectedSuggestionIndex:
          state.selectedSuggestionIndex > 0 ? state.selectedSuggestionIndex - 1 : state.selectedSuggestionIndex,
      });

    case SUGGESTIONS_ARROW_DOWN_EVENT: {
      const selectedSuggestionIndex =
        state.selectedSuggestionIndex < state.suggestionResults.length - 1
          ? state.selectedSuggestionIndex + 1
          : state.selectedSuggestionIndex;

      return Object.assign({}, state, { selectedSuggestionIndex });
    }
    case FETCH_SUGGESTIONS_SUCCESS:
      return Object.assign({}, state, { isFetching: false, suggestionResults: action.suggestionsResult });

    case FETCH_SUGGESTIONS_FAILURE:
      return Object.assign({}, state, { isFetching: false, suggestionResults: [], error: action.error });

    case FETCH_SUGGESTIONS:
      return Object.assign({}, state, { isFetching: true, suggestionQuery: action.suggestionQuery });

    case CANCEL_ADDRESS_SEARCH:
      return Object.assign({}, state, {
        selectedSuggestionIndex: nothingSelected,
        suggestionQuery: '',
        suggestionResults: [],
        addressQueryText: '',
      });

    case RESET_SELECTED_SUGGESTION_INDEX:
      return Object.assign({}, state, { selectedSuggestionIndex: nothingSelected });

    case GOOGLE_AUTOCOMPLETE_SCRIPT_INITIALIZED:
      return Object.assign({}, state, { googleAutoCompleteScriptInitialized: true });

    default:
      return state;
  }
}

export default addressSearchReducer;
