import React from 'react';
import PropTypes from "prop-types";
import styles from './HomeOwnerWorldIcon.less';
import LazyLoad from '../../Utils/LazyLoad/LazyLoad';

const HomeOwnerWorldIcon = ({ url, alt, isHomePage }) => (
  <div className={`grid-item grid-item-fixed-width grid-align-center`}>
    <LazyLoad placeholder={<div className={isHomePage? styles.iconPlaceHolderHomepage : styles.iconPlaceHolder}></div>}>
      <img src={url} alt={alt} className={isHomePage ? styles.iconSizeHomepage : styles.iconSize} />
    </LazyLoad>
  </div>
);

HomeOwnerWorldIcon.propTypes = {
  alt: PropTypes.string,
  url: PropTypes.string,
  isHomePage: PropTypes.bool,
};

HomeOwnerWorldIcon.defaultProps = {
  isHomePage: false
};

export default HomeOwnerWorldIcon;
