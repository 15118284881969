export default {
  addresses: {
    title: 'Adressen',
    addressLineOne: (e) => `${e.address.street} ${e.address.houseNumber}${e.address.houseNumberExtension}`,
    addressLineTwo: (e) => `${e.address.zipcode} ${e.address.city}`,
  },
  districts: {
    title: 'Gemeinden / Bezirke',
    addressLineOne: (e) => e.name,
    addressLineTwo: () => '',
  },
  cities: {
    title: 'Landkreise / Städte',
    addressLineOne: (e) => e.name,
    addressLineTwo: () => '',
  },
  regions: {
    title: 'Bundesländer',
    addressLineOne: (e) => e.name,
    addressLineTwo: () => '',
  },
};
