import React from 'react';
import PropTypes from 'prop-types';
import formatLocale from '../../../../utils/formatLocale.js';
import { toNumber, getCurrentPointInTime } from '../utils.js';

import { svgDimensionsFpt, svgMargins } from '../config.js';
import Chart from '../../../Chart/Chart.jsx';
import { PRIMARY_BLUE, PRIMARY_GREEN } from '../../../../utils/colorPalette.js';
import { useOnVisible } from '../../../../utils/visibility';
import styles from './PriceHistoryDetailedChart.less';

function isMajorTick(i) {
  return i % 1 === 0;
}

const PriceHistoryDetailedChart = ({
  nativeData,
  parentData,
  commercializationType,
  updateSelected,
  isSidebarDesktopExpanded,
  selected,
  useVisibleHook,
  showMpPromoDialog,
  showGridLines,
  futureTrend,
}) => {
  const { observableElement } = useVisibleHook();
  const timeline = (nativeData && nativeData.length > 0 ? nativeData : parentData).map((p) =>
    toNumber(p.year, p.quarter),
  );
  let latestPointInTime = Math.max(...timeline);
  if (showMpPromoDialog) {
    for (let i = 1; i <= 4; i += 1) {
      timeline.push(latestPointInTime + 0.25 * i);
    }
  }
  const firstPointInTime = Math.min(...timeline);
  const currentPointInTime = getCurrentPointInTime({ nativeData, parentData });
  latestPointInTime = Math.max(...timeline);

  const prices = nativeData.concat(parentData).map((p) => p.price);
  const formatY = commercializationType === 'BUY' ? ',d' : ',.2f';

  const chartProps = {
    futureTrend,
    showMpPromoDialog,
    showGridLines,
    firstPointInTime,
    currentPointInTime,
    latestPointInTime,
    commercializationType,
    isSidebarDesktopExpanded,
    updateSelected,
    leftOffset: svgMargins.left, // value measured to the start of the first point on x axis from the left of the svg
    rightOffset: svgMargins.right, // value measured to the end of the last point on x axis from the right of the svg
    sectionsPerYear: 4, // number of sections in a year
    svgDimensions: svgDimensionsFpt,
    margins: svgMargins,
    dx: {
      min: Math.min(...timeline),
      max: Math.max(...timeline),
      selected: selected || getCurrentPointInTime({ nativeData, parentData }),
      values: {
        major: timeline.filter((i) => isMajorTick(i)),
        minor: timeline.filter((i) => !isMajorTick(i)),
      },
      format: {
        major: (d) => `Q1 ${d}`,
        minor: (d) => `Q${Math.round((d % 1) / 0.25) + 1}`,
      },
      orient: 'bottom',
    },
    dy: {
      min: Math.min(...prices),
      max: Math.max(...prices),
      values: {
        major: [],
        minor: [],
      },
      format: {
        major: (d) => `${formatLocale.format(formatY)(d)}`,
        minor: () => '',
      },
      ticksNumber: 3,
      orient: 'left',
      axisLabel: {
        label: '€ / m²',
        transform: 'translate(25, 15)',
      },
    },
    data: [
      {
        values: parentData.map((p) => ({ x: toNumber(p.year, p.quarter), y: p.price })),
        color: PRIMARY_GREEN(),
      },
      {
        values: nativeData.map((p) => ({ x: toNumber(p.year, p.quarter), y: p.price })),
        color: PRIMARY_BLUE(),
      },
    ],
    isMobile: false
  };

  return (
    <div ref={observableElement} className={styles.priceHistoryDetailedChartContainer}>
      <Chart {...chartProps} />
    </div>
  );
};

PriceHistoryDetailedChart.defaultProps = {
  selected: null,
  showMpPromoDialog: false,
  useVisibleHook: useOnVisible,
  futureTrend: false,
};

const priceShape = PropTypes.shape({
  year: PropTypes.number.isRequired,
  quarter: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
});

PriceHistoryDetailedChart.propTypes = {
  futureTrend: PropTypes.bool,
  showMpPromoDialog: PropTypes.bool,
  showGridLines: PropTypes.bool,
  nativeData: PropTypes.arrayOf(priceShape).isRequired,
  parentData: PropTypes.arrayOf(priceShape).isRequired,
  commercializationType: PropTypes.string.isRequired,
  updateSelected: PropTypes.func.isRequired,
  selected: PropTypes.number || null,
  useVisibleHook: PropTypes.func,
};

export default PriceHistoryDetailedChart;
