import React from 'react';
import PropTypes from 'prop-types';
import PricePerMeter from '../../../../Utils/PricePerMeter/PricePerMeter.jsx';
import { formatDE } from '../../../../../utils/NumbersHelper.js';
import styles from './StatsBoxItemMobile.less';

export default class StatsBoxItemMobile extends React.Component {
  renderDelta() {
    const {
      futureTrend,
      itemData: { previousQ, deltaToPreviousQ },
    } = this.props;

    const sign = deltaToPreviousQ >= 0 ? '+' : '';
    const deltaToPreviousYearInPercent = formatDE(deltaToPreviousQ * 100, 1);

    if (previousQ && deltaToPreviousQ) {
      return (
        <div id="cosmaDeltaInfo" className={futureTrend ? styles.deltaLineFpt : styles.deltaLine}>
          <span className={styles.deltaLine_number}>
            {sign}
            {deltaToPreviousYearInPercent}&#37;
          </span>{' '}
          <span className={styles.deltaLine_description}>zu {previousQ}</span>
        </div>
      );
    }
    return null;
  }

  render() {
    const { commercializationType, futureTrend } = this.props;
    const { locationName, price, color } = this.props.itemData;
    const delta = this.renderDelta();

    return (
      <div>
        <PricePerMeter
          appearance="cosma"
          price={price}
          color={color}
          commercializationType={commercializationType}
          futureTrend={futureTrend}
        />
        {delta}
        <div className={`font-ellipsis one-whole font-nowrap inline-block ${styles.locationName}`}>
          <div
            className="inline-block"
            style={{
              backgroundColor: color,
              height: '8px',
              width: '8px',
              borderRadius: '4px',
            }}
          />
          <span>{locationName}</span>
        </div>
      </div>
    );
  }
}

StatsBoxItemMobile.propTypes = {
  itemData: PropTypes.object.isRequired,
  commercializationType: PropTypes.string.isRequired,
  futureTrend: PropTypes.bool,
};

StatsBoxItemMobile.defaultProps = {
  futureTrend: false,
};
