import React from 'react';
import PropTypes from 'prop-types';

export default function Close(props) {
  const { width, height, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ display: 'block' }}
    >
      <path
        d="M19.571.429c-.571-.572-1.428-.572-2 0L10 8 2.429.429c-.572-.572-1.429-.572-2 0-.572.571-.572 1.428 0 2L8 10 .429 17.571c-.572.572-.572 1.429 0 2 .285.286.571.429 1 .429.428 0 .714-.143 1-.429L10 12l7.571 7.571c.286.286.715.429 1 .429.286 0 .715-.143 1-.429.572-.571.572-1.428 0-2L12 10l7.571-7.571c.572-.572.572-1.429 0-2z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}

Close.defaultProps = {
  fill: '#646464',
  width: 15,
  height: 15,
};

Close.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
