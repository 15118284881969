import React from 'react';
import EkomiComponent, {
  VALID_SEALS,
} from './EkomiComponent';
import data from './ekomi.json';

export const EKOMI_LINK = 'https://www.ekomi.de/bewertungen-immobilien-scout24.html';

const EkomiWidget = ({ seal = data.seal, number_of_ratings = data.number_of_ratings, rounded_average = data.rounded_average }) => {
  if (!seal || !number_of_ratings || !rounded_average) {
    return null;
  }

  const numberOfRatings = parseInt(number_of_ratings);
  if (isNaN(numberOfRatings) || !numberOfRatings || numberOfRatings < 1000) {
    return null;
  }

  const count = Math.floor(numberOfRatings / 1000) * 1000;
  const rating = parseFloat(rounded_average);

  if (isNaN(rating) || rating < 0 || rating > 5) {
    return null;
  }

  if (VALID_SEALS.indexOf(seal) < 0) {
    return null;
  }

  return (
    <EkomiComponent seal={seal} rating={rating} count={count} link={EKOMI_LINK} />
  )
};

export default EkomiWidget;
