import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { formatDefaultLocale } from 'd3-format';
import { createCookieMiddleware } from 'redux-cookie';
import Cookies from 'js-cookie';
import App from './App.jsx';
import rootReducer from './reducer';
import { isRenderedInBrowser } from '../../utils/envUtils';
import featureSwitchReader from '../../utils/featureSwitchReader';
import localStorage from "../../utils/localStorage";
import { PRICE_ATLAS_LAST_FILTERS } from "../../utils/globals";
import { realEstateInitialState, userIntentInitialState } from "../../reducer";
import '../../assets/styles/footer.less';

let enhancer;
if (isRenderedInBrowser()) {
  if (typeof window.process === 'undefined') {
    // HACK(keanulee): The Redux package assumes `process` exists - mock it here before the module is loaded.
    window.process = {
      env: {
        NODE_ENV: 'production',
      },
    };
  }

  featureSwitchReader.set(window.IS24.PropertyBook.featureSwitches);

  enhancer = applyMiddleware(thunkMiddleware, createCookieMiddleware(Cookies, '/indexpage/cookie/'));

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(enhancer, window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

window.INITIAL_STATE = window.INITIAL_STATE || {};

const lastFilters = localStorage.getItem(PRICE_ATLAS_LAST_FILTERS) || { userIntent: userIntentInitialState, realEstateType: realEstateInitialState };
window.INITIAL_STATE.realEstateType = lastFilters.realEstateType;
window.INITIAL_STATE.userIntent = lastFilters.userIntent;

const store = createStore(rootReducer, window.INITIAL_STATE, enhancer);

formatDefaultLocale({
  // d3 settings
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['', '\u00a0€'],
});

ReactDOM.hydrate(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
);
