export const PROPERTY_BOOK = 'property_book';
export const PROPERTY_BOOK_V2 = 'property_book_v2';
export const EXPLORE_PRICETABLE_LINKS = 'explore_pricetable_links';
export const INTERACTIVE_ATLAS_NAVI_COLLAPSE = 'interactive_atlas_navi_collapse';
export const INTERACTIVE_ATLAS_NAVI_EXPAND = 'interactive_atlas_navi_expand';
export const INDEX_SEARCH_SELECT_ADDRESS = 'index_search_selectaddress';
export const INDEX_SEARCH_SELECT_DISTRICT = 'index_search_selectdistrict';
export const INDEX_SEARCH_SELECT_CITY = 'index_search_selectcity';
export const INDEX_SEARCH_SELECT_REGION = 'index_search_selectregion';
export const INDEX_SEARCH_ENTER_ADDRESS = 'index_search_enteraddress';
export const INDEX_SEARCH_CLICK_ON_SEARCH_FIELD = 'index_search_click_on_search_field';
export const SEARCH_SELECT_ADDRESS = 'search_selectaddress';
export const SEARCH_SELECT_DISTRICT = 'search_selectdistrict';
export const SEARCH_SELECT_CITY = 'search_selectcity';
export const SEARCH_SELECT_REGION = 'search_selectregion';
export const SEARCH_ENTER_ADDRESS = 'search_enteraddress';
export const SEARCH_CLICK_ON_SEARCH_FIELD = 'search_click_on_search_field';
export const SELECT_HOUSE = 'select_house';
export const SELECT_APARTMENT = 'select_apartment';
export const SELECT_BUY = 'select_buy';
export const SELECT_SELL = 'select_sell';
export const SELECT_LET = 'select_let';
export const SELECT_RENT = 'select_rent';
export const PRICEDEV_INFO_ICON = 'pricedev_info_icon';
export const RLE_BANNER = 'rle_banner_click';
export const RLE_BANNER_NEW = 'rle_banner_click_new';
export const RLE_BANNER_OLD = 'rle_banner_click_old';
export const DASHBOARD_BUTTON_PRICE_DEV = 'dashboard_button_price_dev';
export const EXPAND_SEO_LINKS = 'expand_seo_links';
export const EXPLORE_SEO_LINKS = 'explore_seo_links';
export const FUTURE_PRICE_TREND_LINK_MY_PROPERTY = 'pricedev_activate_future_price_trend';
export const REAL_ESTATE_PREVIEW_CLICK = 'select_new_mktg_focus';
export const CLAIM_PROPERTY = 'claim-property';
export const RLE_BANNER_CLICK_COMPLETE_DATA = 'rle_banner_click_complete_data';
export const HOMEOWNER_ACTIVATED = 'homeowner_activated';
export const HOMEOWNER_DEACTIVATED = 'homeowner_deactivated';
export const HOMEOWNER_TOGGLE_OVERLAY_LINK = 'claim-property_homeowner_toggle';
export const GRAPH_INTERACTION = 'graph_interaction'
export const SEARCH_PRICE_MAP = 'search_price_map'
