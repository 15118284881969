import { PROPERTY_BOOK_V2 } from "./trackingNaming";

function isTealiumLoaded() {
  return (
    window.IS24 &&
    window.IS24.TEALIUM &&
    window.IS24.TEALIUM.tracking &&
    window.IS24.TEALIUM.tracking.report &&
    window.IS24.TEALIUM.tracking.getContextData &&
    window.IS24.TEALIUM.tracking.updateCommonUtagData
  );
}

const trace = (...args) => {
  const searchParams = new URLSearchParams(document.location.search);
  if (searchParams.get('debug') === 'true') {
    // eslint-disable-next-line
    console.log('REPORTED: ', ...args);
  }
};

function reportWithRetry(retries, dataToReport, options, storeData) {
  return new Promise((resolve, reject) => {
    if (isTealiumLoaded()) {
      const pageViewId = window.IS24.TEALIUM.tracking.getContextData().pageViewId;
      const data = pageViewId ? { pag_pagetitle: pageViewId, ...dataToReport } : dataToReport;
      trace(data, options);
      window.IS24.TEALIUM.tracking.report(data, options);
      if (storeData) {
        window.IS24.TEALIUM.tracking.updateCommonUtagData(data);
      }
      resolve();
      return;
    }
    let intervalExecutionCounter = 0;
    const interval = setInterval(() => {
      intervalExecutionCounter += 1;

      if (intervalExecutionCounter > retries) {
        clearInterval(interval);
        reject();
      }

      if (isTealiumLoaded()) {
        const pageViewId = window.IS24.TEALIUM.tracking.getContextData().pageViewId;
        const data = pageViewId ? { pag_pagetitle: pageViewId, ...dataToReport } : dataToReport;
        trace(data, options);
        window.IS24.TEALIUM.tracking.report(data, options);
        if (storeData) {
          window.IS24.TEALIUM.tracking.updateCommonUtagData(data);
        }
        clearInterval(interval);
        resolve();
      }
    }, 1000);
  });
}

function report(label, action = PROPERTY_BOOK_V2, category = 'residential') {
  return new Promise((resolve) => {
    reportWithRetry(5, { evt_ga_category: category, evt_ga_action: action, evt_ga_label: label })
      .then(() => resolve())
      .catch(() => resolve());
  });
}

function reportData(data) {
  const { action = PROPERTY_BOOK_V2, category = 'residential', label, pageTitle } = data;
  return new Promise((resolve) => {
    reportWithRetry(5, {
      pag_pagetitle: pageTitle,
      evt_ga_action: action,
      evt_ga_category: category,
      evt_ga_label: label,
      ...data,
    })
      .then(() => resolve())
      .catch(() => resolve());
  });
}

function reportPageView(pageId) {
  return new Promise((resolve) => {
    // try 3 times to report page view with tealium
    reportWithRetry(10, { pag_pagetitle: pageId }, { reportType: 'UTAG_VIEW' })
      .then(() => resolve())
      .catch((err) => resolve());
  });
}

function reportPageViewWithGeoData(pageId, geoCountry, geoState, geoCity, geoZipCode) {
  return new Promise((resolve) => {
    // try 10 times to report page view with tealium
    const dataToReport = {
      pag_pagetitle: pageId,
      geo_land: geoCountry,
      geo_bln: geoState,
      geo_krs: geoCity,
      geo_plz: geoZipCode,
    };

    reportWithRetry(10, dataToReport, { reportType: 'UTAG_VIEW' }, true)
      .then(() => resolve())
      .catch(() => resolve());
  });
}

const cmpTrackingData = (id, creative) => ({
  cmpId: id,
  cmpName: 'residential_realtorleadengine',
  cmpPosition: 'residential_atlas',
  cmpCreative: creative,
});

const cmpTrackingDataQueryString = ({ id, name, position, creative }) =>
  new URLSearchParams({
    cmp_id: id,
    cmp_name: name,
    cmp_position: position,
    cmp_creative: creative,
  }).toString();

function overwriteTrackingEventContext(eventContext) {
  if (isTealiumLoaded()) {
    const oldContextData = window.IS24.TEALIUM.tracking.getContextData();
    window.IS24.TEALIUM.tracking.overwriteContextData({
      ...oldContextData,
      pag_evt_context: eventContext,
    });
  }
}

function revertTrackingEventContext() {
  if (isTealiumLoaded()) {
    window.IS24.TEALIUM.tracking.overwriteContextData({
      pag_evt_context: null,
    });
  }
}

export default {
  report,
  reportData,
  cmpTrackingData,
  cmpTrackingDataQueryString,
  reportPageView,
  reportPageViewWithGeoData,
  overwriteTrackingEventContext,
  revertTrackingEventContext,
};
