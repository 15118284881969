import React from 'react';
import { connect } from "react-redux";
import PriceTable from '../../../../components/PriceTable/PriceTable';
import styles from './PriceTableWidget.less';
import { getPriceTableData } from "../../../../selectors/selectors";
import NoDataWidget from "../../../../components/Utils/Widget/NoDataWidget/NoDataWidget";
import { ALIGN } from "../../../../components/Utils/Widget/Title/NewWidgetTitle";

const PriceTableWidget = ({ priceTableData }) => {
  const widgetTitle = "Immobilienpreise in den größten Städten Deutschlands"
  if(!priceTableData) {
    return <NoDataWidget text={widgetTitle} align={ALIGN.center} />
  }

  return (
    <div className={styles.priceTableWidget}>
      <PriceTable
        pageTitle="propertybook/index"
        stepCount={10}
        headline={widgetTitle}
        columnHeadline="Stadt"
        entries={priceTableData.map((p) => ({
          link: p.link,
          locationName: p.name,
          values: p.prices || {},
        }))}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  priceTableData: getPriceTableData(state),
});

export default connect(mapStateToProps)(PriceTableWidget);
