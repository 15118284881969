import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PRIMARY_BLUE } from '../../../utils/colorPalette';
import styles from './Circles.less';

export default class ChartCircles extends Component {
  render() {
    const { radius, color, data, className, opacity, updateSelected, hasShadow } = this.props;

    const { xScale: x, yScale: y } = this.props.scales;

    return (
      <g>
        {data.map((d) => (
          <circle
            className={`${className || ''} ${hasShadow ? styles.shadow : ''}`}
            r={radius}
            cx={x(d.x)}
            cy={y(d.y)}
            fill={color || PRIMARY_BLUE()}
            opacity={opacity || 1}
            key={`dot-${d.x}-${d.y}`}
            onMouseEnter={() => updateSelected(d.x)}
          />
        ))}
      </g>
    );
  }
}

ChartCircles.propTypes = {
  updateSelected: PropTypes.func,
  hasShadow: PropTypes.bool,
};

ChartCircles.defaultProps = {
  updateSelected: () => {},
  hasShadow: false,
};
