import React from 'react';
import * as PropTypes from 'prop-types';
import { getCurrentPointInTime } from '../PriceHistory/Detailed/utils';
import PriceHistoryMobileDetailedChart from '../PriceHistory/Mobile/Detailed/Chart/NewChart';
import StatsBoxMobile from '../PriceHistory/StatsBox/Mobile/StatsBoxMobile';
import HomeOwnerWorldPromoMobile from '../HomeOwnerWorldPromo/HomeOwnerWorldPromoMobile';
import HomeOwnerWorldPromoChartOverlay from '../HomeOwnerWorldPromo/HomeOwnerWorldPromoChartOverlay';
import PriceTrendChartInstructionMobile from './PriceTrendChartInstructionMobile';
import styles from './PriceTrendChartMobile.less';
import { GRAPH_INTERACTION, PROPERTY_BOOK_V2 } from "../../tracking/trackingNaming";
import PriceTrendChartDescription from './PriceTrendChartDescription';
import tracking from "../../tracking/tracking";

export default class PriceTrendChartMobile extends React.Component {
  state = { selected: null };

  constructor(props) {
    super(props);
    const { nativeDataMobile: nativeData, parentDataMobile: parentData } = props;
    this.state = { selected: getCurrentPointInTime({ nativeData, parentData }) };
    this.graphInteractions = 0;
  }

  updateSelected = (xAxisValue) => {
    if (xAxisValue !== this.props.selected) {
      this.graphInteractions++;
      const quarter = this.props.nativeDataMobile && this.props.nativeDataMobile.length > 0 ? this.props.nativeDataMobile[0].quarter : this.props.parentDataMobile[0].quarter
      const quarterAsDecimal = (quarter / 4) - 0.25;
      const newXAxisValue = xAxisValue + quarterAsDecimal
      this.setState({ selected: newXAxisValue });
      this.trackGraphInteraction();
    }
  };

  trackGraphInteraction = () => {
    if (this.graphInteractions === 3) {
      tracking.report(GRAPH_INTERACTION)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.commercializationType !== prevProps.commercializationType) {
      const { nativeDataMobile: nativeData, parentDataMobile: parentData } = this.props;
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ selected: getCurrentPointInTime({ nativeData, parentData }) });
    }
  }

  displayWithTooltip = () => (
    <>
      <span className="price-chart-title font-bold font-h5">Preisentwicklung</span>
      <span className="padding-top-xs float-right">
        <div className="tooltip-container">
          <div
            id="new-priceTrend-tooltip"
            className="tooltip-target font-xl is24-icon-info1"
            style={{ paddingLeft: '10px' }}
          />
          <div className="tooltip positioning-left" style={{ width: '272px' }}>
            <div id="new-priceTrend-tooltip-info">
              <ul style={{ fontSize: '14px', color: '#E0E0E0' }}>
                Information zur Bestimmung von Durchschnittspreisen
              </ul>
              <br />
              <ul style={{ fontSize: '12px', color: '#E0E0E0' }}>Verfahren für Wertbestimmung</ul>
              <br />
              <ul style={{ fontSize: '12px', color: '#E0E0E0' }}>
                Um Ihnen eine valide Wertberechnung Ihrer Immobilie zu liefern, haben wir unser Verfahren zusammen mit
                dem RWI Leibniz-Institut für Wirtschaftsforschung entwickelt und zertifizieren lassen. Unser
                Wertbestimmungsverfahren orientiert sich an dem am häufigsten verwendeten Verfahren aus dem Regelwerk
                der Immobilienpreisberechnung (ImmoWertV): dem Vergleichswertverfahren.
              </ul>
            </div>
          </div>
        </div>
      </span>
    </>
  );

  render() {
    const {
      nativeDataMobile: nativeData,
      parentDataMobile: parentData,
      realEstateType,
      commercializationType,
      hideHeader,
      hideSeparator,
      showMpPromoDialog,
      futureTrend,
      trackingAction,
    } = this.props;
    const { selected } = this.state;
    const { statsBoxDataQ } = this.props.getStatsBoxDataMobile(selected);
    const hrStyle = {
      height: '1px',
      backgroundColor: '#E0E0E0',
      border: 'none',
    };
    const currentQuarter = nativeData && nativeData.length > 0 ? nativeData[0].quarter : parentData[0].quarter
    return (
      <>
        {hideHeader ? (
          !hideSeparator && (
            <div className={`grid-item one-whole ${styles.negativeMarginTop}`}>
              <hr style={hrStyle} />
            </div>
          )
        ) : (
          <>
            <div className="padding-horizontal-l padding-top-l">
              <div className="grid-item">{this.displayWithTooltip()}</div>
              <div className="grid-item one-whole padding-vertical-s">
                <hr style={hrStyle} />
              </div>
              <div className="padding-bottom-xl">
                <PriceTrendChartDescription />
              </div>
            </div>
          </>
        )}
        <div className={`${hideHeader ? '' : 'padding-horizontal-l'} padding-bottom-xl`}>
          {statsBoxDataQ.length === 0 ? (
            <HomeOwnerWorldPromoMobile trackingAction={trackingAction} />
          ) : (
            <>
              <h3 style={{ fontSize: '21px' }}>{`Q${currentQuarter} ${Math.floor(selected)}`}</h3>
              <StatsBoxMobile statsData={statsBoxDataQ} realEstateType={realEstateType} futureTrend />
            </>
          )}
        </div>
        <div className={`${hideHeader ? '' : 'padding-horizontal-l'} absolute-reference`}>
          <PriceHistoryMobileDetailedChart
            selected={Math.floor(selected)}
            nativeData={nativeData}
            parentData={parentData}
            updateSelected={this.updateSelected}
            commercializationType={commercializationType}
            futureTrend={futureTrend}
            showMpPromoDialog={showMpPromoDialog}
            showGridLines={false}
            showMarker={false}
          />
          {showMpPromoDialog && <HomeOwnerWorldPromoChartOverlay trackingAction={trackingAction} />}
        </div>
        <div className={`${hideHeader ? '' : 'padding-horizontal-l'}`}>
          <PriceTrendChartInstructionMobile />
        </div>
      </>
    );
  }
}

PriceTrendChartMobile.defaultProps = {
  showMpPromoDialog: false,
  hideSeparator: false,
  futureTrend: false,
  trackingAction: PROPERTY_BOOK_V2,
};

PriceTrendChartMobile.propTypes = {
  showMpPromoDialog: PropTypes.bool,
  realEstateType: PropTypes.string,
  commercializationType: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool.isRequired,
  hideSeparator: PropTypes.bool,
  futureTrend: PropTypes.bool,
  trackingAction: PropTypes.string,
};
