import { useEffect, useRef, useState } from 'react';

export function useOnVisible() {
  const [isVisible, setIsVisible] = useState(false);
  const element = useRef();
  useEffect(() => {
    const updateVisibility = (entries) => setIsVisible(entries.some((e) => e.isIntersecting));
    const observer = new IntersectionObserver(updateVisibility, {
      threshold: 0.5,
    });
    const current = element.current
    observer.observe(current);

    return () => {
      if (current) {
        observer.unobserve(current);
      }

      observer.disconnect();
    };
  }, [element]);

  return {
    isElementVisible: isVisible,
    observableElement: element,
  };
}
