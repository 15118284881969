import React from 'react';
import { useSelector } from "react-redux";
import styles from './HomeOwnerWorldPromo.less';
import tracking from '../../tracking/tracking.js';
import { FUTURE_PRICE_TREND_LINK_MY_PROPERTY } from '../../tracking/trackingNaming.js';
import { getHierarchyLevel } from "../../selectors/selectors";
import {
  ATLAS_HOMEPAGE_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL,
  PRICE_DEVELOPMENT_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL,
  PRICE_OVERVIEW_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL,
} from '../MyPropertyRelocation/urls';

export const HomeOwnerWorldPromo = ({ trackingAction }) => {
  const hierarchyLevel = useSelector(getHierarchyLevel);
  const handleButtonClick = (event, hierarchyLevel) => {
    tracking.report(FUTURE_PRICE_TREND_LINK_MY_PROPERTY, trackingAction);
    event.preventDefault();
    if (window.location.hash === '#/preisentwicklung') {
      window.location = PRICE_DEVELOPMENT_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL;
    }
    else if (window.location.pathname === '/'){
      window.location = ATLAS_HOMEPAGE_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL;
    }
    else {
      window.location = PRICE_OVERVIEW_GRAPH_TO_HOMEOWNER_LANDINGPAGE_URL;
    }
  };

  return (
    <div
      data-identifier="my-property-promo"
      className={`grid border padding-vertical-s padding-horizontal-m ${styles.myPropertyPromoContainer}`}
    >
      <div className="grid-item one-whole">
        <p className={styles.headline}>
          <span className="is24-icon-lock-unlock margin-right-s font-confirm" />
          Preisprognose freischalten
        </p>
      </div>
      <div className="grid-item one-whole margin-top-s">
        <p className={styles.text}>Jetzt Preisprognose für vier Quartale der eigenen Immobilie anzeigen lassen.</p>
      </div>
      <div className="grid-item one-whole">
        <button
          id="my-property-promo-button"
          type="button"
          className={`link-text margin-top-s padding-none ${styles.text}`}
          onClick={(event) => handleButtonClick(event, hierarchyLevel)}
        >
          Zu &quot;Meine Immobilien&quot;
        </button>
      </div>
    </div>
  );
};

export default HomeOwnerWorldPromo;
