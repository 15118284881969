export const SET_SELECTED_POINT_IN_TIME = 'SET_SELECTED_POINT_IN_TIME';
export const CLOSE_STATS_BOX = 'CLOSE_STATS_BOX';

const setSelectedPointInTime = (pointInTime) => ({
  type: SET_SELECTED_POINT_IN_TIME,
  value: pointInTime,
});

const closeStatsBox = () => ({
  type: CLOSE_STATS_BOX,
  value: false,
});

const actionCreators = {
  setSelectedPointInTime,
  closeStatsBox,
};

export default actionCreators;
