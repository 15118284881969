import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../../actions';
import tracking from '../../../../tracking/tracking.js';
import addressSearchConfig from '../../../../components/AddressSearch/config';
import { getSuggestionResultLength, homePageReportLabels } from '../../../../utils/suggestionsUtils';
import { setSearchParams } from '../../../../utils/urlUtils.js';
import { getRealEstateType, getUserIntent } from '../../../../selectors/selectors.js';
import styles from './NewHomepageAddressSearchResults.less';
import { PROPERTY_BOOK } from "../../../../tracking/trackingNaming";

const NewHomepageAddressSearchResults = (props) => {
  const handleClick = (url, label) => (event) => {
    props.selectSuggestion(event.target.title, url, label);
    tracking.report(label, PROPERTY_BOOK);
    event.preventDefault();
    props.cancelSearch();
  };

  const generateLink = (url) => {
    const q0 = window.location.search;
    const q1 = setSearchParams('searchQuery', encodeURIComponent(props.suggestionQuery), q0);
    const newUrl = url.replace(/\?.*/, '').replace(/#.*/, '');
    return `${newUrl}${q1}`;
  };

  const renderSuggestionLink = (suggestionResult, index, selectedSuggestionIndex, reportLabel) => {
    const address = suggestionResult.address;
    const link = generateLink(suggestionResult.url);
    const suggestionText = address
      ? `${address.street} ${address.houseNumber}${
        address.houseNumberExtension ? ` ${address.houseNumberExtension}` : ''
      }, ${address.zipcode} ${address.city}`
      : suggestionResult.name
        ? suggestionResult.name
        : `${suggestionResult.name}`
          ? suggestionResult.name
          : `${suggestionResult.name}`
            ? suggestionResult.name
            : '';
    const selectedClass = index === selectedSuggestionIndex ? styles.selected : '';

    return (
      <div className={styles.suggestionResult} key={link}>
        <a
          title={suggestionText}
          href={link}
          className={selectedClass}
          onMouseDown={handleClick(link, reportLabel)}
        >
          {suggestionText}
        </a>
      </div>
    );
  };

  const renderSuggestionResultItems = (configItem, offset) => {
    const selectedIndex = props.selectedSuggestionIndex % getSuggestionResultLength(props.suggestionResults);
    const values = props.suggestionResults[configItem];
    const config = addressSearchConfig[configItem];
    const reportLabel = `${homePageReportLabels[configItem]}`;
    return (
      <div className={styles.suggestionResultBlock} key={configItem}>
        <span className={styles.title}>{config.title}</span>
        {values.map((value, index) => renderSuggestionLink(value, index + offset, selectedIndex, reportLabel))}
      </div>
    );
  };


  let offset = 0;
  const suggestionResults = props.suggestionResults;

  if (!suggestionResults || getSuggestionResultLength(suggestionResults) <= 0) {
    return <div />;
  }

  return (
    <div data-qa="suggestions" className={`grid one-whole ${styles.results}`}>
      {Object.keys(addressSearchConfig).map((configItem) => {
        if (suggestionResults[configItem].length > 0) {
          const suggestionResultHtml = renderSuggestionResultItems(configItem, offset);
          offset += (suggestionResults[configItem] || []).length;
          return suggestionResultHtml;
        }
        return '';
      })}
    </div>
  );
}

const mapDispatchToProps = {
  cancelSearch: () => actions.cancelAddressSearch(),
};

const mapStateToProps = (state) => ({
  ...state.indexpage,
  realEstateType: getRealEstateType(state),
  userIntent: getUserIntent(state),
});

NewHomepageAddressSearchResults.propTypes = {
  userIntent: PropTypes.string,
  cancelSearch: PropTypes.func,
  selectedSuggestionIndex: PropTypes.number,
  selectSuggestion: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHomepageAddressSearchResults);
