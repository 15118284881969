import React from 'react';
import PropTypes from 'prop-types';

import Chart from '../../../../Chart/Chart.jsx';
import {
  svgDimensions,
  svgMargins,
  svgMarginsMobile,
  ticksConfigWidgetDesktop,
  ticksConfigWidgetMobile,
} from '../Newconfig.js';
import { getCurrentPointInTime, getCurrentPointInTimeMobile } from '../../../Detailed/utils';
import { PRIMARY_BLUE, PRIMARY_GREEN } from '../../../../../utils/colorPalette';
import styles from './PriceHistoryMobileDetailedChart.less';

export default class PriceHistoryMobileDetailedChart extends React.Component {
  getChartConfig(quarter, years, prices, nativeData, parentData, selectedQ) {
    const {
      xOrient,
      svgMarginsOld,
      svgMarginsNew,
      ticksConfigWidgetOld,
      ticksConfigWidgetNew,
      showMarker,
      updateSelected,
      commercializationType,
      futureTrend,
      showMpPromoDialog,
      showGridLines,
      newDesignGraph,
    } = this.props;
    const latestPointInTime = years[years.length - 1];
    const firstPointInTime = years[0];
    const currentPointInTime = Number(getCurrentPointInTimeMobile({ nativeData, parentData }));
    const ticksConfig = newDesignGraph ? ticksConfigWidgetNew : ticksConfigWidgetOld;
    const margins = newDesignGraph ? svgMarginsNew : svgMarginsOld;
    return {
      newDesignGraph,
      futureTrend,
      showMpPromoDialog,
      showGridLines,
      firstPointInTime,
      currentPointInTime,
      latestPointInTime,
      commercializationType,
      showMarker,
      updateSelected,
      leftOffset: margins.left, // value measured to the start of the first point on x axis from the left of the svg
      rightOffset: margins.right, // value measured to the end of the last point on x axis from the right of the svg
      sectionsPerYear: 1, // number of sections in a year
      svgDimensions,
      margins,
      dx: {
        min: Math.min(...years),
        max: Math.max(...years),
        values: {
          major: years,
          minor: years,
        },
        format: {
          major: () => `Q${quarter}`,
          minor: (d) => `${d}`,
        },
        tickSize: ticksConfig.dx.tickSize,
        tickLengthOffset: ticksConfig.dx.tickLengthOffset,
        orient: xOrient,
        selected: selectedQ || getCurrentPointInTime({ nativeData, parentData }),
      },
      dy: {
        min: Math.min(...prices),
        max: Math.max(...prices),
        values: {
          major: [],
          minor: [],
        },
        format: {
          major: () => '',
          minor: () => '',
        },
        tickSize: ticksConfig.dy.tickSize,
        ticksNumber: ticksConfig.dy.ticksNumber,
        tickLengthOffset: ticksConfig.dy.tickLengthOffset,
        orient: 'left',
      },
      data: [
        {
          values: parentData.map((p) => ({ x: Number(p.year), y: p.price })),
          color: PRIMARY_GREEN(),
        },
        {
          values: nativeData.map((p) => ({ x: Number(p.year), y: p.price })),
          color: PRIMARY_BLUE(),
        },
      ],
      isMobile:true
    };
  }

  render() {
    const { nativeData, parentData, selected, showMpPromoDialog } = this.props;

    const prices = nativeData.concat(parentData).map((p) => p.price);
    const years = [
      ...new Set((nativeData && nativeData.length > 0 ? nativeData : parentData).map((p) => Number(p.year))),
    ];

    const quarter = (nativeData && nativeData.length > 0 ? nativeData : parentData).map((p) => Number(p.quarter))[0];

    if (showMpPromoDialog) {
      years.push(years[years.length - 1] + 1);
    }
    const chartProps = this.getChartConfig(quarter, years, prices, nativeData, parentData, selected);

    return (
      <div className={styles.priceHistoryDetailedChartContainer}>
        <Chart {...chartProps} />
      </div>
    );
  }
}

PriceHistoryMobileDetailedChart.defaultProps = {
  selected: null,
  xOrient: 'top',
  svgMarginsOld: svgMargins,
  svgMarginsNew: svgMarginsMobile,
  ticksConfigWidgetOld: ticksConfigWidgetDesktop,
  ticksConfigWidgetNew: ticksConfigWidgetMobile,
  showMarker: true,
  newDesignGraph: true,
};

PriceHistoryMobileDetailedChart.propType = {
  futureTrend: PropTypes.bool,
  showMpPromoDialog: PropTypes.bool,
  nativeData: PropTypes.array.isRequired,
  parentData: PropTypes.array.isRequired,
  selected: PropTypes.number,
  xOrient: PropTypes.string,
  margins: PropTypes.object,
  ticksConfigWidgetDesktop: PropTypes.object,
  ticksConfigWidgetMobile: PropTypes.object,
  showMarker: PropTypes.bool,
  showGridLines: PropTypes.bool.isRequired,
  newDesignGraph: PropTypes.bool,
};
