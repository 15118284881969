import React from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import SearchHeader from './components/AddressSearch/SearchHeader';
import HomepageMain from './components/HomepageMain';
import actions from './actions';
import RouterWrapper from "../../components/AppProvider/RouterWrapper";

export class App extends React.Component {
  resizeListener = () => {
    return () => this.props.screenResize(this.getViewportWidth());
  }

  scrollListener = () => {
    return () => this.props.scrollY(window.pageYOffset || document.documentElement.scrollTop);
  }

  getViewportWidth = () => {
    if (window.innerWidth !== undefined) {
      return window.innerWidth;
    }

    return Math.max(document.body.clientWidth, document.documentElement.clientWidth);
  }

  componentDidMount() {
    document.getElementById('footer').classList.remove('hide'); // Avoid jumping footer by first hiding & then unhiding

    this.resizeListener();
    window.addEventListener('resize', throttle(this.resizeListener(), 128));
    window.addEventListener('scroll', throttle(this.scrollListener(), 128));
  }

  render() {
    return (
      <RouterWrapper>
        <SearchHeader />
        <div className="page-wrapper viewport">
          <div className="content-wrapper">
            <HomepageMain />
          </div>
        </div>
      </RouterWrapper>
    );
  }
}

const mapDispatchToProps = {
  screenResize: (width) => actions.screenResize(width),
  scrollY: (y) => actions.scrollY(y),
};

export default connect((state) => state, mapDispatchToProps)(App);
