import React from 'react';
import { connect, useSelector } from "react-redux";
import styles from './PriceTrendChartWidget.less';
import PriceHistoryWidgetWithRentPrice
  from "../../../../components/PriceHistory/Widget/PriceHistoryWidgetWithRentPrice";
import { getPricesForPriceHistoryWidget, getRealEstateType } from "../../../../selectors/selectors";
import { PROPERTY_BOOK } from "../../../../tracking/trackingNaming";

import NoDataWidget from "../../../../components/Utils/Widget/NoDataWidget/NoDataWidget";
import { ALIGN } from "../../../../components/Utils/Widget/Title/NewWidgetTitle";

const PriceTrendChartWidget = ({ prices }) => {
  const realEstateType = useSelector(getRealEstateType);
  const widgetTitle = 'So entwickeln sich die Immobilienpreise in Deutschland'
  if(!prices) {
    return <NoDataWidget text={widgetTitle} align={ALIGN.center} />
  }

  return (
    <div className={styles.priceTrendChartWidget}>
      <PriceHistoryWidgetWithRentPrice
        isHomePage={true}
        address={'Deutschland'}
        title={widgetTitle}
        realEstateType={realEstateType}
        trackingAction={PROPERTY_BOOK}
        prices={prices}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  prices: getPricesForPriceHistoryWidget(state),
});

export default connect(mapStateToProps)(PriceTrendChartWidget);
