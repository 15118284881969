import debounce from 'lodash/debounce';

export const CANCEL_ADDRESS_SEARCH = 'CANCEL_ADDRESS_SEARCH';
export const FOCUS_ADDRESS_SEARCH = 'FOCUS_ADDRESS_SEARCH';

export const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS';
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS_SUCCESS';
export const FETCH_SUGGESTIONS_FAILURE = 'FETCH_SUGGESTIONS_FAILURE';

export const SUGGESTIONS_ARROW_DOWN_EVENT = 'SUGGESTIONS_ARROW_DOWN_EVENT';
export const SUGGESTIONS_ARROW_UP_EVENT = 'SUGGESTIONS_ARROW_UP_EVENT';
export const RESET_SELECTED_SUGGESTION_INDEX = 'RESET_SELECTED_SUGGESTION_INDEX';

const fetchSuggestionsFromBackend= debounce((url, urlSearchParams, dispatch) => {
  return fetch(`${url}?${urlSearchParams}`)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      const status = `http status: ${response.status}`;
      return Promise.reject(status);
    })
    .then((json) => dispatch(fetchSuggestionsSuccess(json)))
    .catch((error) => dispatch(fetchSuggestionsFailure(error)));
}, 500);


const fetchSuggestions = (suggestionQuery) => ({
  type: FETCH_SUGGESTIONS,
  suggestionQuery,
});

const fetchSuggestionsSuccess = (suggestionResult) => ({
  type: FETCH_SUGGESTIONS_SUCCESS,
  suggestionsResult: suggestionResult,
});

const fetchSuggestionsFailure = (error) => ({
  type: FETCH_SUGGESTIONS_FAILURE,
  error,
});

const resetSelectedSuggestionIndex = () => ({
  type: RESET_SELECTED_SUGGESTION_INDEX,
});

const actionCreators = {
  fetchSuggestions,
  fetchSuggestionsSuccess,
  fetchSuggestionsFailure,
  resetSelectedSuggestionIndex,

  suggestionsArrowDownEvent: () => ({
    type: SUGGESTIONS_ARROW_DOWN_EVENT,
  }),

  suggestionsArrowUpEvent: () => ({
    type: SUGGESTIONS_ARROW_UP_EVENT,
  }),

  cancelAddressSearch: () => ({
    type: CANCEL_ADDRESS_SEARCH,
  }),

  focusAddressSearchInput: () => ({
    type: FOCUS_ADDRESS_SEARCH,
  }),

  updateSuggestionQuery: (suggestionQuery, location) => (dispatch) => {
    dispatch(fetchSuggestions(suggestionQuery));
    const urlSearchParams = new URLSearchParams({
      searchString: suggestionQuery,
      ...location,
    });
    const url = '/suggestions';

    return fetchSuggestionsFromBackend(url, urlSearchParams, dispatch);
  },
};

export default actionCreators;
