import React from 'react';
import styles from './MyPropertyPromotion.less';
import MyPropertyTeaser from "../../../../components/HomeOwnerWorldTeaser/HomeOwnerWorldNewTeaser";
import { PROPERTY_BOOK } from "../../../../tracking/trackingNaming";
import {
   ATLAS_HOMEPAGE_PROMO_TO_HOMEOWNER_LANDINGPAGE_URL
} from '../../../../components/MyPropertyRelocation/urls';

const MyPropertyPromotion = () => {
  const myPropertyPromotionTitle = `Sind Sie Eigentümer?`;
  const myPropertyPromotionDescription = `Kostenlos Immobilie in "Meine Immobilien" anlegen, Wunschpreis festlegen und Immobilienwert sowie aktuelle Nachfrage verfolgen.`;

  return (
    <div className={styles.myPropertyPromotionWidget}>
      <MyPropertyTeaser
        onButtonClicked={() => window.location = ATLAS_HOMEPAGE_PROMO_TO_HOMEOWNER_LANDINGPAGE_URL }
        title={myPropertyPromotionTitle}
        description={myPropertyPromotionDescription}
        isHomePage={true}
        trackingAction={PROPERTY_BOOK}
      />
    </div>
  )
}

export default MyPropertyPromotion;
