import React from 'react';
import Axis from '../Axis/Axis.jsx';
import { translation } from '../axisConfig.js';

const Axes = ({ scales, margins, svgDimensions, ticks, ticksNumber, orient, axisLabels, isMobile }) => {
  const { x: xTicksNumber, y: yTicksNumber } = ticksNumber || {};
  const { x: xOrient, y: yOrient } = orient || { x: 'bottom', y: 'left' };
  const translate = translation(svgDimensions, margins);

  const xProps = {
    orient: xOrient,
    scale: scales.xScale,
    translate: translate[xOrient],
    tickSize: isNaN(ticks.x.tickSize) ? 30 : ticks.x.tickSize,
    majorTickPadding: isNaN(ticks.x.majorTickPadding) ? isMobile ? 28 : 8 : ticks.x.majorTickPadding,
    minorTickPadding: isNaN(ticks.x.minorTickPadding) ? isMobile ? 12 : 6 : ticks.x.minorTickPadding,
    ticks: ticks.x,
    ticksNumber: xTicksNumber,
    axisLabel: axisLabels && axisLabels.xLabel ? axisLabels.xLabel : false,
  };

  const yProps = {
    orient: yOrient,
    scale: scales.yScale,
    translate: translate[yOrient],
    tickSize: isNaN(ticks.y.tickSize) ? 30 : ticks.y.tickSize,
    majorTickPadding: isNaN(ticks.y.majorTickPadding) ? 4 : ticks.y.majorTickPadding,
    minorTickPadding: isNaN(ticks.y.minorTickPadding) ? 0 : ticks.y.minorTickPadding,
    ticks: ticks.y,
    ticksNumber: yTicksNumber,
    axisLabel: axisLabels && axisLabels.yLabel ? axisLabels.yLabel : false,
  };

  return (
    <g>
      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  );
};

export default Axes;
