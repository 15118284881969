export function formatDE(price, numberOfDecimals = 2) {
  // de-DE does not work on most Node.js environments
  const result = price.toLocaleString('en-US', {
    minimumFractionDigits: numberOfDecimals,
    maximumFractionDigits: numberOfDecimals,
  });
  // Hack to swap ',' and '.' around
  return result.replace(/,/gi, '_').replace(/\./gi, ',').replace(/_/gi, '.');
}

// 23.20 becomes 23,2
export function formatDEIgnoreTailingZeroDecimals(price, numberOfDecimals = 2) {
  // de-DE does not work on most Node.js environments
  const result = price.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: numberOfDecimals,
  });
  // Hack to swap ',' and '.' around
  return result.replace(/,/gi, '_').replace(/\./gi, ',').replace(/_/gi, '.');
}

export function calculateDeltaInPercent(from, to) {
  return from !== 0 ? Math.round(((to - from) / from) * 100) : 0;
}

export const clearDimensionFromStringNumber = (stringNumber) =>
  stringNumber
    .replace(/[.€/m²]/g, '')
    .replace(/[,]/g, '.')
    .trim();
