import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Axes from './Axes/Axes.jsx';
import Grid from './Grid/Grid.jsx';
import Line from './Line/Line.jsx';
import Circles from './Circles/Circles.jsx';
import ResponsiveWrapper from '../Utils/ResponsiveWrapper/ResponsiveWrapper.jsx';
import { DesktopAndTablet } from '../Utils/MediaComponents/MediaComponents.tsx';

import { createScale } from './utils.js';
import config from './heightConfig.js';
import Rectangle from './Rectangle/Rectangle.jsx';

export const getMarkerData = (scales, selected, offsets) => {
  const { xScale, yScale } = scales;
  const radius = 6;
  const bottomShift = 32;

  const [xMin, xMax] = xScale.range();
  const [yMin] = yScale.range();
  const rSelected = xScale(selected);

  const y = yScale.invert(yMin + bottomShift);

  return [
    { x: xScale.invert(xMin - offsets.left), y },
    { x: xScale.invert(rSelected - radius), y },
    { x: selected, y: yScale.invert(yMin + bottomShift - 10) },
    { x: xScale.invert(rSelected + radius), y },
    { x: xScale.invert(xMax + offsets.right), y },
  ];
};

export class Chart extends Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
  }

  render() {
    const {
      margins,
      svgDimensions: dimensions,
      dx,
      dy,
      leftOffset,
      rightOffset,
      sectionsPerYear,
      updateSelected,
      parentWidth,
      windowHeight,
      data,
      showMarker,
      futureTrend,
      showMpPromoDialog,
      showGridLines,
      firstPointInTime,
      currentPointInTime,
      latestPointInTime,
      newDesignGraph,
      isMobile
    } = this.props;

    // not smaller than min, not bigger than max, rest range take from (windowHeight - chartTopOffset)
    const getResponsiveHeight = () => {
      const responsiveHeight = windowHeight - config.chartTopOffset;
      if (responsiveHeight < config.minHeight) {
        return config.minHeight;
      }
      if (responsiveHeight > dimensions.height) {
        return dimensions.height;
      }
      return responsiveHeight;
    };

    const svgDimensions = {
      width: Math.max(parentWidth || 0, dimensions.width),
      height: Math.min(320, getResponsiveHeight()),
    };

    const xScale = createScale({
      values: dx.values.major,
      domain: [dx.min, dx.max],
      ticksNumber: dx.ticksNumber,
      orient: 'bottom',
      svgDimensions,
      margins,
    });

    const yScale = createScale({
      values: dy.values.major,
      domain: [dy.min, dy.max],
      ticksNumber: dy.ticksNumber,
      orient: 'left',
      svgDimensions,
      margins,
    });

    const axesTicks = {
      x: {
        values: dx.values,
        format: dx.format,
        selected: dx.selected,
        tickSize: dx.tickSize,
      },
      y: {
        values: dy.values,
        format: dy.format,
        tickSize: dy.tickSize,
      },
    };

    const ticksX = () => {
      const dataPoints = data[0].values.length === 0 ? data[1].values : data[0].values;
      // Sort in descending order
      dataPoints.sort((a, b) => b.x - a.x);
      const lastDataPoint = dataPoints[0].x;

      // If last data point is a quarter other than Q1, then add an additional tick for this data point
      if (lastDataPoint % 1 !== 0) {
        return [lastDataPoint, ...dx.values.major];
      }
      return dx.values.major;
    };

    const getWholeOffsetLeft = (nodeParam) => {
      let node = nodeParam;
      let offsetLeft = 0;
      while (node && node !== document.body) {
        offsetLeft += node.offsetLeft;
        node = node.offsetParent;
      }
      return offsetLeft;
    };

    const selectQuarterWithCursor = (xCoordinate) => {
      if (!updateSelected) {
        return;
      }
      const svgNode = this.svgRef.current;
      const percentageValue =
        (xCoordinate - getWholeOffsetLeft(svgNode) - leftOffset) / (svgDimensions.width - leftOffset - rightOffset);
      const xAxisValue = dx.min + (dx.max - dx.min) * percentageValue;
      if (showMpPromoDialog && xAxisValue > currentPointInTime) {
        updateSelected(Math.ceil(xAxisValue));
        return;
      }
      const roundedXAxisValue = Math.round(xAxisValue * sectionsPerYear) / sectionsPerYear;
      if (roundedXAxisValue >= dx.min && roundedXAxisValue <= dx.max) {
        updateSelected(roundedXAxisValue);
      }
    };

    if (newDesignGraph) {
      const dashStrokeWidth = 8;
      return (
        <div
          ref={this.svgRef}
          className="grid absolute-reference"
          onMouseMove={(event) => {
            selectQuarterWithCursor(event.clientX);
          }}
          onTouchMove={(event) => {
            selectQuarterWithCursor(event.changedTouches[0].clientX);
          }}
        >
          <svg id="priceChartSvg" data-qa="newDesignGraph" width={svgDimensions.width} height={svgDimensions.height}>
            <Grid
              scales={{ xScale, yScale }}
              margins={margins}
              svgDimensions={svgDimensions}
              ticks={{ x: ticksX(), y: dy.values.major }}
              selected={showMpPromoDialog && dx.selected > currentPointInTime ? null : dx.selected}
              key="chart-grid"
              ticksNumber={{ x: dx.ticksNumber, y: dy.ticksNumber }}
              tickLengthOffset={{ x: dx.tickLengthOffset, y: dy.tickLengthOffset }}
              orient={{ x: dx.orient, y: dy.orient }}
              currentPointInTime={currentPointInTime}
              showGridLines={showGridLines}
            />
            <Rectangle
              firstPointInTime={firstPointInTime}
              currentPointInTime={currentPointInTime}
              latestPointInTime={latestPointInTime}
              showMpPromoDialog={showMpPromoDialog}
              futureTrend={futureTrend}
              wholeHeight={svgDimensions.height}
              wholeWidth={svgDimensions.width}
              isMobile={isMobile}
            />
            <Axes
              scales={{ xScale, yScale }}
              margins={margins}
              svgDimensions={svgDimensions}
              ticks={axesTicks}
              key="chart-axes"
              ticksNumber={{ x: dx.ticksNumber, y: dy.ticksNumber }}
              orient={{ x: dx.orient, y: dy.orient }}
              axisLabels={{ xLabel: dx.axisLabel, yLabel: dy.axisLabel }}
              isMobile={isMobile}
            />

            {!showMpPromoDialog &&
              data.map(({ values, color }, index) => (
                <React.Fragment key={index}>
                  <Line
                    scales={{ xScale, yScale }}
                    data={values.filter((d) => d.x >= currentPointInTime)}
                    color={color}
                    showMpPromoDialog={showMpPromoDialog}
                    strokeDashArray={`${dashStrokeWidth} ${dashStrokeWidth}`}
                  />
                </React.Fragment>
              ))}

            {data.map(({ values, color }, index) => (
              <React.Fragment key={index}>
                <Line
                  scales={{ xScale, yScale }}
                  data={values.filter((d) => d.x <= currentPointInTime)}
                  color={color}
                  showMpPromoDialog={showMpPromoDialog}
                />
                {dx.selected && (
                  <SelectedCircles
                    scales={{ xScale, yScale }}
                    data={values.find((e) => e.x === dx.selected)}
                    color={color}
                  />
                )}
              </React.Fragment>
            ))}

            {showMarker && (
              <Line
                scales={{ xScale, yScale }}
                data={getMarkerData({ xScale, yScale }, dx.selected, { left: dy.tickSize, right: dy.tickLengthOffset })}
                color="#343434"
              />
            )}
          </svg>
        </div>
      );
    }
    return (
      <div className="grid">
        <svg id="priceChartSvg" data-qa="oldDesignGraph" width={svgDimensions.width} height={svgDimensions.height}>
          <Axes
            scales={{ xScale, yScale }}
            margins={margins}
            svgDimensions={svgDimensions}
            ticks={axesTicks}
            key="chart-axes"
            ticksNumber={{ x: dx.ticksNumber, y: dy.ticksNumber }}
            orient={{ x: dx.orient, y: dy.orient }}
            axisLabels={{ xLabel: dx.axisLabel, yLabel: dy.axisLabel }}
            isMobile={isMobile}
          />
          <Grid
            scales={{ xScale, yScale }}
            margins={margins}
            svgDimensions={svgDimensions}
            ticks={{ x: ticksX(), y: dy.values.major }}
            selected={dx.selected}
            key="chart-grid"
            ticksNumber={{ x: dx.ticksNumber, y: dy.ticksNumber }}
            tickLengthOffset={{ x: dx.tickLengthOffset, y: dy.tickLengthOffset }}
            orient={{ x: dx.orient, y: dy.orient }}
            showGridLines={showGridLines}
          />
          {data.map(({ values, color }, index) => (
            <React.Fragment key={index}>
              <Line scales={{ xScale, yScale }} data={values} color={color} />
              <Circles scales={{ xScale, yScale }} data={values} color="#FFF" radius={7.5} className="outerDot" />
              <Circles
                scales={{ xScale, yScale }}
                data={values}
                color={color}
                radius={6.5}
                className="dot"
                updateSelected={updateSelected}
              />
              {dx.selected && (
                <HighlightedCircles
                  scales={{ xScale, yScale }}
                  data={values.find((e) => e.x === dx.selected)}
                  color={color}
                />
              )}
              )
            </React.Fragment>
          ))}
          {showMarker && (
            <Line
              scales={{ xScale, yScale }}
              data={getMarkerData({ xScale, yScale }, dx.selected, { left: dy.tickSize, right: dy.tickLengthOffset })}
              color="#343434"
            />
          )}
        </svg>
      </div>
    );
  }
}

export const HighlightedCircles = ({ scales, data, color }) =>
  data ? (
    <>
      <DesktopAndTablet
        render={() => <Circles scales={scales} data={[data]} color={color} radius={21.5} opacity={0.1} />}
      />
      <Circles scales={scales} data={[data]} color={color} radius={11.5} />
      <Circles scales={scales} data={[data]} color="#FFF" radius={10.5} />
      <Circles scales={scales} data={[data]} color={color} radius={6.5} />
      <Circles scales={scales} data={[data]} color="#FFF" radius={3.5} />
    </>
  ) : (
    <div />
  );

export const SelectedCircles = ({ scales, data, color }) =>
  data ? (
    <>
      <Circles scales={scales} data={[data]} color="#FFF" radius={9.5} hasShadow />
      <Circles scales={scales} data={[data]} color={color} radius={6.5} hasShadow={false} />
    </>
  ) : (
    <div />
  );

const dataItemValue = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
});

const dataItem = PropTypes.shape({
  values: PropTypes.arrayOf(dataItemValue).isRequired,
  color: PropTypes.string,
});

Chart.defaultProps = {
  showMarker: false,
  futureTrend: false,
  showMpPromoDialog: false,
  commercializationType: 'BUY',
  newDesignGraph: true,
};

Chart.propTypes = {
  futureTrend: PropTypes.bool,
  showMpPromoDialog: PropTypes.bool,
  showGridLines: PropTypes.bool.isRequired,
  firstPointInTime: PropTypes.number,
  currentPointInTime: PropTypes.number,
  latestPointInTime: PropTypes.number,
  commercializationType: PropTypes.string,
  newDesignGraph: PropTypes.bool,
  showMarker: PropTypes.bool,
  updateSelected: PropTypes.func,
  leftOffset: PropTypes.number,
  rightOffset: PropTypes.number,
  sectionsPerYear: PropTypes.number,
  svgDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  margins: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
  dx: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    values: PropTypes.shape({
      major: PropTypes.arrayOf(PropTypes.number).isRequired,
      minor: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    format: PropTypes.shape({
      major: PropTypes.func.isRequired,
      minor: PropTypes.func.isRequired,
    }).isRequired,
    selected: PropTypes.number,
    ticksNumber: PropTypes.number,
    orient: PropTypes.string,
  }).isRequired,
  dy: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    values: PropTypes.shape({
      major: PropTypes.arrayOf(PropTypes.number).isRequired,
      minor: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    format: PropTypes.shape({
      major: PropTypes.func.isRequired,
      minor: PropTypes.func.isRequired,
    }).isRequired,
    ticksNumber: PropTypes.number,
    orient: PropTypes.string,
  }).isRequired,
  data: PropTypes.arrayOf(dataItem).isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default ResponsiveWrapper(Chart);
