import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import actions from '../actions';
import frontendActions from '../../../actions';
import tracking from '../../../tracking/tracking.js';
import {
  getAddress,
  getGeoHierarchy,
  getHash,
  getHierarchyLevel,
  getLocation,
  getRealEstateType,
  getSelectedSuggestionIndex,
  getSuggestionQuery,
  getSuggestionResults,
  getUserIntent,
  isMobileNavigationMenuOpen,
} from '../../../selectors/selectors.js';
import addressExtractor from '../../../utils/addressExtractor.js';
import urlGenerator from './UrlGenerator';
import AddressSearchResults from '../Results/AddressSearchResults';
import { getSuggestionResultLength } from '../../../utils/suggestionsUtils';
import { Position } from '../../../../backend/service/Position';
import SavingAddressSearchInput from './Input/SavingAddressSearchInput';
import State from "../../../store/state";


type SavingAddressSearchProps = {
  cancelSearch: () => AnyAction,
  suggestionResults?: object,
  saveLastSearchInput: (x: string, y: string) => AnyAction,
  onResultSelected: (x: string, y: string, z: string) => void,
  userIntent?: string,
  suggestionQuery?: string,
  realEstateType?: string,
  navigateToTab?: string,
  updateSuggestionQuery: (x: Position) => (y: string) => Promise<unknown> | undefined,
  suggestionsArrowDownEvent: () => AnyAction,
  suggestionsArrowUpEvent: () => AnyAction,
  selectRealEstateType?: (x: string) => never,
  selectUserIntent?: (x: string) => never,
  selectedSuggestionIndex?: number,
  onButtonOpen?: () => never,
  searchInput?: string,
  urlSelectedSuggestion?: string,
  suggestionSelected?: boolean,
  isMobileMenuOpen?: boolean,
  hierarchyLevel?: string,
  geoHierarchy?: object,
  address?: string,
  className?: string,
  resetSelection?: () => AnyAction,
  customResultStyle?: string,
  isHomePage?: boolean,
  handleOnFocus?: () => AnyAction,
  addressSelectedByUser?: string,
  location: Position,
};

export const SavingAddressSearch = (props: SavingAddressSearchProps): JSX.Element => {
  const containerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  const [inputField, setInputField] = useState<HTMLElement | null>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickSomewhere);
    return () => {
      document.removeEventListener('click', handleClickSomewhere);
    };
  });

  const isContained = (container: HTMLElement | null | undefined, node: Node | null | undefined): boolean => {
    if (node === container) {
      return true;
    }
    if (node?.nodeName.toUpperCase() === 'BODY') {
      return false;
    }
    return isContained(container, node?.parentNode);
  };

  const handleClickSomewhere = (event: MouseEvent) => {
    const suggestionResults = props.suggestionResults;

    if (getSuggestionResultLength(suggestionResults) <= 0) {
      return;
    }

    if (!isContained(containerRef.current, event.target as Node)) {
      props.cancelSearch();
    }
  };

  const handleClick = (url: string, addressShortName: string, reportLabel: string) => (event: MouseEvent) => {
    event.preventDefault();
    props.cancelSearch();
    props.saveLastSearchInput(addressShortName, url);
    props.onResultSelected(url, addressShortName, reportLabel);

    inputField && inputField.blur();
  };

  const generateLink = (url: string) =>
    urlGenerator.generate(
      url,
      window.location.search,
      props.suggestionQuery,
      props.realEstateType,
      props.userIntent,
      props.navigateToTab,
    );

  const handleEnter = (event: MouseEvent) => {
    event.preventDefault();
    const link: HTMLAnchorElement | null = document.querySelector('.selectedLink a');
    if (link) {
      handleClick(link.href, link.text, '')(event);
    }
  };

  const onResultsTouchMove = () => {
    inputField && inputField.blur();
  };

  const {
    className,
    suggestionQuery,
    suggestionResults,
    selectedSuggestionIndex,
    resetSelection,
    customResultStyle,
    isHomePage,
  } = props;

  const address = addressExtractor(props.hierarchyLevel, props.geoHierarchy, props.address);
  const autoFocus = false
  const savingAddressSearchInputProps = {
    updateSuggestionQuery: props.updateSuggestionQuery(props.location),
    suggestionsArrowDownEvent: props.suggestionsArrowDownEvent,
    suggestionsArrowUpEvent: props.suggestionsArrowUpEvent,
    cancelSearch: props.cancelSearch,
    suggestionQuery: suggestionQuery,
    handleEnter: handleEnter,
    handleOnFocus: props.handleOnFocus,
    address: props.addressSelectedByUser || address,
    callbackInputField: (inputField: HTMLElement) => {
      setInputField(inputField);
    },
    autoFocus: autoFocus,
    isHomePage: isHomePage,
  };

  return (
    <div ref={containerRef} key="address-search" className={`grid-item ${className}`}>
      <SavingAddressSearchInput {...savingAddressSearchInputProps} />
      <AddressSearchResults
        suggestionQuery={suggestionQuery}
        suggestionResults={suggestionResults}
        selectedIndex={selectedSuggestionIndex}
        handleClick={handleClick}
        resetSelection={resetSelection}
        generateLink={generateLink}
        className={customResultStyle}
        isHomePage={isHomePage}
        onTouchMove={() => {
          onResultsTouchMove();
        }}
      />
    </div>
  );
};

SavingAddressSearch.defaultProps = {
  className: '',
  customResultStyle: '',
  navigateToTab: '',
  addressSelectedByUser: '',
  onResultSelected: (url: string, addressShortName: string, reportLabel: string) => {
    tracking.report(reportLabel).then(() => {
      window.location.assign(url);
    });
  },
};

const mapStateToProps = (state: State) => ({
  suggestionQuery: getSuggestionQuery(state),
  suggestionResults: getSuggestionResults(state),
  selectedSuggestionIndex: getSelectedSuggestionIndex(state),
  realEstateType: getRealEstateType(state),
  userIntent: getUserIntent(state),
  location: getLocation(state),
  address: getAddress(state),
  geoHierarchy: getGeoHierarchy(state),
  hierarchyLevel: getHierarchyLevel(state),
  navigateToTab: getHash(state),
  isMobileMenuOpen: isMobileNavigationMenuOpen(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => ({
  cancelSearch: () => dispatch(actions.cancelAddressSearch()),
  updateSuggestionQuery: (location: Position) => (suggestionQuery: string) =>
    actions.updateSuggestionQuery(suggestionQuery, location)(dispatch),
  suggestionsArrowDownEvent: () => dispatch(actions.suggestionsArrowDownEvent()),
  suggestionsArrowUpEvent: () => dispatch(actions.suggestionsArrowUpEvent()),
  resetSelection: () => dispatch(actions.resetSelectedSuggestionIndex()),
  handleOnFocus: () => dispatch(actions.focusAddressSearchInput()),
  saveLastSearchInput: (inputValue: string, atlasUrlSelected: string) => dispatch(frontendActions.saveLastSearchInput(inputValue, atlasUrlSelected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavingAddressSearch);
