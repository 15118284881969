import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import actions from "../../actions";

type HashListenerAttribute = {
  setLocationHash: (hash: string) => void
} & JSX.ElementChildrenAttribute;

const HashListener = ({ children, setLocationHash }: HashListenerAttribute): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    setLocationHash(pathname);
  }, [setLocationHash, pathname]);

  return (
    <>
      { children }
    </>
  );
};

const mapDispatchToProps = {
  setLocationHash: (hash: string) => actions.setLocationHash(hash),
};

export default connect(null, mapDispatchToProps)(HashListener);
