import React from 'react';
import styles from './QuartelyDeltaIndicators.less';
import PriceDelta from './PriceDelta/PriceDelta';

export const QuarterlyDeltaIndicators = ({ stats, quarterlyDeltaIndicatorsExtraOffset, commercializationType }) => {
  if (stats.length === 0) {
    return null;
  }
  const priceDeltaStats = stats.map((statsItem) => (
    <div key={statsItem.locationName} className="one-half grid-item">
      <PriceDelta {...statsItem} commercializationType={commercializationType} />
    </div>
  ));
  return (
    <div className={`grid ${styles.indicatorBox} border padding-m background-white`}>
      {priceDeltaStats}
      <div
        className={`border-right border-bottom ${styles.quarterArrow}`}
        style={{ right: `calc(50% + ${quarterlyDeltaIndicatorsExtraOffset}px)` }}
      />
    </div>
  );
};

export default QuarterlyDeltaIndicators;
