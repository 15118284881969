import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchHeader.less';
import { isFeatureOn } from '../../../../utils/featureSwitchReader';
import SavingHomepageAddressSearch from './SavingHomepageAddressSearch';

export const SearchHeader = () => {
  return (
    <div className={`${styles.searchHeader}`}>
      <div
        data-imageversion="0.0.4"
        className={styles.buildingsWithListingsBackground}
      />
      <div className={styles.newSearchWrapper}>
        <SavingHomepageAddressSearch />
      </div>
    </div>
  );
};

SearchHeader.propTypes = {
  isFeatureOn: PropTypes.func,
};

SearchHeader.defaultProps = {
  isFeatureOn,
};

export default SearchHeader;
