import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList, TabsContainer } from 'is24-corecss-server';
import _ from "lodash";
import NewWidgetTitle, { ALIGN } from '../../Utils/Widget/Title/NewWidgetTitle.jsx';
import PriceTrendChart from '../../PriceTrendChart/PriceTrendChart';
import { DesktopAndTablet, Mobile } from '../../Utils/MediaComponents/MediaComponents.tsx';
import { getStatsBoxData } from '../Detailed/utils.js';
import { PRIMARY_BLUE, PRIMARY_GREEN } from '../../../utils/colorPalette';
import styles from './PriceHistoryWidgetWithRentPrice.less';
import PriceTrendChartMobile from '../../PriceTrendChart/PriceTrendChartMobile';
import PriceInfoOverlay from "../PriceInfoOverlay";
import tracking from "../../../tracking/tracking";
import { PRICEDEV_INFO_ICON, PROPERTY_BOOK, PROPERTY_BOOK_V2 } from "../../../tracking/trackingNaming";
import NoDataWidget from "../../Utils/Widget/NoDataWidget/NoDataWidget";

const PriceHistoryWidgetWithRentPrice = ({ realEstateType, address, prices, title, trackingAction, isHomePage }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const priceHistoryGraphTypes = ['BUY', 'RENT'];

  const widgetTitle = _.isEmpty(title) ? `Immobilienpreisentwicklung in ${address} für Wohnungen und Häuser` : title;
  const noData = !prices
    || Object.keys(prices).length === 0
    || !prices[`APARTMENT_${priceHistoryGraphTypes[activeTab]}`]
    || !prices[`HOUSE_${priceHistoryGraphTypes[activeTab]}`];

  if (noData) {
    return <NoDataWidget text={widgetTitle} align={ALIGN.left} />
  }

  const apartmentPrices = prices[`APARTMENT_${priceHistoryGraphTypes[activeTab]}`];
  const housePrices = prices[`HOUSE_${priceHistoryGraphTypes[activeTab]}`];

  const apartmentPricesPerQ = apartmentPrices && apartmentPrices.pricesPerQ ? apartmentPrices.pricesPerQ : null;
  const housePricesPerQ = housePrices && housePrices.pricesPerQ ? housePrices.pricesPerQ : null;
  const mostRecentQuarterA = apartmentPricesPerQ ? apartmentPricesPerQ[apartmentPricesPerQ.length - 1].quarter : 1;
  const mostRecentQuarterH = housePricesPerQ ? housePricesPerQ[housePricesPerQ.length - 1].quarter : 1;

  const commercializationType = priceHistoryGraphTypes[activeTab];
  const pricesPerQ = {
    apartments: apartmentPrices ? apartmentPrices.pricesPerQ : [],
    houses: housePrices ? housePrices.pricesPerQ : [],
  };

  const pricesPerAnnualQ = {
    apartments: apartmentPrices ? apartmentPrices.pricesPerQ.filter(price => price.quarter === mostRecentQuarterA) : [],
    houses: housePrices ? housePrices.pricesPerQ.filter(price => price.quarter === mostRecentQuarterH) : [],
  };

  return (
    <>
      <div className={`grid grid-flex grid-justify-space-between ${styles.widgetContainer}`}>
        <div className="grid-item one-whole align-center">
          <NewWidgetTitle
            id="price-history"
            align={ALIGN.left}
            text={widgetTitle}
          />
        </div>
        <div className={`grid-item one-whole`}>
          <div className="grid grid-flex">
            <div className="grid-item">
              <div className={styles.tabsContainer}>
                <TabsContainer onClick={(clickedTab) => setActiveTab(clickedTab)} defaultSelected={0}>
                  <TabList aria-label="Tab list">
                    <Tab>Kaufpreise</Tab>
                    <Tab>Mietpreise</Tab>
                  </TabList>
                </TabsContainer>
              </div>
            </div>
            <div className="grid-item">
              <button onClick={() => {
                setModalVisible(true);
                tracking.report(PRICEDEV_INFO_ICON, isHomePage ? PROPERTY_BOOK : PROPERTY_BOOK_V2);
              }} type="button" className={styles.infoIcon}><i className="is24-icon-info1" /></button>
              <PriceInfoOverlay visible={modalVisible} onClose={() => setModalVisible(false)} />
            </div>
          </div>
        </div>
        <DesktopAndTablet
          render={() => (
            <>
              <div className={`grid-item one-whole ${styles.widget}`} id="price-history-chart">
                <PriceTrendChart
                  nativeData={pricesPerQ.apartments}
                  parentData={pricesPerQ.houses}
                  commercializationType={commercializationType}
                  items={[
                    { color: PRIMARY_BLUE(), text: 'Wohnungen' },
                    { color: PRIMARY_GREEN(), text: 'Häuser' },
                  ]}
                  hideHeader
                  showMpPromoDialog={true}
                  trackingAction={trackingAction}
                  getStatsBoxData={(selected) =>
                    getStatsBoxData(selected, pricesPerQ.apartments, pricesPerQ.houses, 'Wohnungen', 'Häuser', false)
                  }
                />
              </div>
            </>
          )}
        />
        <Mobile
          render={() => (
            <>
              <div className={`grid-item one-whole margin-top-l ${styles.widget}`} id="price-history-chart-mobile">
                <PriceTrendChartMobile
                  hideHeader
                  hideSeparator
                  nativeDataMobile={pricesPerAnnualQ.apartments}
                  parentDataMobile={pricesPerAnnualQ.houses}
                  realEstateType={realEstateType}
                  commercializationType={commercializationType}
                  showMpPromoDialog={true}
                  trackingAction={trackingAction}
                  getStatsBoxDataMobile={(selected) =>
                    getStatsBoxData(
                      selected,
                      pricesPerAnnualQ.apartments,
                      pricesPerAnnualQ.houses,
                      'Wohnungen',
                      'Häuser',
                      true
                    )
                  }
                />
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};


PriceHistoryWidgetWithRentPrice.defaultProps = {
  isHomePage: false,
  address: null,
  prices: null,
  title: '',
};

PriceHistoryWidgetWithRentPrice.propTypes = {
  isHomePage: PropTypes.bool,
  address: PropTypes.string,
  prices: PropTypes.object.isRequired,
  title: PropTypes.string,
  realEstateType: PropTypes.string.isRequired,
  trackingAction: PropTypes.string.isRequired,
};

export default (PriceHistoryWidgetWithRentPrice);
