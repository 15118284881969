import React from 'react';
import PropTypes from 'prop-types';
import { ModalBackdrop } from 'is24-corecss-server';
import { ModalContainer } from 'is24-corecss-server';
import { ModalContent } from 'is24-corecss-server';
import { OVERLAY_MODAL_SUB_1, OVERLAY_MODAL_TEXT, OVERLAY_MODAL_TITLE } from './content.js';
import styles from './PriceInfoOverlay.less';

const PriceInfoOverlay = ({ visible, onClose }) => {
  return (
    <ModalBackdrop visible={visible} onClose={onClose}>
      <ModalContainer containerClassName={styles.priceInfoOverlay__modalContainer} visible={visible} width={516} modalTitle={OVERLAY_MODAL_TITLE} onClose={onClose}>
        <ModalContent>
          <div>
            <div>{OVERLAY_MODAL_SUB_1}<br /><br /></div>
            <p>{OVERLAY_MODAL_TEXT}</p>
          </div>
        </ModalContent>
      </ModalContainer>
    </ModalBackdrop>
  )
};

PriceInfoOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PriceInfoOverlay;

