import React from 'react';
import PropTypes from 'prop-types';
import StatsBoxItemMobile from './Item/StatsBoxItemMobile.jsx';
import { getCommercializationType } from '../../../../realEstateTypes.js';
import styles from './StatsBoxMobile.less';

export default class StatsBoxMobile extends React.Component {
  render() {
    const { statsData, realEstateType, futureTrend } = this.props;

    const commercializationType = getCommercializationType(realEstateType);

    return (
      <div className={`grid grid-flex gutter-l ${futureTrend ? '' : 'grid-justify-space-between'}`}>
        {statsData.map((statsItem, i) => (
          <div
            key={i}
            className={`grid-item ${!statsItem.previousQ ? styles.statsBoxMarginBottom : ''}`}
            style={{ maxWidth: '50%' }}
          >
            <StatsBoxItemMobile
              itemData={statsItem}
              commercializationType={commercializationType}
              futureTrend={futureTrend}
            />
          </div>
        ))}
      </div>
    );
  }
}

const StatDataMobileItem = PropTypes.shape({
  locationName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  previousQ: PropTypes.string,
  deltaToPreviousQ: PropTypes.number,
  color: PropTypes.string.isRequired,
});

StatsBoxMobile.propTypes = {
  statsData: PropTypes.arrayOf(StatDataMobileItem).isRequired,
  realEstateType: PropTypes.string.isRequired,
  futureTrend: PropTypes.bool,
};

StatsBoxMobile.defaultProps = {
  futureTrend: false,
};
