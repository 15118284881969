import { FOCUS_ADDRESS_SEARCH, CANCEL_ADDRESS_SEARCH } from '../AddressSearch/actions.js';

import {
  BURGER_MENU_OPEN,
  BURGER_MENU_CLOSE,
  TOGGLE_SIDEBAR,
  OPEN_MOBILE_NAVIGATION_MENU,
  CLOSE_MOBILE_NAVIGATION_MENU,
  TOGGLE_SIDEBAR_DESKTOP,
  TOGGLE_HOME_OWNER,
} from './actions.js';
import localStorage from '../../utils/localStorage';
import {
  IS_HOME_OWNER,
  IS_SIDEBAR_DESKTOP_EXPANDED,
  SIDEBAR_FOLDED_STATE_TTL,
  TOGGLE_HOME_OWNER_STATE_TTL
} from '../../utils/globals';
import { disablePageScroll, enablePageScroll } from "../../utils/disablePageScroll";

const isSidebarDesktopExpanded = localStorage.getItem(IS_SIDEBAR_DESKTOP_EXPANDED) !== false;
const isHomeOwner = localStorage.getItem(IS_HOME_OWNER) === undefined ? false : localStorage.getItem(IS_HOME_OWNER);

const initialState = {
  sidebarExpanded: false,
  sidebarDesktopExpanded: isSidebarDesktopExpanded,
  burgerMenuOpened: false,
  isMobileNavigationMenuOpen: false,
  forcedToHideStaticHeader: false,
  forcedToFoldNavigationHeader: false,
  homeOwner: isHomeOwner,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOCUS_ADDRESS_SEARCH:
      return Object.assign({}, state, { sidebarExpanded: true });
    case CANCEL_ADDRESS_SEARCH:
      return Object.assign({}, state, { sidebarExpanded: false });
    case BURGER_MENU_OPEN:
      return Object.assign({}, state, { burgerMenuOpened: true });
    case BURGER_MENU_CLOSE:
      return Object.assign({}, state, { burgerMenuOpened: false });
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, { sidebarExpanded: action.isExpanded });
    case TOGGLE_SIDEBAR_DESKTOP: {
      const sidebarDesktopExpanded = !state.sidebarDesktopExpanded;
      localStorage.setItem(IS_SIDEBAR_DESKTOP_EXPANDED, sidebarDesktopExpanded, SIDEBAR_FOLDED_STATE_TTL);
      return Object.assign({}, state, { sidebarDesktopExpanded });
    }
    case OPEN_MOBILE_NAVIGATION_MENU: {
      disablePageScroll();
      return Object.assign({}, state, { isMobileNavigationMenuOpen: true });
    }
    case CLOSE_MOBILE_NAVIGATION_MENU: {
      enablePageScroll();
      return Object.assign({}, state, { isMobileNavigationMenuOpen: false });
    }
    case TOGGLE_HOME_OWNER: {
      const homeOwner = !state.homeOwner;
      localStorage.setItem(IS_HOME_OWNER, homeOwner, TOGGLE_HOME_OWNER_STATE_TTL);
      return Object.assign({}, state, { homeOwner });
    }
    default:
      return state;
  }
};
