import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { propertyTypes, userIntentsReduced } from '../../../utils/userIntentConfig';
import Button from '../../Button/Button';
import { getPropertyType, getSuggestionQuery, getUserIntent, } from '../../../selectors/selectors';
import actionCreators from '../actions';
import actions from '../../../actions';
import homepageActions from '../../../homepage/indexpage/actions';
import tracking from '../../../tracking/tracking';
import styles from './SavingNavigationSidebarMobileNewUserIntent.less';
import urlGenerator from '../../AddressSearch/AddressSearch/UrlGenerator';
import { PROPERTY_BOOK, PROPERTY_BOOK_V2 } from '../../../tracking/trackingNaming';
import { toCommercializationType } from '../../../userIntents';
import Chips from "./Chips/Chips";
import HomeOwnerToggle from "../HomeOwnerToggle/HomeOwnerToggle";
import SavingAddressSearch from '../../AddressSearch/AddressSearch/SavingAddressSearch';
import State from "../../../store/state";

type SavingNavigationSidebarMobileNewUserIntentProps = {
  onButtonClose: () => AnyAction,
  propertyType: string,
  userIntent: string,
  suggestionQuery: string,
  selectRealEstateType: (x: string) => AnyAction,
  selectUserIntent: (x: string) => AnyAction,
  getCurrentTab: () => string,
  report: (x: string, y?: string, z?: string) => Promise<unknown>,
  navigateToLocation: (x: string) => void,
  isHomePage: boolean,
  saveLastSearchInput: (x: string, y: string, z: boolean) => void,
  saveLastUserIntentAndPropertyType: (x: string, y: string, z: boolean) => void,
  searchInput: string,
  urlSelectedSuggestion: string,
  suggestionSelected: boolean,
}

const SavingNavigationSidebarMobileNewUserIntent = ({
  onButtonClose,
  propertyType,
  userIntent,
  suggestionQuery,
  selectRealEstateType,
  selectUserIntent,
  getCurrentTab = () => window.location.hash.replace('#', ''),
  report = tracking.report,
  navigateToLocation = (url: string) => window.location.assign(url),
  isHomePage = false,
  saveLastSearchInput,
  saveLastUserIntentAndPropertyType,
  searchInput,
  urlSelectedSuggestion,
}: SavingNavigationSidebarMobileNewUserIntentProps): JSX.Element => {
  const [selectedIntent, setIntent] = useState(userIntent);
  const [selectedPropertyType, setPropertyType] = useState(propertyType);
  const [addressSelectedByUser, setAddressSelectedByUser] = useState('');
  const [addressUrl, setAddressUrl] = useState('');

  useEffect(() => {
    if(isHomePage) {
      setAddressSelectedByUser(searchInput);
      setAddressUrl(urlSelectedSuggestion);
    }
  }, [isHomePage, searchInput, urlSelectedSuggestion]);
  const hrStyle = {
    height: '1px',
    backgroundColor: '#E0E0E0',
    border: 'none',
  };

  const handlePropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const propertyType = event.target.value;
    setPropertyType(propertyType);
  };

  const handleUserIntentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userIntent = event.target.value;
    setIntent(userIntent);
  };

  const handleSearchResultSelected = (url: string, addressShortName: string, reportLabel: string) => {
    setAddressSelectedByUser(addressShortName);
    setAddressUrl(url);
    report(reportLabel, isHomePage ? PROPERTY_BOOK : PROPERTY_BOOK_V2);
  };

  const realEstateTypeFromSelection = () =>
    `${selectedPropertyType}_${toCommercializationType(selectedIntent)}`.toUpperCase();

  const hasIntentOrPropertyTypeChanged = () => selectedIntent !== userIntent || selectedPropertyType !== propertyType;

  const saveTrackAndNavigate = () => {
    saveLastSearchInput(addressSelectedByUser, addressUrl, isHomePage);
    saveLastUserIntentAndPropertyType(selectedIntent, realEstateTypeFromSelection(), isHomePage);
    if (hasIntentOrPropertyTypeChanged() && !addressUrl) {
      report(`${selectedPropertyType}_${selectedIntent}`.toLowerCase(), isHomePage ? PROPERTY_BOOK : PROPERTY_BOOK_V2);
    }

    selectUserIntent(selectedIntent);
    selectRealEstateType(realEstateTypeFromSelection());

    if (addressUrl) {
      report(`${selectedPropertyType}_${selectedIntent}`.toLowerCase(), isHomePage ? PROPERTY_BOOK : PROPERTY_BOOK_V2)
      navigateToLocation(
        urlGenerator.generate(
          addressUrl,
          window.location.search,
          suggestionQuery,
          realEstateTypeFromSelection(),
          selectedIntent,
          getCurrentTab(),
        ),
      );
    }
    onButtonClose();
  };

  const addressSearchProps: Record<string, unknown> = {
    customResultStyle: `${styles.newResultBlockSidebarUserIntent} ${styles.reducedHeight}`,
    navigateToTab: getCurrentTab(),
    onResultSelected: handleSearchResultSelected,
    addressSelectedByUser: addressSelectedByUser,
    isHomePage: isHomePage,
    selectRealEstateType
  };

  return (
    <div className="grid grid-flex ">
      <div className="grid-item one-whole padding-horizontal-l padding-top-m">
        <div className="grid padding-top-s">
          <div className="grid-item one-half align-left font-h5 font-l font-normal padding-top-m padding-bottom-m padding-left-l">Suchen</div>
          <div className="grid-item one-half align-right margin-top-m">
            <span
              className="is24-icon-closing font-h4 font-normal"
              onClick={onButtonClose}
              role="button"
              onKeyDown={(event)=> {
                if (event.key === 'Escape'){
                  onButtonClose()
                }
              }}
              tabIndex={0} />
          </div>
        </div>
      </div>

      <hr className="grid-item one-whole" style={hrStyle} />

      <div className={`grid-item one-whole padding-horizontal-l padding-top-m padding-horizontal-s`}>
        <div className="grid-item one-whole padding-horizontal-l padding-top-m">
          <SavingAddressSearch {...addressSearchProps} />
        </div>

        <div className="grid-item one-whole padding-horizontal-l padding-top-xxl">
          <Chips
            label={"Zeige mir an"}
            listOptions={userIntentsReduced}
            defaultChecked={selectedIntent}
            groupName={"userIntents"}
            onChange={handleUserIntentChange}
          />
        </div>
        <div className="grid-item one-whole padding-horizontal-l padding-top-xl">
          <Chips
            label={"für"}
            listOptions={propertyTypes}
            defaultChecked={selectedPropertyType}
            groupName={"propertyTypes"}
            onChange={handlePropertyChange}
          />
        </div>

        <div className="grid-item one-whole padding-horizontal-l padding-top-xl">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */}
          <HomeOwnerToggle isHomePage={isHomePage} />
        </div>

        <div className="grid-item one-whole align-center padding-horizontal-l padding-vertical-l">
          <Button
            text={'Auswahl bestätigen'}
            onClickHandler={saveTrackAndNavigate}
            buttonClass="button-primary"
            sizeClass="one-whole"
            id="btn-confirm-selection"
          >
          </Button>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state: State) => ({
  userIntent: getUserIntent(state),
  propertyType: getPropertyType(state),
  suggestionQuery: getSuggestionQuery(state),
  ...state.indexpage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => ({
  onButtonClose: () => dispatch(actionCreators.closeMobileNavigationMenu()),
  selectRealEstateType: (value: string) => dispatch(actions.selectRealEstateType(value)),
  selectUserIntent: (value: string) => dispatch(actions.selectUserIntent(value)),
  saveLastUserIntentAndPropertyType: (userIntent: string, realEstateType: string, isHomepage: boolean) => {
    isHomepage
      ? dispatch(homepageActions.saveLastUserIntentAndPropertyType(userIntent, realEstateType))
      : dispatch(actions.saveLastUserIntentAndPropertyType(userIntent, realEstateType))
  },
  saveLastSearchInput: (inputValue: string, atlasUrlSelected: string, isHomepage: boolean) => {
    isHomepage
      ? dispatch(homepageActions.saveLastSearchInput(inputValue, atlasUrlSelected))
      : dispatch(actions.saveLastSearchInput(inputValue, atlasUrlSelected))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SavingNavigationSidebarMobileNewUserIntent);
