import React from 'react';
import { HashRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { isRenderedOnServer } from '../../utils/envUtils';
import HashListener from "./HashListener";

const RouterWrapper = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  if (isRenderedOnServer()) {
    return (
      <StaticRouter location="/">
        {children}
      </StaticRouter>
    );
  }

  return (
    <HashRouter>
      <HashListener>
        {children}
      </HashListener>
    </HashRouter>
  );
};

export default RouterWrapper;
