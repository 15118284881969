import React from 'react';
import GridLines from './Lines/Lines.jsx';

import { translation } from '../axisConfig.js';

export default ({
  scales,
  margins,
  svgDimensions,
  ticks,
  ticksNumber,
  selected,
  tickLengthOffset,
  orient,
  currentPointInTime,
  showGridLines,
}) => {
  const { height, width } = svgDimensions;

  const { x: xTicksNumber, y: yTicksNumber } = ticksNumber || {};
  const { x: xTickLengthOffset, y: yTicksLengthOffset } = tickLengthOffset || {};

  const { x: xOrient, y: yOrient } = orient || { x: 'bottom', y: 'left' };

  const translate = translation(svgDimensions, margins);

  const xProps = {
    orient: xOrient,
    scale: scales.xScale,
    translate: translate[xOrient],
    length: height - margins.top - margins.bottom + (xTickLengthOffset || 0),
    ticks: ticks.x,
    ticksNumber: xTicksNumber,
    selected,
    currentPointInTime,
  };

  const yProps = {
    orient: yOrient,
    scale: scales.yScale,
    translate: translate[yOrient],
    length: width - margins.left - margins.right + (yTicksLengthOffset || 30),
    ticks: ticks.y,
    ticksNumber: yTicksNumber,
  };

  return (
    <g>
      {showGridLines && <GridLines {...yProps} />}
      <GridLines {...xProps} />
    </g>
  );
};
