import React from 'react';
import * as PropTypes from 'prop-types';
import Legend from '../Chart/Legend/Legend';
import QuarterlyDeltaIndicators from './QuarterlyDeltaIndicators/QuarterlyDeltaIndicators';
import PriceHistoryDetailedChart from '../PriceHistory/Detailed/Chart/Chart';
import { getFirstPointInTime, getCurrentPointInTime, getLatestPointInTime } from '../PriceHistory/Detailed/utils';
import { OVERLAY_MODAL_SUB_1, OVERLAY_MODAL_TITLE, OVERLAY_MODAL_TEXT } from '../PriceHistory/content';
import PriceTrendChartDescription from './PriceTrendChartDescription';
import HomeOwnerWorldPromo from '../HomeOwnerWorldPromo/HomeOwnerWorldPromo';
import HomeOwnerWorldPromoChartOverlay from '../HomeOwnerWorldPromo/HomeOwnerWorldPromoChartOverlay';
import { svgMargins } from '../PriceHistory/Detailed/config';
import styles from './PriceTrendChart.less';
import { PROPERTY_BOOK_V2 } from "../../tracking/trackingNaming";
import tracking from "../../tracking/tracking";
import { GRAPH_INTERACTION } from "../../tracking/trackingNaming";

export default class PriceTrendChart extends React.Component {
  state = { selected: null };

  constructor(props) {
    super(props);
    const stateValues = this.createStateValuesFromProps(props);
    this.state = {
      ...stateValues,
      quarterlyDeltaIndicatorsParentWidth: null,
    };
    this.quarterlyDeltaIndicatorsParentRef = React.createRef();
    this.graphInteractions = 0;
  }

  createStateValuesFromProps(props) {
    const { nativeData, parentData, showMpPromoDialog } = props;
    const latestPoint = getLatestPointInTime({ nativeData, parentData }) + (showMpPromoDialog ? 1 : 0);
    const currentPoint = getCurrentPointInTime({ nativeData, parentData });
    const firstPoint = getFirstPointInTime({ nativeData, parentData });
    const quarterlyDeltaIndicatorsXCoordinate = this.calculateQuarterlyDeltaIndicators(
      firstPoint,
      latestPoint,
      currentPoint,
      null,
    ).xCoordinate;
    const quarterlyDeltaIndicatorsXCoordinateLastPoint = this.calculateQuarterlyDeltaIndicators(
      firstPoint,
      latestPoint,
      latestPoint,
      null,
    ).xCoordinate;

    return {
      selected: currentPoint,
      latestPoint,
      firstPoint,
      quarterlyDeltaIndicatorsExtraOffset: 0,
      quarterlyDeltaIndicatorsXCoordinate,
      quarterlyDeltaIndicatorsXCoordinateLastPoint,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateView);
    this.updateView();
  }

  componentDidUpdate(prevProps) {
    if (this.props.commercializationType !== prevProps.commercializationType) {
      const stateValues = this.createStateValuesFromProps(this.props);
      /* eslint-disable react/no-did-update-set-state */
      this.setState(stateValues);
      this.updateSelected(stateValues.selected, true, this.quarterlyDeltaIndicatorsParentRef.current.offsetWidth);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateView);
  }

  updateView = () => {
    this.updateSelected(this.state.selected, true, this.quarterlyDeltaIndicatorsParentRef.current.offsetWidth);
  };

  calculateQuarterlyDeltaIndicators = (firstPoint, latestPoint, currentPoint, parentWidth) => {
    const leftOffset = svgMargins.left;
    const rightOffset = svgMargins.right;
    const relativePosition = (currentPoint - firstPoint) / (latestPoint - firstPoint);
    const containerOffset = 148;
    const containerWidth = 314;

    let extraOffset = 0;
    let actualParentWidth = parentWidth;

    if (actualParentWidth === null) {
      actualParentWidth =
        (this.quarterlyDeltaIndicatorsParentRef &&
          this.quarterlyDeltaIndicatorsParentRef.current &&
          this.quarterlyDeltaIndicatorsParentRef.current.offsetWidth) ||
        0;
    }
    if (actualParentWidth) {
      const rightEnd =
        (actualParentWidth - rightOffset - leftOffset) * relativePosition +
        leftOffset +
        (containerWidth - containerOffset);
      const leftEnd = (actualParentWidth - rightOffset - leftOffset) * relativePosition + leftOffset - containerOffset;
      if (rightEnd > actualParentWidth) {
        extraOffset = actualParentWidth - rightEnd;
      } else if (leftEnd < leftOffset - 15) {
        extraOffset = leftOffset - 15 - leftEnd;
      }
    }
    return {
      xCoordinate: `calc(((100% - ${rightOffset}px - ${leftOffset}px) * ${relativePosition}) + ${leftOffset}px - ${containerOffset}px + ${extraOffset}px)`,
      extraOffset,
    };
  };

  updateSelected = (xAxisValue, force, quarterlyDeltaIndicatorsParentWidth) => {
    if (xAxisValue !== this.state.selected || force) {
      this.graphInteractions++;
      this.setState((prevState) => {
        const quarterlyDeltaIndicators = this.calculateQuarterlyDeltaIndicators(
          prevState.firstPoint,
          prevState.latestPoint,
          xAxisValue,
          quarterlyDeltaIndicatorsParentWidth || prevState.quarterlyDeltaIndicatorsParentWidth,
        );
        const quarterlyDeltaIndicatorsLastPoint = this.calculateQuarterlyDeltaIndicators(
          prevState.firstPoint,
          prevState.latestPoint,
          prevState.latestPoint,
          quarterlyDeltaIndicatorsParentWidth || prevState.quarterlyDeltaIndicatorsParentWidth,
        );
        return {
          selected: xAxisValue,
          quarterlyDeltaIndicatorsXCoordinate: quarterlyDeltaIndicators.xCoordinate,
          quarterlyDeltaIndicatorsXCoordinateLastPoint: quarterlyDeltaIndicatorsLastPoint.xCoordinate,
          quarterlyDeltaIndicatorsExtraOffset: quarterlyDeltaIndicators.extraOffset,
          quarterlyDeltaIndicatorsParentWidth:
            quarterlyDeltaIndicatorsParentWidth || prevState.quarterlyDeltaIndicatorsParentWidth,
        };
      });
      this.trackGraphInteraction()
    }
  };

  trackGraphInteraction = () => {
    if (this.graphInteractions === 3) {
      tracking.report(GRAPH_INTERACTION)
    }
  }

  displayWithTooltip = () => (
    <>
      <span id="priceTrend" className="price-chart-title font-bold font-h5">Preisentwicklung</span>
      <span className="padding-top-xs float-right">
        <div className="tooltip-container">
          <div
            id="priceTrend-tooltip"
            className="tooltip-target font-xl is24-icon-info1"
            style={{ paddingLeft: '10px' }}
          />
          <div className="tooltip positioning-left" style={{ width: '272px' }}>
            <div id="priceTrend-tooltip-info">
              <ul id="title" style={{ fontSize: '14px', color: '#E0E0E0' }}>
                {OVERLAY_MODAL_TITLE}
              </ul>
              <br />
              <ul id="subtitle" style={{ fontSize: '12px', color: '#E0E0E0' }}>
                {OVERLAY_MODAL_SUB_1}
              </ul>
              <br />
              <ul id="subtext" style={{ fontSize: '12px', color: '#E0E0E0' }}>
                {OVERLAY_MODAL_TEXT}
              </ul>
            </div>
          </div>
        </div>
      </span>
    </>
  );

  render() {
    const {
      items,
      nativeData,
      parentData,
      commercializationType,
      getStatsBoxData,
      hideHeader,
      showMpPromoDialog,
      futureTrend,
      trackingAction,
    } = this.props;
    const { selected } = this.state;
    const stats = getStatsBoxData(selected).statsBoxDataQ;

    const hrStyle = {
      height: '1px',
      backgroundColor: '#E0E0E0',
      border: 'none',
    };

    return (
      <>
        <div className="grid padding-l">
          {!hideHeader && (
            <>
              <div className="grid-item">{this.displayWithTooltip()}</div>
              <div className=" grid-item one-whole padding-vertical-s">
                <hr style={hrStyle} />
              </div>
              <div className="grid-item margin-bottom ">
                <PriceTrendChartDescription />
              </div>
            </>
          )}

          <div
            ref={this.quarterlyDeltaIndicatorsParentRef}
            className="grid-item absolute-reference"
            style={{ height: '80px' }}
          >
            <div
              className={`${styles.quarterlyDeltaIndicatorsContainer} absolute-content`}
              style={{
                bottom: '-40px',
                left:
                  stats.length === 0
                    ? this.state.quarterlyDeltaIndicatorsXCoordinateLastPoint
                    : this.state.quarterlyDeltaIndicatorsXCoordinate,
              }}
            >
              {stats.length === 0 ? (
                <HomeOwnerWorldPromo trackingAction={trackingAction} />
              ) : (
                <QuarterlyDeltaIndicators
                  quarterlyDeltaIndicatorsExtraOffset={this.state.quarterlyDeltaIndicatorsExtraOffset}
                  selected={selected}
                  commercializationType={commercializationType}
                  stats={stats}
                />
              )}
            </div>
          </div>
        </div>
        <div className="padding-horizontal-l padding-bottom-xl absolute-reference">
          <PriceHistoryDetailedChart
            updateSelected={this.updateSelected}
            selected={selected}
            nativeData={nativeData}
            parentData={parentData}
            commercializationType={commercializationType}
            showMpPromoDialog={showMpPromoDialog}
            futureTrend={futureTrend}
            showGridLines
          />
          {showMpPromoDialog && <HomeOwnerWorldPromoChartOverlay trackingAction={trackingAction} />}
        </div>
        <div className="align-right padding-right">
          <Legend showFuturePriceTrend={!showMpPromoDialog} items={items} />
        </div>
      </>
    );
  }
}

PriceTrendChart.defaultProps = {
  hideHeader: false,
  showMpPromoDialog: false,
  futureTrend: false,
  trackingAction: PROPERTY_BOOK_V2
};

PriceTrendChart.propTypes = {
  hideHeader: PropTypes.bool,
  showMpPromoDialog: PropTypes.bool,
  futureTrend: PropTypes.bool,
  realEstateType: PropTypes.string,
  commercializationType: PropTypes.string,
  nativeData: PropTypes.array,
  parentData: PropTypes.array,
  items: PropTypes.array,
  getStatsBoxData: PropTypes.func,
  trackingAction: PropTypes.string,
};
