import PropTypes from "prop-types";
import React  from "react";
import Chip from "./Chip";
import styles from './Chips.less'

const Chips = ({ label, defaultChecked, listOptions, groupName, onChange }) => (
    <div className={`grid-item desk-one-whole lap-one-whole palm-one-half ${styles.container_chips}`}>
      <label className="input-label" id={`label-${groupName}`}>{label}:</label>
      <div className={styles.wrapper_chips}>
        {listOptions && listOptions.map((item) => (
            <Chip
              key={item.value}
              name={item.name}
              value={item.value}
              groupName={groupName}
              onChange={onChange}
              defaultChecked={defaultChecked}
            />
          ))}
      </div>
    </div>
    )

Chips.defaultProps = {
  label: ""
};

Chips.propTypes = {
  label: PropTypes.string,
  defaultChecked:PropTypes.string,
  listOptions:PropTypes.array,
  groupName: PropTypes.string,
};

export default Chips
