export const PRICE_CHECK_INPUT_UPDATE = 'PRICE_CHECK_INPUT_UPDATE';
export const PRICE_CHECK_REAL_ESTATE_TYPE = 'PRICE_CHECK_REAL_ESTATE_TYPE';

const priceCheckRealEstateType = (realEstateType) => ({
  type: PRICE_CHECK_REAL_ESTATE_TYPE,
  realEstateType,
});

const priceCheckInputUpdate = (key, value) => ({
  type: PRICE_CHECK_INPUT_UPDATE,
  value,
  key,
});

export default {
  priceCheckInputUpdate,
  priceCheckRealEstateType,
};
