import { setSearchParams } from '../../../utils/urlUtils';

const generate = (url, initialSearchQuery, suggestionQuery, realEstateType, userIntent, navigateTo) => {
  const q1 = setSearchParams('searchQuery', encodeURIComponent(suggestionQuery), initialSearchQuery);
  const q2 = setSearchParams('marketingFocus', encodeURIComponent(realEstateType), q1);
  const q3 = setSearchParams('userIntent', encodeURIComponent(userIntent), q2);
  const customTab = navigateTo ? `#${navigateTo}` : '';
  const newUrl = url.replace(/\?.*/, '').replace(/#.*/, '');
  return `${newUrl}${q3}${customTab}`;
};

export default { generate };
