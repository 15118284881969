import React from "react";
import NewWidgetTitle from "../Title/NewWidgetTitle";
import { NO_DATA_TEXT } from "../../../../utils/globals";
import styles from './NoDataWidget.less';

type NoDataWidgetProps = {
  text: string;
  align: string;
};

const NoDataWidget = ({ text = "Tut uns leid da ist etwas schief gelaufen. Diesen Inhalt können wir dir gerade leider nicht zeigen.", align }: NoDataWidgetProps) => {
  return (
    <>
      <div className={`grid grid-flex grid-justify-space-between ${styles.widgetContainer}`}>
        <div className="grid-item one-whole align-center">
          <NewWidgetTitle id="price-history" align={align} text={text} />
        </div>
        <div className="grid-item one-whole align-center padding-vertical-xl padding-horizontal-xxl">
          {NO_DATA_TEXT}
        </div>
      </div>
    </>
  );
};

export default NoDataWidget;
