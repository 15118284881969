export const BURGER_MENU_OPEN = 'BURGER_MENU_OPEN';
export const BURGER_MENU_CLOSE = 'BURGER_MENU_CLOSE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_DESKTOP = 'TOGGLE_SIDEBAR_DESKTOP';
export const OPEN_MOBILE_NAVIGATION_MENU = 'OPEN_MOBILE_NAVIGATION_MENU';
export const CLOSE_MOBILE_NAVIGATION_MENU = 'CLOSE_MOBILE_NAVIGATION_MENU';
export const TOGGLE_HOME_OWNER = 'TOGGLE_HOME_OWNER';

const actionCreators = {
  openBurgerMenu: () => ({
    type: BURGER_MENU_OPEN,
  }),
  closeBurgerMenu: () => ({
    type: BURGER_MENU_CLOSE,
  }),
  toggleSidebar: (isExpanded) => ({
    type: TOGGLE_SIDEBAR,
    isExpanded,
  }),
  toggleSidebarDesktop: () => ({
    type: TOGGLE_SIDEBAR_DESKTOP,
  }),
  openMobileNavigationMenu: () => ({
    type: OPEN_MOBILE_NAVIGATION_MENU,
  }),
  closeMobileNavigationMenu: () => ({
    type: CLOSE_MOBILE_NAVIGATION_MENU,
  }),
  toggleHomeOwner: () => {
    return { type: TOGGLE_HOME_OWNER }
  },
};

export default actionCreators;
