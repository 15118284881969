import { useEffect } from "react";
import { useDispatch } from "react-redux";
import actionCreators from "../components/Navigation/actions";

const useWidthResize = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWidth = () => {
      if(window.innerWidth >= 660){
        dispatch(actionCreators.closeMobileNavigationMenu());
      }
    };

    window.addEventListener('resize', handleWidth);

    return () => window.removeEventListener('resize', handleWidth);
  })
};

export default useWidthResize
