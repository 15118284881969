import React from 'react';
import PropTypes from 'prop-types';
import styles from './PriceDelta.less';
import { formatDE } from '../../../../utils/NumbersHelper';
import PriceDeltaLegend from './PriceDeltaLegend';

export default class PriceDelta extends React.Component {
  render() {
    const { previousQ, deltaToPreviousQ, previousYearQ, deltaToPreviousYearQ, price, locationName, color, commercializationType } = this.props;

    const sign = deltaToPreviousQ >= 0 ? '+' : '';
    const deltaToPreviousQInPercent = formatDE(deltaToPreviousQ * 100, 1);
    const deltaToPreviousYearQInPercent = formatDE(deltaToPreviousYearQ * 100, 1);
    const priceFormat = new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: commercializationType === 'RENT' ? 2 : 0,
      minimumFractionDigits: commercializationType === 'RENT' ? 2 : 0,
    });

    return (
      <div className="padding-horizontal-s">
        <div className="grid">
          <div className={`grid-item align-left font-m font-semibold ${styles.line1}`}>
            Ø {priceFormat.format(price)} €/m²
          </div>
          {previousQ && deltaToPreviousQ && (
            <div className={`grid-item align-left ${styles.line2}`}>
              <span className="font-semibold">
                {sign}
                {deltaToPreviousQInPercent}&#37;
              </span>
              <span className="font-xs font-lightgray">&nbsp;zu {previousQ}</span>
            </div>
          )}
          {previousYearQ && deltaToPreviousYearQ && (
            <div className={`grid-item align-left ${styles.line2}`}>
              <span className="font-semibold">
                {sign}
                {deltaToPreviousYearQInPercent}&#37;
              </span>
              <span className="font-xs font-lightgray">&nbsp;zu {previousYearQ}</span>
            </div>
          )}
          <div className={`grid-item align-left ${styles.line3}`}>
            <PriceDeltaLegend color={color} locationName={locationName} />
          </div>
        </div>
      </div>
    );
  }
}

PriceDelta.propTypes = {
  previousQ: PropTypes.string,
  deltaToPreviousQ: PropTypes.number,
  previousYearQ: PropTypes.string,
  deltaToPreviousYearQ: PropTypes.number,
  color: PropTypes.string.isRequired,
  commercializationType: PropTypes.string.isRequired
};
