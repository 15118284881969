import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../AddressSearch.less';

export default class AddressSearchResultsItem extends React.Component {
  constructor(props) {
    super(props);
    this.suggestionResultRef = React.createRef();
  }

  componentDidUpdate() {
    // only scroll into view if the item is selected
    if (this.props.selectedResult) {
      this.ensureActiveItemVisible();
    }
  }

  getHighlightedText(text, highlight) {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${this.escapeRegExp(highlight)})`, 'gi'));
    return parts.map((part, i) => (
      <span key={i} className={part.toLowerCase() === highlight.toLowerCase() ? styles.suggestionQuery : ''}>
        {part}
      </span>
    ));
  }

  escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  ensureActiveItemVisible = () => {
    const itemComponent = this.suggestionResultRef.current;
    itemComponent.scrollIntoView(false);
  };

  render() {
    const { url, addressLineOne, addressLineTwo, suggestionQuery, selectedResult, generateLink } = this.props;
    const link = generateLink(url);
    const selectedResultClass = selectedResult
      ? `${styles.resultItem} selectedLink ${styles.selectedResult}`
      : styles.resultItem;
    return (
      <div
        ref={this.suggestionResultRef}
        className={`suggestion-result-item padding-vertical-s padding-horizontal-xl ${selectedResultClass}`}
      >
        <a className={styles.resultAnchor} href={link} onMouseDown={this.props.handleClick(link)}>
          <div className={`${styles['arrow-div']} hide float-right`}>
            <span className={`${styles['arrow-icon']} fa fa-2x icon-arrow`} />
          </div>
          <p className="font-standard font-regular">{this.getHighlightedText(addressLineOne, suggestionQuery)}</p>
          <p className="font-s font-lightgray">{addressLineTwo}</p>
        </a>
        <div className={`border-bottom ${styles.resultItemBorder}`} />
      </div>
    );
  }
}

AddressSearchResultsItem.defaultProps = {
  addressLineTwo: '',
  selectedResult: false,
};

AddressSearchResultsItem.propTypes = {
  suggestionQuery: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  addressLineOne: PropTypes.string.isRequired,
  addressLineTwo: PropTypes.string,
  selectedResult: PropTypes.bool,
  generateLink: PropTypes.func.isRequired,
};
