import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Close from '../../../../icons/Close.jsx';
import tracking from '../../../../tracking/tracking.js';
import {
  INDEX_SEARCH_CLICK_ON_SEARCH_FIELD,
  INDEX_SEARCH_ENTER_ADDRESS,
  PROPERTY_BOOK,
  SEARCH_CLICK_ON_SEARCH_FIELD,
  SEARCH_ENTER_ADDRESS
} from '../../../../tracking/trackingNaming.js';
import styles from '../../AddressSearch.less';

const SavingAddressSearchInput = (props) => {
  const [enterAddressReported, setEnterAddressReported] = useState(false);
  const [searchInput, setSearchInput] = useState(props.address || props.suggestionQuery);
  const inputFieldRef = useRef();

  useEffect(() => {
    props.callbackInputField(inputFieldRef.current);
  });

  useEffect(() => {
    setSearchInput(props.address || props.suggestionQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.address]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      props.cancelSearch();
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      props.suggestionsArrowUpEvent();
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      props.suggestionsArrowDownEvent();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      props.handleEnter(event);
    }
  };

  const handleOnChange = (event) => {
    event.persist();
    if (!enterAddressReported) {
      setEnterAddressReported(true);
      props.isHomePage ? tracking.report(INDEX_SEARCH_ENTER_ADDRESS, PROPERTY_BOOK) : tracking.report(SEARCH_ENTER_ADDRESS);
    }
    setSearchInput(event.target.value);
    props.updateSuggestionQuery(event.target.value);
  };

  const handleOnClick = () => {
    props.isHomePage ? tracking.report(INDEX_SEARCH_CLICK_ON_SEARCH_FIELD, PROPERTY_BOOK) : tracking.report(SEARCH_CLICK_ON_SEARCH_FIELD);
  };

  const searchIconClass = props.suggestionQuery ? `${styles.open}` : '';

  const { autoFocus } = props;

  const userOnboardingStyle = `${styles.searchIconNew2} font-gray`
  const style = { fontSize: '25px' }

  return (
    <div className={`${styles.searchBar}`}>
      <div
        className={`${searchIconClass} ${styles.searchIconNew} ${userOnboardingStyle}`}
        onClick={props.cancelSearch}
        role="button"
        onKeyDown={(event)=> {
          if (event.key === 'Escape'){
            props.cancelSearch()
          }
        }}
        tabIndex={0}
      >
        {props.suggestionQuery ? <Close /> : <span style={ style } className="is24-icon-search" />}
      </div>
      <input
        id="search-input"
        ref={inputFieldRef}
        placeholder={props.placeholder}
        title="Andere Adresse suchen"
        type="text"
        className={ `${styles.addressInputNew} ${styles.addressInputNew2}`}
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
        onClick={handleOnClick}
        onFocus={props.handleOnFocus}
        value={searchInput}
        autoComplete="disabled"
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
      />
    </div>
  );
};

SavingAddressSearchInput.defaultProps = {
  suggestionQuery: '',
  address: '',
  placeholder: 'Stadt, Stadtviertel oder Adresse eingeben',
  callbackInputField: () => {},
  autoFocus: false
};

SavingAddressSearchInput.propTypes = {
  suggestionQuery: PropTypes.string,
  cancelSearch: PropTypes.func.isRequired,
  updateSuggestionQuery: PropTypes.func.isRequired,
  suggestionsArrowDownEvent: PropTypes.func.isRequired,
  suggestionsArrowUpEvent: PropTypes.func.isRequired,
  handleEnter: PropTypes.func.isRequired,
  address: PropTypes.string,
  callbackInputField: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

export default SavingAddressSearchInput;
