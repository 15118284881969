let instance = null;
let lazyFeatureSwitches = {};

export class FeatureSwitchReaderClass {
  constructor() {
    const defaultFeatureChecker = (featureName) => {
      return lazyFeatureSwitches[featureName] === true;
    };
    let currentFeatureChecker = defaultFeatureChecker;

    this.setFeatureChecker = function (featureChecker) {
      if (typeof featureChecker === 'function') currentFeatureChecker = featureChecker;
    };

    this.resetFeatureChecker = function () {
      currentFeatureChecker = defaultFeatureChecker;
    };

    this.isFeatureOn = function (featureName) {
      return currentFeatureChecker(featureName);
    };
  }
}

const featureSwitchReader = {
  set: (featureSwitches) => {
    lazyFeatureSwitches = featureSwitches;
  },
  getInstance: () => {
    instance = instance || new FeatureSwitchReaderClass();
    return instance;
  },
  isFeatureOn: (featureName) => featureSwitchReader.getInstance().isFeatureOn(featureName)
};

export const isFeatureOn = (featureName) => featureSwitchReader.isFeatureOn(featureName)

export default featureSwitchReader;
